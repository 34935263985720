import React from 'react'
import './style.css'
import AboutBody from "./about-body"
import HeaderFa from '../header-fa';
import FooterFa from '../footer-fa';


class About extends React.Component {
    render() {
        return(
            <div>
                <HeaderFa/>
                <AboutBody/>
                <FooterFa/>
            </div>
        );
    }
}

export default About
import React from 'react'
import './style.css'
import axios from 'axios'

class CheckoutSuccess extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            
        }
    }

    componentDidMount = async () => {

		const receipt = await axios.post('https://request.sweetobot.com/receipts/receive', {
			token: -1,
			status: "paypal transaction",
			description: "verification was successful",
			factorNum: -1,
			transId: -1,
			cardNum: -1
		})
		// localStorage.setItem('token', receipt.data.token) // I think we could delete this line!
		const response = await axios.post('https://request.sweetobot.com/registers/payment', {paid: 5}, {headers: {'x-access-token': localStorage.getItem('token')}}) // changed for ver1.5
		window.location.href = 'https://www.mehrobo.com/screening-test'
    }

    render() {
		return (
			<div className="receipt-container">
				<div className="receipt-verify-result">
					<div>Transaction was successful, you are redirecting to screening test ...</div>
					<a href="https://www.mehrobo.com" className="receipt-button">return</a>
				</div>
			</div>
		)
	}
}

export default CheckoutSuccess
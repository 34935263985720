import React from 'react'
import './style.css'
import axios from 'axios'
import AddUserModal from './modal-addusers'
import AccessModal from './access-modal'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FactCheckOutlined from '@mui/icons-material/FactCheckOutlined'
import EditUserModal from './modal-edituser'

class Users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            clinicUsers: null,
            sendMessage: "Hello,I'mInEvaluationPage",
            evaluation: null,
            currentDateTime: null,
            openModal: false,
            openEditModal: false,
            errorModal: false,
            modalClinicUser: '',
            modalUserId: '',
            videocallSession: false,
            sessName: 0,
            userId: 0,
            currentUser: 0
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        console.log("Current Date is:", dateTime);
        console.log("Current Date is:", today.getTime());
        console.log("Current Date is:", randDate < today);
    }

    componentDidMount = async () => {
        const userResult = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        localStorage.setItem('currentUser', userResult.data.access)
        if (userResult.data.message == "Yo, You are authenticated!") {
            // this.setState({isLogin: true});
            // this.setState({firstName: userResult.data.firstname})
            // this.setState({lastName: userResult.data.lastname})
            console.log(userResult.data)
        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
        this.handleRedirect();

        const result = await axios.post('https://request.sweetobot.com/user/list', {some: 'some'}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Received Data:", result.data);
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({isLogin: true});
            this.setState({clinicUsers: result.data.users})
            this.setState({isLogin: true});
            this.getCurrentDateAndTime()
        } else {
            this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    modalHandler = (modal) => {
        this.setState({openModal: modal})
    }

    editModalHandler = (modal) => {
        this.setState({openEditModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    accessHandler = (modal) => {
        this.setState({errorModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    testNameServerHandler = (nameServer) => {
        this.setState({sessName: nameServer})
        console.log("session name is:", nameServer)
    }

    buttonAddUser = () => {
        this.setState({openModal: true})
    }

    buttonEditUser = (id) => {
        this.setState({userId: id})
        this.setState({openEditModal: true})
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>لیست کاربران</span>
                                </p>
                            </div>
                        </div>
                        <div className='test-list mt-1 d-flex flex-column'>
                            {this.state.openModal && 
                            <AddUserModal
                                closeModal={(modalBool) => this.modalHandler(modalBool)}
                            />}
                            {this.state.openEditModal && 
                            <EditUserModal
                                closeModal={(modalBool) => this.editModalHandler(modalBool)}
                                selectedUser={this.state.clinicUsers[this.state.userId]}
                            />}
                            {this.state.errorModal && 
                            <AccessModal
                                closeModal={(modalBool) => this.accessHandler(modalBool)} 
                            />}
                            <div className='boxes my-4 mb-4' style={{gridGap: '0'}}>
                                <a className='box d-flex flex-column align-items-center justify-content-center' onClick={() => this.buttonAddUser()}>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center'>
                                        <span style={{color: '#3ecc97'}}> + </span>
                                    </div>
                                    <span className='font-14 mt-4 mode-text-7'>افزودن کاربر</span>
                                </a>
                            </div>
                            <div className='panel mode-bg-8 mb-4'>
                                <div className='d-flex flex-column w-100'>
                                    <div className='test-title-wrapper d-flex justify-content-between align-items-center pr-4 pl-4'>
                                        <p className='font-14 mode-text-4'>
                                            لیست کاربران
                                        </p>
                                    </div>
                                    <div className='test-table-wrapper'>
                                        <div className='test-table'>
                                            <div className='table-head'>
                                                <div className='test-table-header d-flex align-items-center mode-bg-5 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>ردیف</p>
                                                    <p className='mode-text-9 w-35'>نام کاربر</p>
                                                    <p className='mode-text-9 w-35'>نوع کاربر</p>
                                                    <p className='mode-text-9 w-35 d-flex justify-content-end pr-4'>عملیات‌ها</p>
                                                </div>
                                            </div>
                                            {this.state.clinicUsers.map((clinicUser, i) => (
                                            <div className='table-row' key={i}>
                                                <a className='test-table-row w-100 d-flex align-items-center mode-bg-9 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>{i+1}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.firstname} {clinicUser.lastname}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.access == 'Therapist' ? 'درمانگر' : 'مراجع'}</p>
                                                    <div className='w-50 d-flex justify-content-end'>
                                                        <a href={`/fa/panel/evaluations/list/results?id=${clinicUser.id}`} className="users-control mode-text-0" style={{backgroundColor: '#398e3d'}}>
                                                            <FactCheckOutlined/>
                                                        </a>
                                                        <div className="users-control" style={{backgroundColor: '#257cb5'}} onClick={() => this.buttonEditUser(i)}>
                                                            <DriveFileRenameOutlineIcon/>
                                                        </div>
                                                        <div className="users-control" style={{backgroundColor: '#f80001'}}>
                                                            <DeleteOutline/>
                                                        </div>
                                                    </div>

                                                </a>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Users
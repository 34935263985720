import React, { useEffect, useState } from 'react'
import './style.css'
import axios from 'axios'
import SliderHP from './slider-hp'

import TehranUniLogo from '../../../assets/tehranUni-logo.png'
import TehranCiteLogo from '../../../assets/tehranCite-logo.png'
import HayatLogo from '../../../assets/hayat-logo.png'
import LoginPagePicture from '../../../assets/hp-test-image.png'
import AvatLogo from '../../../assets/Avat.png'
import KhaneKheradLogo from '../../../assets/khenehkherad-sh-logo.png'
import EvaluationsImage from '../../../assets/Evaluations-Product.png'
import DigitalFilesImage from '../../../assets/DigitalFiles-Product.png'
import HeaderFa from '../header-fa'

import { IoFileTrayFullOutline, IoGameControllerOutline } from 'react-icons/io5'
import { BsCardChecklist } from 'react-icons/bs'
import { HiOutlineUserGroup } from 'react-icons/hi'
import FooterFa from '../footer-fa'


const engText = require('../../../utils/homepageEnglishTexts.js')
const faText = require('../../../utils/homepageFarsiTexts.js')

class HomePage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      homepageText: '',
      countryCode: this.props.languageInput,
      sendMessage: "Hello,I'mInHomePage",
    }
  }

  componentDidMount = async() => {
    this.handleRedirect();
    const lang = localStorage.getItem('lang')
    if(lang == null) {
      if(this.state.countryCode =='IR'){
        this.setState({homepageText: [...faText]})
      } else {
        this.setState({homepageText: [...engText]})
      }
    }
    else if(lang =='IR') {
      this.setState({homepageText: [...faText]})
      this.setState({countryCode: 'IR'})
    }
    else {
      this.setState({homepageText: [...engText]})
      this.setState({countryCode: 'US'})
    }
  }

  componentDidUpdate = () => {
    if (this.props.languageInput != this.state.countryCode) {
      this.setState({countryCode: this.props.languageInput})
      if(this.props.languageInput == 'IR' ){
        this.setState({homepageText: [...faText]})
      } else {
        this.setState({homepageText: [...engText]})
      }
    }
  }

  async handleRedirect () {
    const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    return true
  }

  render() {

    return (
      <div className='homepage'>
        <HeaderFa/>
        <SliderHP/>

        <div className='statics' style={{flexDirection: 'column'}}>
          <h1>{"هر آنچه کلینیک شما نیاز دارد"}</h1>
          <div className='statics-container statistics-top'>
            <div className='statics-card'>
              <div className='stats-circle'>
                <BsCardChecklist></BsCardChecklist>
              </div>
              <p>{"تست‌ها و ارزیابی‌ها"}</p>
            </div>
            <div className='statics-card'>
              <div className='stats-circle'>
                <IoFileTrayFullOutline></IoFileTrayFullOutline>
              </div>
              <p>{"پرونده‌های دیجیتال"}</p>
            </div>
            <div className='statics-card'>
              <div className='stats-circle'>
                <IoGameControllerOutline></IoGameControllerOutline>
              </div>
              <p>{"بازی‌های شناختی"}</p>
            </div>
            <div className='statics-card'>
              <div className='stats-circle'>
                <HiOutlineUserGroup></HiOutlineUserGroup>
              </div>
              <p>{"دوره‌های آنلاین"}</p>
            </div>
          </div>
        </div>

        <div className='products-hp'>
          <div className='product-hp-container'>
            <div className='product-hp-content' style={{width: '100%', marginRight: 0}}>
              <h1>{"تست‌ها و ارزیابی‌ها"}</h1>
              <p>{"مجموعه تست‌ها و ارزیابی‌های هوشمند مهروبو، دامنه‌ی وسیعی از تست‌های حوزه‌ی کودک را در اختیار متخصصین قرار می‌دهد تا بتوانند تنها در چند دقیقه، مهارت مورد نظر در کودک را ارزیابی نموده و گزارش را در لحظه به ببینند. با بهره‌گیری از هوش مصنوعی، نتایج تست‌های چون گارس ، ای اس کیو ، وکسلر و اسکوبو تنها در چند ثانیه تولید و در اختیار متخصص قرار می‌گیرد"}</p>
            </div>
            <div className='product-hp-image'>
              <img src={EvaluationsImage}></img>
            </div>
          </div>
          <div className='product-hp-container' style={{direction: 'ltr'}}>
            <div className='product-hp-content' style={{direction: 'rtl'}}>
              <h1>{"پرونده‌های دیجیتال"}</h1>
              <p>{"با بهره‌گیری از سیستم پرونده‌های دیجیتال مهروبو، دیگر نیاز نیست برای مراجعین خود از پرونده‌های کاغذی استفاده کنید. شما می‌توانید در هر لحظه توضیح جلسات، روند پیشرفت، گزارش رشد و نتایج ارزیابی‌های جلسات کودک را در لپ تاپ یا موبایل خود داشته باشید. سیستم جامع پرونده دیجیتال مهروبو به شما کمک می کند تا همواره در مسیر رشد کودک گام بردارید"}</p>
            </div>
            <div className='product-hp-image' style={{borderRadius: '50px 0 0 50px'}}>
              <img src={DigitalFilesImage}></img>
            </div>
          </div>
        </div>
        
        <div className='statics' style={{flexDirection: 'column'}}>
          <h1>{"اعتماد شما باعث افتخار ماست"}</h1>
          <div className='statics-container statistics-bottom'>
            <div className='statics-card'>
              <div className='stats-square'>
                <BsCardChecklist></BsCardChecklist>
                <p style={{marginTop: 0, marginBottom: 0}}>{"۴"}</p>
                <p style={{marginTop: 0, marginBottom: 0}}>{"کلینیک‌های هوشمند"}</p>
              </div>
            </div>
            <div className='statics-card'>
              <div className='stats-square middle'>
                <IoFileTrayFullOutline></IoFileTrayFullOutline>
                <p style={{marginTop: 0, marginBottom: 0}}>{"۱۰۷۸"}</p>
                <p style={{marginTop: 0, marginBottom: 0}}>{"تست‌های انجام شده"}</p>
              </div>
            </div>
            <div className='statics-card'>
              <div className='stats-square'>
                <IoGameControllerOutline></IoGameControllerOutline>
                <p style={{marginTop: 0, marginBottom: 0}}>{"۵۲"}</p>
                <p style={{marginTop: 0, marginBottom: 0}}>{"دانلود بازی"}</p>
              </div>
            </div>
          </div>
        </div>

        <FooterFa/>
      </div>
    )
  }
}


export default HomePage
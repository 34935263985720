import React, { useEffect, useRef, useState } from "react";
import AgoraRTC from "agora-rtc-sdk-ng"
import { GlobalProvider, useClient, useStart, useUsers } from './GlobalContext';
import './style.css'
import axios from "axios";
import { BsCameraVideo, BsCameraVideoOff, BsMic, BsMicMute } from 'react-icons/bs'

const appId = "9da260ed3a4940e3bd85c3def35b85aa"; //ENTER APP ID HERE
const token = "0069da260ed3a4940e3bd85c3def35b85aaIABTER4Nfv3vHcwIVQIdalguAIFjadDXrSFTs6DugEbutqj7PwoAAAAAEABg7BQ502VXYgEAAQDSZVdi";

const Session = () => {
  return (
    <GlobalProvider>
      <Content />
    </GlobalProvider>
  );
}

const Content = () => {
  const setUsers = useUsers()[1]
  const [start, setStart] = useStart()
  const rtc = useClient()
  const [appId, setAppId] = useState("");
  const [token, setToken] = useState("");
  const [channel, setChannel] = useState("");
  const [uid, setUid] = useState("");
  const [remoteUsers, setRemoteUsers] = useState([]) // Added
  
  useEffect(() => {
    getChannelName()
  }, [])

  const getChannelName = async() => {
    const request = await axios.post('https://request.sweetobot.com/videocall/request', {some: 'some'}, {headers: {'x-access-token': localStorage.getItem('token')}}) // Added 05/30/2022
    
    setAppId(request.data.appid)
    setToken(request.data.token)
    setChannel(request.data.channelname)
	  setUid(request.data.uid)
  }

  const getRemoteUsers = () => {
    console.log("my agora debug 0: total remote users", rtc.current.client.remoteUsers, rtc.current.client.remoteUsers.length);
    if (rtc.current.client.remoteUsers.length) {
      console.log("my agora debug 0: remote users au & vi", rtc.current.client.remoteUsers[0].uid, rtc.current.client.remoteUsers[0].hasAudio);
      if (remoteUsers.length == 0) {
        const remUsers = remoteUsers
        remUsers.push({uid: rtc.current.client.remoteUsers[0].uid, audio: rtc.current.client.remoteUsers[0].hasAudio, video: rtc.current.client.remoteUsers[0].hasVideo});
        setRemoteUsers(remUsers)
      }
    }
  }

  let init = async (isAudio, isVideo) => {
    rtc.current.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    initClientEvents()
	  // const uid = await rtc.current.client.join(appId, channel, token, null);
    const tempUid = await rtc.current.client.join(appId, channel, token, uid);
    // Create an audio track from the audio sampled by a microphone.
    rtc.current.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    // Create a video track from the video captured by a camera.
    rtc.current.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    //Adding a User to the Users State
    setUsers((prevUsers) => {
      return [...prevUsers, { uid: tempUid, audio: isAudio, video: isVideo, client: true, videoTrack: rtc.current.localVideoTrack }] // Edited
    })
    //Publishing your Streams
    rtc.current.localAudioTrack.setEnabled(isAudio); // Added
    rtc.current.localVideoTrack.setEnabled(isVideo); // Added
    await rtc.current.client.publish([rtc.current.localAudioTrack, rtc.current.localVideoTrack]);
    getRemoteUsers() // Added
    setStart(true)
  }




  const initClientEvents = () => {
    rtc.current.client.on("user-published", async (user, mediaType) => {
      // New User Enters
      await rtc.current.client.subscribe(user, mediaType);

      if (mediaType === "video") {
        const remoteVideoTrack = user.videoTrack;
        setUsers((prevUsers) => {
          return [...prevUsers, { uid: user.uid, audio: user.hasAudio, video: user.hasVideo, client: false, videoTrack: remoteVideoTrack }]

        })
      }

      if (mediaType === "audio") {
        const remoteAudioTrack = user.audioTrack;
        user.hasAudio && remoteAudioTrack.play(); // Edited
        setUsers((prevUsers) => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: user.hasAudio }
            }
            return User
          }))

        })
      }
    });

    rtc.current.client.on("user-unpublished", (user, type) => {
      //User Leaves
      if (type === 'audio') {
        setUsers(prevUsers => {
          return (prevUsers.map((User) => {
            if (User.uid === user.uid) {
              return { ...User, audio: !User.audio }
            }
            return User
          }))
        })
      }
      if (type === 'video') {
        setUsers((prevUsers) => {
          return prevUsers.filter(User => User.uid !== user.uid)
        })
      }
    });
  }

  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      {start && <Videos remUsers={remoteUsers} />}
      {!start && <ChannelForm initFunc={init} />}
    </div>
  )
}


const Videos = ({ remUsers }) => {

  const users = useUsers()[0]
  const usersDebug = useUsers()
  const rtc = useClient() // Added
  const [remoteUsers, setRemoteUsers] = useState(remUsers) // Added
  const [remote1Join, setRemote1Join] = useState(remUsers.length == 1) // Added

  useEffect(() => {
    //************************ Added ************************//
    rtc.current.client.on("user-joined", (user, type) => {
      console.log("my agora debug 1: user joined", user.uid, type);
      setRemote1Join(true);
      if (remoteUsers.length == 0) {
        remoteUsers.push({uid: user.uid, audio: false, video: false});
      } else {
        for (let i=0; i < remoteUsers.length; i++) {
          if (user.uid == remoteUsers[i].uid) {
            return
          }
        }
        const uMtemp = remoteUsers
        uMtemp.push({uid: user.uid, audio: false, video: false});
        setRemoteUsers(uMtemp)
      }
      console.log("my agora debug 2: online users", remoteUsers);
    })

    rtc.current.client.on("user-published", (user, type) => {
      console.log("my agora debug 3: user published", user.uid, type);
      if (remoteUsers.length == 0) {
        ;
      } else {
        for (let i=0; i < remoteUsers.length; i++) {
          if (user.uid == remoteUsers[i].uid) {
            // const uMtemp = remoteUsers
            // if (type == 'audio') uMtemp[i].audio = true;
            // if (type == 'video') uMtemp[i].video = true;
            // setRemoteUsers(uMtemp)
            if (type == 'audio') remoteUsers[i].audio = true;
            if (type == 'video') remoteUsers[i].video = true;
          }
        }
      }
    })

    rtc.current.client.on("user-unpublished", (user, type) => {
      console.log("my agora debug 4: user unpublished", user.uid, type);
      if (remoteUsers.length == 0) {
        ;
      } else {
        for (let i=0; i < remoteUsers.length; i++) {
          if (user.uid == remoteUsers[i].uid) {
            // const uMtemp = remoteUsers
            // if (type == 'audio') uMtemp[i].audio = false;
            // if (type == 'video') uMtemp[i].video = false;
            // setRemoteUsers(uMtemp)
            if (type == 'audio') remoteUsers[i].audio = false;
            if (type == 'video') remoteUsers[i].video = false;
          }
        }
      }
    })

    rtc.current.client.on("user-left", (user, type) => {
      console.log("my agora debug 5: user left", user.uid, type);
      setRemote1Join(false);
      if (remoteUsers.length == 0) {
        ;
      } else {
        for (let i=0; i < remoteUsers.length; i++) {
          if (user.uid == remoteUsers[i].uid) {
            // const uMtemp = remoteUsers
            // uMtemp.splice(i);
            // setRemoteUsers(uMtemp)
            remoteUsers.splice(i)
          }
        }
      }
      console.log("my agora debug 6: online users", remoteUsers);
    })
    
    
  }, [rtc])
  //************************ Added ************************//

  return (
    <div id='videos'>
      {users.length && users.map((user) =>
        <Video key={user.uid} user={user} />
      )}

      {remote1Join && !remoteUsers[0].video &&
        <div key={remoteUsers[0].uid} style={{position: 'relative', width: '95%', height: '95%', maxWidth: '75vW', maxHeight: '40vh', background: 'green'}}>
          {remoteUsers[0].uid}
          <Controls user={remoteUsers[0]} />
        </div>
      }


      {/* {remoteUsers.length && remoteUsers.map((user) => 
      !user.video && <div key={user.uid} style={{position: 'relative', width: '95%', height: '95%', maxWidth: '75vW', maxHeight: '40vh', background: 'green'}}>
        {user.uid}
        <Controls user={user} />
      </div>
      )} */}
    </div>
  )

}

export const Video = ({ user }) => {

  const vidDiv = useRef(null)

  const playVideo = () => {
    user.videoTrack.play(vidDiv.current)
  }

  const stopVideo = () => {
    user.videoTrack.stop()
  }

  useEffect(() => {
    console.log("vidDive:", vidDiv.current, user.video);
    playVideo()
    return () => {
      stopVideo()
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div className='vid' ref={vidDiv} >
      <Controls user={user} />
    </div>
  )
}


export const Controls = ({ user }) => {

  const setStart = useStart()[1]
  const setUsers = useUsers()[1]
  const rtc = useClient()

  const leaveChannel = async () => {
    // Destroy the local audio and video tracks.
    await rtc.current.localAudioTrack.close();
    await rtc.current.localVideoTrack.close();
    await rtc.current.client.leave();
    setUsers([])
    setStart(false)
  }

  const mute = (type, id) => {
    if (type === 'audio') {
      setUsers(prevUsers => {
        return (prevUsers.map((user) => {
          if (user.uid === id) {
            user.client && rtc.current.localAudioTrack.setEnabled(!user.audio)
            return { ...user, audio: !user.audio }
          }
          return user
        }))
      })
    }
    else if (type === 'video') {
      setUsers(prevUsers => {
        return prevUsers.map((user) => {
          if (user.uid === id) {
            user.client && rtc.current.localVideoTrack.setEnabled(!user.video)
            return { ...user, video: !user.video }
          }
          return user
        })
      })
    }
  }

  return (
    <div className='controls'>
      {<p className={user.audio ? 'on' : ''} onClick={() => user.client && mute('audio', user.uid)}>Mic</p>}
      {<p className={user.video ? 'on' : ''} onClick={() => user.client && mute('video', user.uid)}>Video</p>}
      {user.client && <p onClick={() => leaveChannel()}>Quit</p>}
    </div>
  )
}


const ChannelForm = ({ initFunc }) => {
  
  const [channelName, setChannelName] = useState('')
  const [appId, setappId] = useState('')
  const [audioAccess, setAudioAccess] = useState(false)
  const [videoAccess, setVideoAccess] = useState(false)

  return (
    <form className='d-flex w-100 h-100 justify-content-around align-items-center'>
      <div className='d-flex flex-column'>
        {/* <input type="text" placeholder="Enter App Id" onChange={(e) => { setappId(e.target.value) }} />
        <input type="text" placeholder='Enter Channel Name' onChange={(e) => setChannelName(e.target.value)} /> */}
        <button onClick={(e) => { e.preventDefault(); initFunc(audioAccess, videoAccess); }}>Join Call</button>
      </div>

      <div className='d-flex flex-column'>
        <div className="controls" style={{position: 'unset'}}>
          <p className={audioAccess ? "on" : ""}
            onClick={() => setAudioAccess(!audioAccess)}>
            {audioAccess ? <BsMic /> : <BsMicMute />}
          </p>
          <p className={videoAccess ? "on" : ""}
            onClick={() => setVideoAccess(!videoAccess)}>
            {videoAccess ? <BsCameraVideo /> : <BsCameraVideoOff />}
          </p>
        </div>
      </div>
    </form>
  );

}

export default Session;
module.exports = [
    "Spend 5 minutes to check your child's growth milestones",
    "Based on recent research, 1 in 44 children born with autism. By answering a few simple questions, our powerful artificial intelligence will help you screen your child for autism and know if you need further actions or not. Also, for your convenience we didn't set any price for this hight tech screening test and you can pay how much you want to support us to continue develope technologies for children health.",
    "child name",
    "mobile number",
    "Select your child's age",
    "between 18 months to 2 years",
    "between 2 years to 3 years",
    "between 3 years to 4 years",
    "over 4 years",
    "select your child's sex",
    "girl",
    "boy",
    "Is there any record of autism in your family?",
    "yes",
    "no",
    "my child is already diagnosed with autism.",
    "10 USD",
    "start",
    "enter your childs name",
    'enter your phone number',
    "enter your childs age",
    "please fill all the information",
    "discount code",
    "child family name"
]
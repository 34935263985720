import React, { useState, useRef, useEffect } from 'react'
import './index.css'
import ReactToPrint from 'react-to-print'
import { PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import garsIcon from '../../../../../assets/SarbargGars3.png'

const faScoboQuestions = require('../../../../../utils/scoboTestQuestionsFa.js')
const faGars2Questions = require('../../../../../utils/gars2TestQuestionsFa.js')
const faGars3Questions = require('../../../../../utils/gars3TestQuestionsFa.js')

function ResultModalScobo({ evaluation, closeModal, testStats, clinicName }) {

    const [testName, setTestName] = useState(evaluation.Session)
    const [testIndex, setTestIndex] = useState(evaluation.Index)
    const [testAnswer, setTestAnswer] = useState(evaluation.Answers)
    const [testQuestion, setTestQuestion] = useState(evaluation.Questions)
    const [testSubscales, setTestSubscales] = useState(evaluation.Subscales)
    const [testPercentiles, setTestPercentiles] = useState(evaluation.Percentiles)
    const [testStartDate, setTestStartDate] = useState(evaluation.StartDate)
    const [resultAds, setResultAds] = useState(evaluation.Result)
    const [score, setScore] = useState(evaluation.Score)
    const [name, setName] = useState(testStats.firstname)
    const [lName, setLName] = useState(testStats.lastname)
    const [birthResult, setBirthResult] = useState(testStats.birthdate)
    const [clinic, setClinic] = useState(clinicName)
    const [adsResults, setAdsResults] = useState([])
    const [age, setAge] = useState(0)

    const COLORS = ['#0088FE', '#D70040'];

    const data = [
        {
            label: 'عدم شباهت به اتیسم',
            percentile: 100 - score
        },
        {
            label: 'شباهت به اتیسم',
            percentile: score
        }
    ]

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
        const RADIAN = Math.PI / 180;
        // eslint-disable-next-line
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        // eslint-disable-next-line
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        // eslint-disable-next-line
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
          <text
            x={x}
            y={y}
            fill="#000000"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
          >
            {data[index].label} ({value})
          </text>
        );
    }

    const userSubscaleText = 
    [
        "رفتارهای کلیشه‌ای و تکراری",
        "تعاملات اجتماعی",
        "ارتباطات اجتماعی",
        "پاسخ‌های هیجانی",
        "چارچوب شناختی",
        "گفتار ناهنجار",
    ]

    const resultAdsText = 
    [
        "احتمال ابتلا به ASD",
        "سطح شدت ASD بر اساس DSMS",
        "توصیف"
    ]

    const componentRef = useRef(null);

    const resultIdentifier = () => {
        if (resultAds==1){
            setAdsResults(
                ["وجود ندارد",
                "",
                "عدم ابتلا به ASD"]
            )
        }
        else if (resultAds==2){
            setAdsResults(
                ["تا حدی احتمال دارد",
                "سطح ۱",
                "حمایت کم"]
            )
        }
        else if (resultAds==3){
            setAdsResults(
                ["به احتمال خیلی زیاد",
                "سطح ۲",
                "حمایت قابل توجه"]
            )
        }       
        else if (resultAds==4){
            setAdsResults(
                ["به احتمال خیلی زیاد",
                "سطح ۳",
                "حمایت خیلی زیاد"]
            )
        }
    }

    const ageCalculator = () => {
        var tavalod = new Date(birthResult)
        var date = new Date()
        var y1 = tavalod.getFullYear()
        var y2 = date.getFullYear()
        setAge(y2 - y1)
    }

    useEffect (() => {
        console.log("received id in this page is:", evaluation);
        resultIdentifier()
        ageCalculator()
    }, [])

    var userQuestion = []
    if (testName == 'SCOBO') userQuestion = faScoboQuestions
    if (testName == 'GARS2') userQuestion = faGars2Questions
    if (testName == 'GARS3') userQuestion = faGars3Questions

        return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print' ref={componentRef}>
                <div className='result-header mode-bg-4'>
                    <img style={{width:'100%'}} className='gars3-img' src={garsIcon}></img>
                </div>
                <div style={{marginTop: '0', marginBottom: '0'}} className='result-spec-table mode-text-9'>
                    <div className='result-spec-right-wrapper'>
                        <p>نام و نام خانوادگی: {name} {lName}</p>
                        <p> نام آزمونگر: {clinic}</p>
                        <p> نسبت فردی که تست را تکمیل نموده:</p>
                    </div>
                    <div className='result-spec-left-wrapper'>
                        <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                        <p>تاریخ ثبت فرم: {new Date(testStartDate).toLocaleDateString('fa-IR')}</p>
                        <p>سن: {age}</p>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '300px'}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={300} height={300}>
                            <Pie
                                data={data}
                                nameKey="label"
                                dataKey="percentile"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                                label={renderCustomizedLabel}
                            >
                                {data.map((color, i) => (
                                    <Cell key={`cell-${i}`} fill={COLORS[i % COLORS.length]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                
                <div className='result-table'>
                <div style={{marginTop: '10px', marginBottom: '10px'}} className='result-header mode-text-13'>
                    <div className='result-modal-title'> سوالات: </div>
                </div>
                {testQuestion.map((queCount, i) => (
                    <div className='table-result-items' >
                        <p className='result-item-stats'>{queCount}</p>
                        <p className='result-item-title font-12'>{userQuestion[i]}</p>
                        <p className='result-item-stats'>{testAnswer[i]}</p>
                    </div>
                ))}
                </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint 
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج تست اسکوبو'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>

            </div>
            
        </div>
    )
}

export default ResultModalScobo
import SoundFontPlayer from "soundfont-player";
import AudioContext from "./AudioContext";

const NullSoundFontPlayerNoteAudio = {
  stop() {}
};

const NullSoundFontPlayer = {
  play() {
    return NullSoundFontPlayerNoteAudio;
  }
};
const AudioPlayer = () => {
  //Audio Context
  const audioContext = AudioContext && new AudioContext();

  //soundPlayer
  let soundPlayer = NullSoundFontPlayer;
  let p = [];
  // let p1 = null;
  // let p2 = null;
  // let p3 = null;
  // let p4 = null;
  // let p5 = null;
  // let p6 = null;
  // let p7 = null;
  // let p8 = null;
  // let p9 = null;
  // let p10 = null;
  // let p11 = null;
  // let p12 = null;
  // let p13 = null;
  // let p14 = null;
  // let p15 = null;
  // let p16 = null;
  // let p17 = null;
  // let p18 = null;
  // let p19 = null;
  // let p20 = null;
  // let p21 = null;
  // let p22 = null;
  // let p23 = null;
  // let p24 = null;
  // let p25 = null;
  // let p26 = null;
  // let p27 = null;
  // let p28 = null;
  // let p29 = null;
  // let p30 = null;
  // let p31 = null;
  //setInstrument
  const Player = {
    async setInstrument(instrumentName) {
      let instrumentLoaded = false;
      await SoundFontPlayer.instrument(audioContext, instrumentName, {
        gain: 5 // Volume Control
      }).then(soundfontPlayer => {
          soundPlayer = soundfontPlayer;
          instrumentLoaded = true;
        })
        .catch(e => {
          soundPlayer = NullSoundFontPlayer;
          instrumentLoaded = false;
        });
      return instrumentLoaded;
    },
    playNote(note) {
      soundPlayer.play(note);
    },
    playScheduledNotes(notes, durations) {
      const songLength = notes.length;
      let time = audioContext.currentTime;
      for(let i = 0; i<songLength; i++) {
        if (p.length == 0) {
          p.push(soundPlayer.play(notes[i], time, { duration: durations[i]}));
        } else {
          p[i] = soundPlayer.play(notes[i], time, { duration: durations[i]});
        }
        time = time + durations[i];
      }
      // p1 = soundPlayer.play(notes[0], audioContext.currentTime, { duration: 0.5});
      // p2 = soundPlayer.play(notes[1], audioContext.currentTime+0.5, { duration: 0.5});
      // p3 = soundPlayer.play(notes[2], audioContext.currentTime+1, { duration: 0.5});
      // p4 = soundPlayer.play(notes[3], audioContext.currentTime+1.5, { duration: 1});
      // p5 = soundPlayer.play(notes[4], audioContext.currentTime+2.5, { duration: 0.5});
      // p6 = soundPlayer.play(notes[5], audioContext.currentTime+3, { duration: 1});
      // p7 = soundPlayer.play(notes[6], audioContext.currentTime+4, { duration: 0.5});
      // p8 = soundPlayer.play(notes[7], audioContext.currentTime+4.5, { duration: 1});
      // p9 = soundPlayer.play(notes[8], audioContext.currentTime+5.5, { duration: 0.5});
      // p10 = soundPlayer.play(notes[9], audioContext.currentTime+6, { duration: 0.5});
      // p11 = soundPlayer.play(notes[10], audioContext.currentTime+6.5, { duration: 0.5});
      // p12 = soundPlayer.play(notes[11], audioContext.currentTime+7, { duration: 0.5});
      // p13 = soundPlayer.play(notes[12], audioContext.currentTime+7.5, { duration: 1});
      // p14 = soundPlayer.play(notes[13], audioContext.currentTime+8.5, { duration: 0.5});
      // p15 = soundPlayer.play(notes[14], audioContext.currentTime+9, { duration: 1});
      // p16 = soundPlayer.play(notes[15], audioContext.currentTime+10, { duration: 0.5});
      // p17 = soundPlayer.play(notes[16], audioContext.currentTime+10.5, { duration: 1});
      // p18 = soundPlayer.play(notes[17], audioContext.currentTime+11.5, { duration: 0.5});
      // p19 = soundPlayer.play(notes[18], audioContext.currentTime+12, { duration: 0.5});
      // p20 = soundPlayer.play(notes[19], audioContext.currentTime+12.5, { duration: 0.5});
      // p21 = soundPlayer.play(notes[20], audioContext.currentTime+13, { duration: 0.5});
      // p22 = soundPlayer.play(notes[21], audioContext.currentTime+13.5, { duration: 1});
      // p23 = soundPlayer.play(notes[22], audioContext.currentTime+14.5, { duration: 0.5});
      // p24 = soundPlayer.play(notes[23], audioContext.currentTime+15, { duration: 1});
      // p25 = soundPlayer.play(notes[24], audioContext.currentTime+16, { duration: 0.5});
      // p26 = soundPlayer.play(notes[25], audioContext.currentTime+16.5, { duration: 1});
      // p27 = soundPlayer.play(notes[26], audioContext.currentTime+17.5, { duration: 0.5});
      // p28 = soundPlayer.play(notes[27], audioContext.currentTime+18, { duration: 0.5});
      // p29 = soundPlayer.play(notes[28], audioContext.currentTime+18.5, { duration: 0.5});
      // p30 = soundPlayer.play(notes[29], audioContext.currentTime+19, { duration: 0.5});
      // p31 = soundPlayer.play(notes[30], audioContext.currentTime+19.5, { duration: 1});
    },
    playNoteTimed(note, startTime, Time) {
      soundPlayer.play(note, audioContext.currentTime + startTime, { duration: Time});
    },
    stopScheduledNotes() {
      const songLength = p.length;
      for(let i = 0; i<songLength; i++) {
        p[i].stop();
      }
      // p1.stop();
      // p2.stop();
      // p3.stop();
      // p4.stop();
      // p5.stop();
      // p6.stop();
      // p7.stop();
      // p8.stop();
      // p9.stop();
      // p10.stop();
      // p11.stop();
      // p12.stop();
      // p13.stop();
      // p14.stop();
      // p15.stop();
      // p16.stop();
      // p17.stop();
      // p18.stop();
      // p19.stop();
      // p20.stop();
      // p21.stop();
      // p22.stop();
      // p23.stop();
      // p24.stop();
      // p25.stop();
      // p26.stop();
      // p27.stop();
      // p28.stop();
      // p29.stop();
      // p30.stop();
      // p31.stop();
      // p32.stop();
      // p33.stop();
    }
  };
  return Player;
};

export default AudioPlayer;

import React, { useEffect, useState } from 'react'
import './style.css'
import axios from 'axios'
import HeaderFa from '../header-fa'
import FooterFa from '../footer-fa'

const engText = require('../../../utils/screeningEnglishTexts.js')
const faText = require('../../../utils/screeningFarsiTexts.js')

function Screening(props) {
    const [showNameError, setShowNameError] = useState(false)
    const [showPhoneError, setShowPhoneError] = useState(false)    
    const [showAgeError, setShowAgeError] = useState(false)
    const [showMainError, setShowMainError] = useState(false)
    const [nameError, setNameError] = useState(true)
    const [phoneError, setPhoneError] = useState(true)    
    const [ageError, setAgeError] = useState(true)
    const [mainError, setMainError] = useState(true)
    const [childName, setChildName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [childAge, setChildAge] = useState(0)
    const [mobileNumber, setMobileNumber] = useState('')
    const [screeningText, setScreeningText] = useState([])
    const [countryCode, setCountryCode] = useState('')
    const [childAutism, setChildAutism] = useState('')
    const [childGender, setchildGender] = useState('boy')
    const [autismRecord, setAutismRecord] = useState('no')
    const [sendMessage, setSendMessage] = useState("Hello,I'mInScreeningTicketPage")

    useEffect(() => {
        handleRedirect();
        const lang = localStorage.getItem('lang')
        if(lang == null) {
            if(countryCode =='IR'){
                setScreeningText(faText)
            } else {
                setScreeningText(engText)
            }
        }
        else if(lang =='IR'){
            setCountryCode('IR')
            setScreeningText(faText)

        } else {
            setCountryCode('US')
            setScreeningText(engText)
        }
    }, [])

    useEffect(() => {
    if (props.languageInput != countryCode) {
        setCountryCode(props.languageInput)
        if(props.languageInput=='IR'){
            setScreeningText(faText)
            setCountryCode('IR')
        } else {
            setScreeningText(engText)
            setCountryCode('US')
       }
    }
    })

    const handleRedirect = async() => {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: sendMessage})
        return true
      }

    const childNameInput = (event) => {
        console.log('child name is', event.target.value);
        if (event.target.value == "")
            setNameError(true)
        else
            setNameError(false)
        if (event.target.value != "" && !phoneError && !ageError)
            setMainError(false)
        else
            setMainError(true)
        setChildName(event.target.value)
    } 

    const childFamilyInput = (event) => {
        setFamilyName(event.target.value)
    } 

    const mobileNumberInput = (event) => {
        if (event.target.value == "")
            setPhoneError(true)
        else
            setPhoneError(false)
        if (!nameError && event.target.value != "" && !ageError)
            setMainError(false)
        else
            setMainError(true)
        setMobileNumber(event.target.value)
    }

    const childAgeInput = (event) => {
        if (event.target.value == 0)
            setAgeError(true)
        else
            setAgeError(false)
        if (!nameError && !phoneError && event.target.value != "")
            setMainError(false)
        else
            setMainError(true)
        setChildAge(event.target.value)
    }

    const childGenderCheck = (event) => {
        setchildGender(event.target.value)
    }

    const childAutismCheck = (event) => {
        setAutismRecord(event.target.value)
    }

    const submitHandler = async(event) => {
        event.preventDefault()
        if(childName != "") 
            setShowNameError(false)
        else 
            setShowNameError(true)
        if(mobileNumber != "") 
            setShowPhoneError(false)
        else    
            setShowPhoneError(true)
        if(childAge != 0) 
            setShowAgeError(false)
        else 
            setShowAgeError(true)
        if(childAge !=0 && mobileNumber !='' && childName !='') {
            setShowMainError(false)
            const response = await axios.post('https://request.sweetobot.com/registers/request', {
                firstname: childName,
                lastname: familyName,
                phone: mobileNumber,
                age: childAge-1,
                sex: childGender,
                autismBackground: autismRecord,
                autismDiagnosis: childAutism
            })
            if (response.data.reg == true) {
                localStorage.setItem('token', response.data.token)
                if (mobileNumber == '989914500249' || mobileNumber == '989126468996' ||
                    mobileNumber == '09169092292'  || mobileNumber == '09169092292 ' || mobileNumber == ' 09169092292')
                    window.location.href = '/screening-test'
                else
                    window.location.href = `https://www.sweetobot.com/bank-redirection?status=2&CN=-1&PN=SCOBO&FN=${childName}&LN=None&MN=${mobileNumber}&WT=${response.data.token}`
                
            }
        }
        else
            setShowMainError(true)
    }
    const submitHandlerPaypal = async(event) => {
        event.preventDefault()
        if(childName != "") 
            setShowNameError(false)
        else 
            setShowNameError(true)
        if(mobileNumber != "") 
            setShowPhoneError(false)
        else    
            setShowPhoneError(true)
        if(childAge != 0) 
            setShowAgeError(false)
        else 
            setShowAgeError(true)
        if(childAge !=0 && mobileNumber !='' && childName !='') {
            setShowMainError(false)
            const response = await axios.post('https://request.sweetobot.com/registers/request', {
                firstname: childName,
                lastname: familyName,
                phone: mobileNumber,
                age: childAge-1,
                sex: childGender,
                autismBackground: autismRecord,
                autismDiagnosis: childAutism
            })
            if (response.data.reg == true) {
                localStorage.setItem('token', response.data.token)
                // const receipt = await axios.post('https://request.sweetobot.com/receipts/request', { // Save the pre receipt
                //     firstName: childName, 
                //     lastName: familyName, 
                //     phoneNumber: mobileNumber,
                //     amount: "USD",
                //     websitetoken: localStorage.getItem('token'),
                //     bookingtoken: "Paypal token",
                //     status: "going to the pay"
                // })
                if (mobileNumber == '989914500249' || mobileNumber == '989126468996' ||
                    mobileNumber == '09169092292'  || mobileNumber == '09169092292 ' || mobileNumber == ' 09169092292')
                    window.location.href = '/fa/screening-test'
                else
                    document.forms[0].submit();
                
            }
        }
        else
            setShowMainError(true)
    }

    return (
        <div>
            <HeaderFa/>
            <div className='screening-container'>
                <div className='screening-page'>
                    <div className='left-screening'>
                        <h1>{"غربالگری هوشمند اسکوبو"}</h1>
                        <p>{"غربالگری هوشمند اسکوبو، دقیق‌ترین غربالگری اتیسم در دنیا می‌باشد که با بهره‌گیری از هوش مصنوعی، جواب سوالات شما را با بیش از هزار داده از پیش یادگیری شده مقایسه و تولید می کند. پر کردن این تست حدود ۵ دقیقه زمان می‌برد و پاسخ آن در عرض چند ثانیه برای شما تولید می‌شود و به شما برای تصمیم گیری جهت مراجعه به متخصص کمک می‌کند"}</p>
                    </div>
                    <div className='right-screening'>
                            <div className='form'>               
                                <div className='name-familyname'>
                                    <input type='text' placeholder={screeningText[2]} onChange={(event) => childNameInput(event) }
                                    style={{marginLeft:'5px', marginRight:'5px'}}  className='input-box-childName'>
                                    </input>
                                    <input type='text' placeholder={screeningText[23]} onChange={(event) => childFamilyInput(event)} 
                                    style={{marginRight:'5px', marginLeft:'5px'}}  className='input-box-childName'>
                                    </input>
                                </div>    
                                <div className='error-text'>{showNameError ? <p>{screeningText[18]}</p>: null}</div>
                                <input type='number' placeholder={screeningText[3]} onChange={(event) => mobileNumberInput(event)}
                                    className='input-box-mobileNumber'>
                                </input>
                                <div className='error-text'>{showPhoneError ? <p>{screeningText[19]}</p>: null}</div>
                                <select type='dropdown' onChange={(event) => childAgeInput(event)} className='drop-down'>
                                    <option value={0}>
                                        {screeningText[4]}
                                    </option>
                                    <option value={1}>
                                        {screeningText[5]}
                                    </option>
                                    <option value={2}>
                                        {screeningText[6]}
                                    </option>
                                    <option value={3}>
                                        {screeningText[7]}
                                    </option>
                                    <option value={4}>
                                        {screeningText[8]}
                                    </option>
                                </select>
                                <div className='error-text'>{showAgeError ? <p>{screeningText[20]}</p>: null}</div>
                                <div className='radiobox-section'>
                                    <div className='gender-radiobox'>
                                        <p>{screeningText[9]}</p>
                                        <div className='radio-buttons-box'>
                                            <div className='radio-button-1'>
                                                <input type='radio' checked={childGender === "girl"} value= 'girl' onChange={(event) => childGenderCheck(event)}/>
                                                <label>{screeningText[10]}</label>
                                            </div>
                                            <div className='radio-button-2'>
                                                <input type='radio' checked={childGender === "boy"} value= 'boy' onChange={(event) => childGenderCheck(event)}/>
                                                <label>{screeningText[11]}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='autism-checkbox'>
                                    <input type='checkbox' onChange={(event) => childAutismCheck(event)} />
                                    <p>{screeningText[15]}</p> 
                                </div>
                                <div className='field-container'>
                                    {countryCode == "IR" &&
                                    <form className='price-field' onSubmit={(event) => submitHandler(event)}>
                                        <div className='price-field-amount'>
                                            <p>{screeningText[16]}</p>
                                            <div className='price-field-discount'>
                                                <input type='text' placeholder={screeningText[22]}></input>
                                                <button type='button'></button>
                                            </div >
                                        </div>
                                        <button type='submit' className='control-button'> {screeningText[17]} </button>
                                    </form>
                                    }
                                    {countryCode != "IR" &&
                                    <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" onSubmit={(event) => submitHandlerPaypal(event)}>
                                        <input type="hidden" name="cmd" value="_s-xclick"></input>
                                        <input type="hidden" name="hosted_button_id" value="Y6FSAST43FUVW"></input>
                                        <table>
                                        <tbody><tr><td><input type="hidden" name="on0" value="Buy Coffee for Us!"></input></td></tr></tbody>
                                        <tbody><tr><td><select name="os0">
                                            {/* <option value="1 Cup">1 Cup $5,00 USD</option>
                                            <option value="2 Cups">2 Cups $10,00 USD</option>
                                            <option value="3 Cups">3 Cups $15,00 USD</option>
                                            <option value="4 Cups">4 Cups $20,00 USD</option>
                                            <option value="5 Cups">5 Cups $25,00 USD</option>
                                            <option value="10 Cups">10 Cups $50,00 USD</option> */}
                                            <option value="1 Cup">$5,00 USD</option>
                                            <option value="2 Cups">$10,00 USD</option>
                                            <option value="3 Cups">$15,00 USD</option>
                                            <option value="4 Cups">$20,00 USD</option>
                                            <option value="5 Cups">$25,00 USD</option>
                                            <option value="10 Cups">$50,00 USD</option>
                                        </select></td></tr></tbody>
                                        </table>
                                        <input type="hidden" name="currency_code" value="USD"></input>
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"></input>
                                        <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"></img>
                                    </form>
                                    }
                                </div>
                                
                                <div className='error-text-main'>{showMainError ? <p>{screeningText[21]}</p>: null}</div>
                            </div>        
                    </div>
                </div>
            </div>
            <FooterFa/>
        </div>
        
    )
}

export default Screening
import React from "react";
import axios from "axios";
import LoadingPage from "../loading-page";

class Redirection extends React.Component {


    constructor(props) {
        super(props)
    
        this.state = {
            countryCode: 'IR',
            showSidebar: false,
            isLoaded: false,
            redirectedURL: props.inputURL
        }
    }

    componentDidMount = async() => {
        const lang = localStorage.getItem('lang')
        
        if (lang == null) {
            try {
                const userLocation = await this.getLocation()
                this.setState({countryCode: userLocation})
                localStorage.setItem('lang', userLocation) // This api and command returns two letter country code (IR, US, NL, ...)

                if (userLocation === 'IR') window.location.href = '/fa' + this.state.redirectedURL
                else window.location.href = '/en' + this.state.redirectedURL
                
                //////// A code to handle filtering in Iran! ////////
                // var timer = 0
                // const timerInterval = setInterval(() => {
                //     if (timer > 4) {
                //         clearInterval(timerInterval);
                //         this.setState({countryCode: 'IR'})
                //         window.location.href = '/fa' + this.state.redirectedURL
                //     } else {
                //         timer = timer + 1;
                //     }
                // }, 1000);
                ////////// End of handle filtering in Iran //////////
            } catch {
                this.setState({countryCode: 'IR'})
                window.location.href = '/fa' + this.state.redirectedURL
            }
        } else if(lang === 'IR') {
            this.setState({countryCode: 'IR'})
            window.location.href = '/fa' + this.state.redirectedURL
        } else {
            this.setState({countryCode: 'US'})
            window.location.href = '/en' + this.state.redirectedURL
        }
        this.setState({isLoaded: true})
    }
        
    getLocation = async() => { // Edited in 1.7.0
        var locationProps = null
        locationProps = await axios.get('https://ip.nf/me.json')
        
        return locationProps.data.ip.country_code
    }
    
    render () {

        if(this.state.isLoaded === false){
            return (
                <LoadingPage/>  
            )
        }
        
        return (
            <div className="h-100 d-ltr d-flex flex-column justify-content-center align-items-center">
                <h1>Please wait to redirect ...</h1>
                <h1>... لطفا چند لحظه منتظر بمانید</h1>
            </div>
        )
    }
}

export default Redirection;
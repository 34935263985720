import React from 'react'
import './style.css'
import axios from 'axios'
import ProfileImageCEO from '../../../../assets/CEO-Profile.png'
import ProfileImageGRP from '../../../../assets/GRP-Profile.png'
import ProfileImageADVHadi from '../../../../assets/ADV-Profile-Hadi.png'
import ProfileImageADVLeila from '../../../../assets/ADV-Profile-Leila.png'
import ProfileImagePSY from '../../../../assets/PSY-Profile.png'
import IntelligentSystem from '../../../../assets/products-aipanel-02.png'

class AboutBody extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sendMessage: "Hello,I'mInAboutPage"
        }
    }

    componentDidMount = () => {
        this.handleRedirect();
    }

    //put this function in the related component in componentDidMount
    async handleRedirect () {

        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})

    }

    render() {
        return(
            <div className="main-body">
                <div className="about-title">
                    <h1>ما اینجاییم تا شما</h1>
                    <h1>مدرن‌ترین کلینیک را داشته باشید</h1>
                    <h3>از تشخیص تا توانبخشی در کنار شما خواهیم بود</h3>
                </div>
                <div className="about-body">
                    <img src={IntelligentSystem} style={{maxWidth: '90%', marginTop: '-10%'}}></img>
                    {/* <div className="cards-container">
                        <div className="profile-card">
                            <img src={ProfileImageCEO}></img>
                            <h2>آرش فندرسکی</h2>
                            <p>تحقیق و توسعه</p>
                            <p>کارشناسی ارشد</p>
                        </div>
                        <div className="profile-card">
                            <img src={ProfileImageGRP}></img>
                            <h2>پریان ناصری‌نیا</h2>
                            <p>گرافیک و تولید محتوا</p>
                            <p>کارشناسی</p>
                        </div>
                        <div className="profile-card">
                            <img src={ProfileImageADVLeila}></img>
                            <h2>لیلا کاشانی</h2>
                            <p>استاد مشاور</p>
                            <p>عضو هیئت علمی دانشگاه علوم تحقیقات</p>
                        </div>
                        <div className="profile-card">
                            <img src={ProfileImagePSY}></img>
                            <h2>سحر محمدی</h2>
                            <p>روانشناس کودک</p>
                            <p>کارشناسی ارشد</p>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default AboutBody
import React from 'react';
import './styles.css'; //import CSS file for styling
import productImage from '../../../assets/website-slider0.png'; //import product image
import FooterEn from "../footer-en";
import HeaderEn from "../header-en";

class HomePageEn extends React.Component {
    
    render() {
        return (
            <div>
                <HeaderEn/>
                <div className="landing-container" style={{direction: 'ltr'}}>
                    <div className="content-container">
                        <h1 className="landing-heading">Welcome to Our AI-Powered Autism Screening</h1>
                        <p className="landing-text">We believe that everyone deserves access to quality autism screening. Our online form is designed to help you get started.</p>
                        <button className="cta-button">Get Started</button>
                    </div>
                    <div className="image-container">
                        <img src={productImage} alt="Product Image" className="product-image" />
                    </div>
                </div>
                <FooterEn/>
            </div>
        )
    }
};

export default HomePageEn
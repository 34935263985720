import React, { useEffect, useState } from 'react'
import './style.css'
import axios from 'axios'
import SliderClinic from '../slider-products'

import sliderClinic1 from '../../../assets/products-aipanel-01.png'
import sliderClinic2 from '../../../assets/products-aipanel-02.png'
import HeaderFa from '../header-fa'
import FooterFa from '../footer-fa'

const AiClinic = (props) => {
    
    const [sendMessage, setSendMessage] = useState("Hello,I'mInProductPage")
    const [clinicName, setClinicName] = useState('')
    const [clinicNumber, setClinicNumber] = useState('')
    const [userName, setUserName] = useState('')
    const [userLName, setUserLName] = useState('')
    const [priceAmount, setPriceAmount] = useState(5000000)
    const [emptyFieldError, setEmptyFieldError] = useState(false)

    useEffect(() => {
        handleRedirect();
        
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const receivedId = params.get('id');
        if (parseInt(receivedId)==3) {
            setClinicName('مرکز اتیسم آوات')
            setClinicNumber('٠٢١٦٥٣٥٦١٥۶')
        }
    }, [])

    const handleRedirect = async() => {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: sendMessage})
        return true
    }

    const handleSubmit = async() => {
        if(clinicName !='' && clinicNumber !='' && userName !='' && userLName !='') {
            const receipt = await axios.post('https://request.sweetobot.com/receipts/request', 
            {
                firstName: clinicName, 
                lastName: 'ClinicalPayment', 
                phoneNumber: clinicNumber,
                amount: 5000000,
                websitetoken: "Not signed in, clinic page!",
                bookingtoken: "Mehrobo website, not needed",
                status: "going to the bank"
            })
            if (priceAmount == 5000000)
                window.location.href = `https://www.sweetobot.com/bank-redirection?status=5&FN=${clinicName}&LN=ClinicalPayment&PN=${clinicNumber}&WT=None`
        } else {
            setEmptyFieldError(true)
        }
    }

    
    return (
        <div>
            <HeaderFa/>
            <div className='product'>
                <div className='product-container'>
                    <div className='right-wrapper-clinic'>
                        <SliderClinic
                        SliderPicture1={sliderClinic1}
                        SliderPicture2={sliderClinic2}
                        />
                    </div>
                    <div className='left-wrapper-clinic'>
                        <h1 className='mode-text-4'>کلینیک هوشمند مهروبو</h1>
                        <h2>خدمات آنلاین کلینیک شامل تست گارس۲، گارس۳ ASQ-3، برگزاری جلسات، بازی‌های شناختی، پرونده دیجیتال </h2>
                        <p  className='mode-text-4'>کلینیک هوشمند مهروبو، محصولی آنلاین و بر پایه‌ی تکنولوژی جهت تسهیل خدمات کلینیک‌ها در حوزه‌ی کودک می‌باشد. این محصول با فراهم آوردن انواع تست‌ها و ارزیابی‌های مورد نیاز کلینیک‌ها، این امکان را برای کلینیک‌ها فراهم می‌کند که ارزیابی‌ها را دقیق‌تر، در زمانی کوتاه‌تر و با هزینه‌ای پایین‌تر برای مخاطبان خود انجام می‌دهد. </p>
                        <div className='price-container'>
                            <p>قیمت محصول:</p>
                            <p className='mr-3'>۵۰۰٫۰۰۰ تومان</p>
                        </div>
                        <div className='input-container mb-2'>
                            <input className='input-default w-50' type='text' placeholder={'نام'} onChange={(event) => setUserName(event.target.value) } value={userName}></input>
                            <input className='input-default w-50' type='text' placeholder={'نام خانوادگی'} onChange={(event) => setUserLName(event.target.value) } value={userLName}></input>
                        </div>
                        <div className='input-container mb-2'>
                            <input className='input-default w-50' type='text' placeholder={'نام کلینیک'} onChange={(event) => setClinicName(event.target.value) } value={clinicName}></input>
                            <input className='input-default w-50' type='number' placeholder={'شماره موبایل'} onChange={(event) => setClinicNumber(event.target.value) } value={clinicNumber}></input>
                        </div>
                        <button className='btn-default mode-bg-2 w-100 mt-2' onClick={handleSubmit}>پرداخت و رزرو محصول</button>
                        <div className='error-text-main'>{emptyFieldError ? <p>لطفا فرم را کامل کنید</p>: null}</div>
                    </div>
                </div>
            </div>
            <FooterFa/>
        </div>
    )
}

export default AiClinic
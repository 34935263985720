const dataSlider = [
  {
    id: 0,
  },

  {
    id: 1,
  },
];

export default dataSlider;
module.exports = [
    'آیا کودک از توانایی گفتار در حد ساختن جملات و انجام گفتگو برخوردار است؟',
    'اگر کودک تنها بماند، اکثر وقت خود را صرف انجام کارهایی تکراری یا کلیشه‌ای می‌کند',
    'درگیر محرک خاصی است که وقتی زیاد می‌شوند، غیر طبیعی به نظر می‌رسد',
    'به دستانش، اشیا یا چیزهایی که در محیط هستند برای حداقل ۵ ثانیه خیره می‌شود',
    'برای مدت ۵ ثانیه یا بیشتر انگشتانش را به سرعت جلوی چشمانش حرکت می‌دهد',
    'هنگام حرکت از یک مکان به مکان دیگر، جهش‌هایی سریع و حرکاتی ناگهانی انجام می‌دهد',
    'انگشتان یا دست‌هایش را در مقابل صورت یا در طرفین به صورت بال بال تکان می‌دهد',
    'از صداهای جیغ‌گونه (برای مثال، اِاِاِ اِاِاِ اِاِاِ) یا صداهایی مشابه جهت تحریک خودش استفاده می‌کند',
    'از اسباب بازی یا وسایل به طور نامناسبی استفاده می‌کند (برای مثال، ماشین‌ها را می‌چرخاند، بخش‌های اسباب بازی را از هم جدا می‌کند)',
    'برخی کارها را به صورت تکراری و با آداب و رفتارهای مشخصی انجام می‌دهد',
    'وقتی با اسباب بازی یا اشیا بازی می‌کند، درگیر رفتارهای کلیشه‌ای می‌شود',
    'صداهای نامفهومی را (غان و غون) مدام تکرار می‌کند',
    'علاقه‌ی غیر معمولی از نظر حسی به اسباب بازی‌ها، اعضای بدنش یا اشیا نشان می‌دهد',
    'رفتارهایی اجباری یا غیر معمول از خود نشان می‌دهد',
    'شروع کننده مکالمه با کودکان هم سن خود و یا دیگران نیست',
    'کم‌توجهی یا بی‌توجهی به آنچه دیگران انجام می‌دهند',
    'در شروع کردن باری با دیگران و یا یادگیری از دیگران موفق نیست',
    'ایماء و اشاره و نشانه‌های ظاهری دگیران را به سمت چیزی دنبال نمی‌کند (به عنوان مثال زمانی که فردی سرش را به سمت چیزی حرکت و یا اشاره می‌کند و یا از سایر حرکات در زبان بدن استفاده می‌کند.)',
    '(به نظر می‌رسد نسبت به توجه دیگران بی‌تفاوت است.(عدم تلاش برای جذب یا حفظ توجه دیگران',
    'هنگام تعمل با دیگران لذت خیلی کمی از خود نشان می‌دهد',
    'از نشان دادن اسباب بازی و سایر اشیاء به دیگران هیجان زده و خوشحال نمی‌شود',
    'به نظر می‌رسد به اشاره کردن و نشان دادن چیزهای مختلف در محیط پیرامون به دیگری علاقه‌مند نیست',
    'به نظر می‌رسد علاقه و تمایلی به تعامل دیگری با خودش ندارد',
    'وقتی که دیگران تلاش می‌کنند با وی تعامل داشته باشند یا واکنشی نشان نمی‌دهد و یا حداقل واکنش را دارد',
    'ارتباط اجتماعی متقابل از خود نشان نمی‌دهد؛ مثلا در پاسخ به "بای بای" کردن دیگران داوطلبانه "بای بای" نمی‌کند',
    'تلاش برای دوست شدن با دیگران انجام نمی‌دهد',
    'قادر به انجام یک بازی خلاقانه و تخیلی نیست',
    'علاقه‌ای به دیگران ندارد یا علاقه‌ی بسیار کمی دارد',
    'واکنش‌های نامناسب به طنز نشان می‌دهد؛ (مثلا به شوخی، جوک و داستان‌های خنده‌دار نمی‌خندد)',
    'درک و فهم جوک برایش سخت است',
    'در درک عبارات عامیانه مشکل دارد',
    'وقتی فردی با او شوخی می‌کند مشکل داشته و به سختی متوجه می‌شود',
    'در فهم این که او را دست می‌اندازند، مشکل دارد',
    'به سختی می‌فهمد که چه عاملی باعث شده دیگری او را دوست نداشته باشد',
    'در پیش بینی پیامد احتمالی وقایع اجتماعی ناتوان است',
    'به نظر نمی‌رسد بفهمد که مردم فکر و احساساتی متفاوت با او دارد',
    'به نظر نمی‌رسد بفهمد دیگران بعضی چیزها را نمی‌دانند.',
    'در صورت تغییر یا وقوع اشتباه در امری به اطمینان خاطر زیادی برای اعتماد مجدد نیاز دارد',
    'وقتی قادر به انجام کاری نیست سریع ناامید می‌شود',
    'در هنگام ناکامی قشقرق به پا می‌کند',
    'زمانی که روال عادی و معمول تغییر می‌کند ناراحت و آشفته می‌شود',
    'وقتی به او دستوری می‌دهیم یا می‌خواهیم کاری را انجام دهد پاسخ منفی می‌دهد',
    'در واکنش به صداهای بلند و غیرمنتظره عکس العمل‌های شدیدی مانند گریه، جیغ، داد و فریاد و قشقرق نشان می‌دهد',
    'وقتی چیزی را با روش خودش بدست نمی‌آورد قشقرق می‌کند',
    'وقتی از انجام کاری که لذت می‌برد نهی می‌شود قشقرق به پا می‌کند',
    'به طوری غیر معمول دقیق صحبت می‌کند',
    'معانی کاملا واضحی را برای کلمات استفاده می‌کند',
    'بیش از حد در مورد یک موضوع صحبت می‌کند',
    'در موضوعات مشخصی، دانش و مهارت فوق‌العاده‌ای از خود نشان می‌دهد',
    'حافظه‌ی عالی‌ای دارد',
    'علاقه‌ی زیاد و به گونه‌ای شدید به موضوعات ذهنی مشخصی نشان می‌دهد',
    'حرفای ساده لوحانه می‌زند (بدون در نظر گرفتن عکس‌العملی که در دیگران ایجاد می‌شود)',
    'کلمات یا جملات را با کلام یا علامت دادن مرتبا تکرار می‌کند (اکو می‌کند)',
    'کلمات را به گونه‌ای بی ربط تکرار می‌کند (کلمات یا جملاتی که قبلا شنیده را تکرار می‌کند)',
    'با صدا (یا علامت)یی یکنواخت صحبت می‌کند (یا ابراز می‌کند)',
    'کلمات "آره" و "نه" را  به طور نامناسب به کار می‌برد. مثلا وقتی از او پرسیده می‌شود آیا می‌خواهی مجازات شوی می‌گوید "بله" یا وقتی از او پرسیده می‌شود آیا اسباب بازی یا جایزه مورد علاقه‌ات را می‌خواهی می‌گوید "نه"',
    'وقتی میخواهد به خودش اشاره کند، به جای کلمه‌ی "من"، از "او" استفاده می‌کند',
    'صحبت کردنش به لحاظ تن صدا، بلندی صدا و نیز سرعت کلمات غیر عادی به نظر می‌رسد',
    'کلمات یا جملات به خصوصی را به زبان می‌آورد که برای دیگران هیچ معنی ندارند',
]
module.exports = [
    'Is the child competent of basic speech skills such as making sentences or having conversations with the others.',
    'If you point out to an interesting item, does the child turn around?',
    'Does the child pay attention to something interesting far away or out of window?',
    'If you call the child, does she pay attention to her name?',
    'Shows body parts when asked in 18 months.',
    'Does he have weak attention or short attention span?',
    'Between the ages of 1-3, does the child have weak eye contact?',
    'Are the child’s parents worried about her language delay?',
    'Does the child have less eye contact, compared to other children?',
    'Does the child use the index finger for asking things?',
    'The child brings items to show you?',
    'When you take her to the park, she shows you the things that she likes',
    'The child contacts you only to meet her needs (like hunger,…).',
    'The child tries to grab your attention by calling you or making sounds.',
    'The child laughs, screams, scares, or cries for no apparent reason.',
    'The child makes repeated loud voices to stimulate herself.',
    'The tone or quality of her voice is unusual.',
    'The child enjoys being held or cuddled from infancy or toddlerhood.',
    'The child has irregular sleeping pattern and waking up in the middle of night.',
    'The child has stereotypical movements like turning around, swinging, jumping, flapping or moving hand or finger in front of face. ',
    'The child is attracted to twirling objects like car wheels, plates or fans.',
    'The child plays with various different toys.',
    'Enjoys collecting complete collections.',
    'The child has unusual interests like traffic lights,…* trains or airplains schedules.',
    'Loves special activities like moving car wheels, and turning on and off the lights regardless of the others disapproval. ',
    'The child repeats an activity over and over again in a same manner.',
    'The child accepts change very hard.',
    'She throws tantrum when wants to move other children to another activity.',
    'Compared to other children, The child’s emotional expressions are inappropriate for different social situations.',
    'The child’s emotional mood swings are not appropriate for the situations with confronting others (she does not understand emotional expressions levels).',
    'The child has limited face expressions range in the age of two (like smiling, surprise, or anger). ',
    'The child’s empathizing level is low.',
    'The child has an exceptional long term memory for events (e.g. remembers scenes happened years ago).',
    'The child has incredible talents?',
    'The child can imitate you if you would do something in front of her.',
    'If you move your face part, the child would imitate you.',
    'The child can hold pen or pencil correctly.',
    'The child draws with colored pencils.',
    'At age 4 or 5, the child shows disagreement with parents by moving head and shoulders.',
    'The child seems to be anxious or lost at age 3-5.',
    'The child’s choice of words is shallow and literal* (like for expressions or methaphors). ',
    'The child asks her mother for permission to play.',
    'The child understands the difference between joking and talking seriously.',
    'The child can call out her friends’ names.',
    'The child uses personal pronouns correctly (I, you, she,…).',
    'The child uses “I” work incorrectly.',
    'The child asks about every day events.',
    'The child talks about every day events like day care.',
    'The child can change the subject of the conversation.',
    'The child has problems in correcting a conversation (e.g., when is confused, does not ask for clarification, just simply changes the subject or thinks about it for a long time to answer).',
    'The child can follow reciprocal conversations.',
    'The child speaks with others based on her interests in a single-sided format*.',
    'The child can follow conversations with her peers.',
    'The child can initiate conversations with adults or peers.',
    'The child can participate in imaginative games with other children and plays certain roles.',
    'The child does not like interactive games with peers between the ages of 1-3.',
    'The child can make contact with peers and have social interactions*.',
    'It seems like the child wants to make friends but does not know how to.',
    'The child likes to be alone and does not communicate with others',
    'The child neglects others’ presence like nobody exists.',
    'The child neglects peers’ presence and avoids contacting them. ',
    'The child prefers to play alone.',
    'The child shares her toys and hobbies with others.',
    'The child can enter a peer group easily.',
    'The child does not comprehend the method of playing with others (for example lack of knowledge about cooperative play).',
    'The child can play symbolic/pretend play at age 3 (e.g., pretend to be super heroes or have tea parties).',
    'The child has problems in interacting with/getting along with social rules.',
    'The child has problems in empathizing with others in cooperative play.',
    'The child fails to start up a simple game with the others or participate in social games.',
]
import React from 'react'
import './index.css'

function Modal({ closeModal}) {

    return (
        <div className='access-modal-background'>
            <div className='access-modal-container'>
                <div className='access-modal-title'>
                    هنوز تست توسط کاربر تکمیل نشده است
                </div>
                <button onClick={() => closeModal(false)}>
                        متوجه شدم
                </button>
            </div>
        </div>
    )
}

export default Modal
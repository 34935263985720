import React, { useEffect, useState } from 'react'
import './style.css'
import logoImage from '../../../assets/instagram-logo.png'
import axios from 'axios'

const enText = require('../../../utils/footerEnglishTexts.js')
const faText = require('../../../utils/footerFarsiTexts.js')

function FooterEn(props) {

  const [footerText,setFooterText] = useState(enText)
  const [countryCode, setCountryCode] = useState([])

  return (
      <footer>
        <div className='footer-container'>
          <div className='footer-container-right'>
            <h1>{footerText[0]}</h1>
            <p>{footerText[1]}</p>
            <p>{footerText[2]}</p>
          </div>
          <div className='footer-container-left'>
            <a className='adress-mehrobo-instagram' href='https://www.instagram.com/mehroboo'>
              <p>www.instagram.com/mehroboo</p>
              <img src={logoImage}></img>
            </a>
          </div>
        </div>
        <div className='company-location'>
          {/* <p>{footerText[3]}</p> */}
        </div>
        <div className='section-all-rights-reserved'>
          <p>{footerText[4]}</p>
        </div>
      </footer>
  )
}

export default FooterEn
import React, { useState, useRef, useEffect } from 'react'
import './style.css'
import ReactToPrint from 'react-to-print'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import garsIcon from '../../../../../assets/SarbargGars2.png'

const faScoboQuestions = require('../../../../../utils/scoboTestQuestionsFa.js')
const faGars2Questions = require('../../../../../utils/gars2TestQuestionsFa.js')
const faGars3Questions = require('../../../../../utils/gars3TestQuestionsFa.js')

function ResultModalGars2({ evaluation, closeModal, testStats, clinicName }) {

    const [testName, setTestName] = useState(evaluation.Session)
    const [testIndex, setTestIndex] = useState(evaluation.Index)
    const [testAnswer, setTestAnswer] = useState(evaluation.Answers)
    const [testQuestion, setTestQuestion] = useState(evaluation.Questions)
    const [testSubscales, setTestSubscales] = useState(evaluation.Subscales)
    const [testPercentiles, setTestPercentiles] = useState(evaluation.Percentiles)
    const [testStartDate, setTestStartDate] = useState(evaluation.StartDate)
    const [resultAds, setResultAds] = useState(evaluation.Result)
    const [score, setScore] = useState(evaluation.Score)
    const [name, setName] = useState(testStats.firstname)
    const [lName, setLName] = useState(testStats.lastname)
    const [birthResult, setBirthResult] = useState(testStats.birthdate)
    const [clinic, setClinic] = useState(clinicName)
    const [adsResults, setAdsResults] = useState([])
    const [age, setAge] = useState(0)

    const data = [
        {
            label: 'نمرات استاندارد'
        },
        {
            label: 'رفتارهای کلیشه‌ای',
            subscale: null
        },
        {
            label: 'ارتباط',
            subscale: null
        },
        {
            label: 'تعاملات اجتماعی',
            subscale: null
        },
        {
            label: 'شاخص اتیسم'
        }
    ]

    if (testSubscales.length == 3) {
        data[1].subscale = testSubscales[0]
        data[2].subscale = testSubscales[1]
        data[3].subscale = testSubscales[2]
    } else {
        data[1].subscale = testSubscales[0]
        data[2].subscale = (testSubscales[1] + testSubscales[0])/2
        data[3].subscale = testSubscales[1]
    }

    const userSubscaleText = 
    [
        "رفتارهای کلیشه‌ای",
        "ارتباطات",
        "تعاملات اجتماعی",
    ]
    if (testSubscales.length == 3) {
        ;
    } else {
        userSubscaleText.splice(1,1)
    }

    const resultAdsText = 
    [
        "احتمال ابتلا به ASD",
        "سطح شدت ASD بر اساس DSM5",
        "توصیف"
    ]

    const componentRef = useRef(null);

    const resultIdentifier = () => {
        if (resultAds==1){
            setAdsResults(
                ["وجود ندارد",
                "",
                "عدم ابتلا به ASD"]
            )
        }
        else if (resultAds==2){
            setAdsResults(
                ["تا حدی احتمال دارد",
                "سطح ۱",
                "حمایت کم"]
            )
        }
        else if (resultAds==3){
            setAdsResults(
                ["به احتمال خیلی زیاد",
                "سطح ۲",
                "حمایت قابل توجه"]
            )
        }       
        else if (resultAds==4){
            setAdsResults(
                ["به احتمال خیلی زییاد",
                "سطح ۳",
                "حمایت خیلی زیاد"]
            )
        }
    }

    const ageCalculator = () => {
        var tavalod = new Date(birthResult)
        var date = new Date()
        var y1 = tavalod.getFullYear()
        var y2 = date.getFullYear()
        setAge(y2 - y1)
    }

    useEffect (() => {
        console.log("received id in this page is:", evaluation);
        resultIdentifier()
        ageCalculator()
    }, [])

    var userQuestion = []
    if (testName == 'SCOBO') userQuestion = faScoboQuestions
    if (testName == 'GARS2') userQuestion = faGars2Questions
    if (testName == 'GARS3') userQuestion = faGars3Questions


    return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print font-12' ref={componentRef}>
                    <div className='result-header mode-bg-4'>
                        <img src={garsIcon}></img>
                    </div>
                    <div style={{marginTop: '0', marginBottom: '0'}} className='result-spec-table mode-text-9'>
                        <div className='result-spec-right-wrapper'>
                            <p>نام آزمودنی: {name} {lName}</p>
                            <p> نام آزمونگر: {clinic}</p>
                            <p> نسبت فردی که تست را تکمیل نموده:</p>
                        </div>
                        <div className='result-spec-left-wrapper'>
                            <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                            <p>تاریخ ثبت فرم: {new Date(testStartDate).toLocaleDateString('fa-IR')}</p>
                            <p>سن: {age}</p>
                        </div>
                    </div>
                <div className='d-flex'>
                    <div className='d-flex flex-column w-50'>
                        <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                            <div className='result-modal-title d-flex'>خلاصه نمرات: </div>
                        </div>
                        <div className='result-table' style={{marginBottom: '0', marginTop: '0', borderStyle: 'none'}}>
                            <div className='table-result-items'>
                                <p className='result-item-title' ></p>
                                <p className='result-item-stats mode-text-13 w-35'>نمره استاندارد</p>
                                <p className='result-item-stats mode-text-13 w-35'>درصد شباهت با اتیسم:</p>
                                <p className='result-item-stats mode-text-13 w-35'>خطای استاندارد:</p>
                            </div>
                        </div>
                        <div style={{marginTop: '5px', marginBottom: '0px'}} className='result-table'>
                        {testSubscales.map((subCount, i) => (
                            <div className='table-result-items' key={i}>
                                <p className='result-item-title' >{userSubscaleText[i]}</p>
                                <p className='result-item-stats mode-text-13 w-35'>{subCount}</p>
                                <p className='result-item-stats mode-text-13 w-35'>{testPercentiles[i]}</p>
                                <p className='result-item-stats mode-text-13 w-35'>1</p>
                            </div>
                        ))}
                        </div>
                        <div style={{marginTop: 0, border: 'none'}} className='result-table'>
                            <div className='table-result-items'>
                                <p className='result-item-title'> شاخص اتیسم:</p>
                                <p className='result-item-stats mode-text-13 w-35'> {testIndex}</p>
                                <p className='result-item-stats mode-text-13 w-35'> {score}</p>
                                <p className='result-item-stats mode-text-13 w-35'> </p>
                            </div>
                        </div>
                        <div className='result-header mode-text-13' >
                            <div className='result-modal-title d-flex'> راهنمای تفسیر: </div>
                        </div>
                        <table style={{marginTop: '5px'}} className='result-table'>
                            <tr className='table-result-items'>                   
                                <th className='result-item-title'>نمره خرده‌مقیاس</th>
                                <th className='result-item-title'>شاخص اتیسم</th>
                                <th className='result-item-title'>احتمال اتیسم</th>
                            </tr>
                            <tr className='table-result-items'>                   
                                <th className='result-item-title'>۷ یا بیشتر</th>
                                <th className='result-item-title'>۸۵ یا بیشتر</th>
                                <th className='result-item-title'>بسیار زیاد</th>
                            </tr>
                            <tr className='table-result-items'>                   
                                <th className='result-item-title'>۴ تا ۶</th>
                                <th className='result-item-title'>۷۰ تا ۸۴</th>
                                <th className='result-item-title'>تا حدودی</th>
                            </tr>
                            <tr className='table-result-items'>                   
                                <th className='result-item-title'>۱ تا ۳</th>
                                <th className='result-item-title'>۶۹ یا کمتر</th>
                                <th className='result-item-title'>احتمال ندارد</th>
                            </tr>
                        </table>
                    </div>
                    <div className='d-flex flex-column w-50'>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                width={500}
                                height={1000}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: -25,
                                    left: -25,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis angle={-90} interval={0} orientation='top' dataKey="label" padding={{ left: 30, right: 30 }} />
                                <YAxis yAxisId="left" tickCount={20} domain={[1, 20]} />
                                <YAxis yAxisId="right" tickCount={20} orientation="right" domain={[55, 150]}  />
                                <Line yAxisId="left" type="monotone" dataKey="subscale" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className='result-table'>
                    <div style={{marginTop: '10px', marginBottom: '10px'}} className='result-header mode-text-13'>
                        <div className='result-modal-title'> سوالات: </div>
                    </div>
                    {testQuestion.map((queCount, i) => (
                        <div className='table-result-items' key={i}>
                            <p className='result-item-stats'>{queCount}</p>
                            <p className='result-item-title font-12'>{userQuestion[i]}</p>
                            {testAnswer[i] === 0 ?
                            <p className='result-item-stats'>{"-"}</p>
                            :
                            <p className='result-item-stats'>{testAnswer[i]-1}</p>
                            }
                        </div>
                    ))}
                    </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint 
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج تست گارس ۲'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>
            </div>
            
        </div>
    )
}

export default ResultModalGars2
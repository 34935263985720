module.exports = [
    'مهروبو',
    'درباره ما',
    'ورود',
    'محصولات',
    'غربالگری اتیسم',
    'موسیقی درمانی',
    'کلینیک هوشمند',
    'En',
    'فا'
]
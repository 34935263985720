import React, { useState, useRef, useEffect } from 'react'
import './style.css'
import ReactToPrint from 'react-to-print'
import asq3Icon from '../../../../../assets/SarbargAsq3.png'

const faScoboQuestions = require('../../../../../utils/scoboTestQuestionsFa.js')
const faGars2Questions = require('../../../../../utils/gars2TestQuestionsFa.js')
const faGars3Questions = require('../../../../../utils/gars3TestQuestionsFa.js')
const faAsq3Questions = require('../../../../../utils/asq3TestQuestionsFa.js')
const faNumbers = require('../../../../../utils/numbersFa.js')

function ResultModalAsq3({ evaluation, closeModal, testStats, clinicName }) {

    const [testName, setTestName] = useState(evaluation.Session)
    const [testIndex, setTestIndex] = useState(evaluation.Index)
    const [testAnswer, setTestAnswer] = useState(evaluation.Answers)
    const [testQuestion, setTestQuestion] = useState(evaluation.Questions)
    const [testSubscales, setTestSubscales] = useState(evaluation.Subscales)
    const [testPercentiles, setTestPercentiles] = useState(evaluation.Percentiles)
    const [testStartDate, setTestStartDate] = useState(evaluation.StartDate)
    const [examinerName, setExaminerName] = useState(evaluation.Examiner)
    const [resultAds, setResultAds] = useState(evaluation.Result)
    const [score, setScore] = useState(evaluation.Score)
    const [name, setName] = useState(testStats.firstname)
    const [lName, setLName] = useState(testStats.lastname)
    const [birthResult, setBirthResult] = useState(testStats.birthdate)
    const [clinic, setClinic] = useState(clinicName)
    const [adsResults, setAdsResults] = useState([])
    const [age, setAge] = useState(0)
    const [ageIndex, setAgeIndex] = useState(0)
    const farsiNumbers = faNumbers



    const userSubscaleText = 
    [
        "مهارت ارتباطی",
        "مهارت‌های درشت",
        "مهارت‌های خرد",
        "مهارت حل مسئله",
        "مهارت اجتماعی",
    ]

    const componentRef = useRef(null);

    const resultIdentifier = () => {
        
        for (let i=0; i<testSubscales.length; i++) {
            if (testSubscales[i] > testPercentiles[1][i]) adsResults.push(["رشد طبیعی"])
            else if (testSubscales[i] > testPercentiles[0][i] && testSubscales[i] <= testPercentiles[1][i]) adsResults.push(["نیاز به یادگیری"])
            else if (testSubscales[i] <= testPercentiles[0][i]) adsResults.push(["نیاز به متخصص"])
        }

    }

    const ageCalculator = () => {
        var tavalod = new Date(birthResult)
        var date = new Date()
        var sen = Math.ceil( (date - tavalod) / (1000 * 60 * 60 * 24 * 30) );
        setAge(sen)
        if (sen < 3) setAgeIndex(0)
        else if (sen < 5) setAgeIndex(1)
        else if (sen < 7) setAgeIndex(2)
        else if (sen < 9) setAgeIndex(3)
        else if (sen < 10) setAgeIndex(4)
        else if (sen < 11) setAgeIndex(5)
        else if (sen < 13) setAgeIndex(6)
        else setAgeIndex(7)
    }

    useEffect (() => {
        console.log("received id in this page is:", evaluation);
        resultIdentifier()
        ageCalculator()
    }, [])

    var userQuestion = []
    if (testName == 'SCOBO') userQuestion = faScoboQuestions
    if (testName == 'GARS2') userQuestion = faGars2Questions
    if (testName == 'GARS3') userQuestion = faGars3Questions
    if (testName == 'ASQ3') userQuestion = faAsq3Questions[ageIndex]

        return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print' ref={componentRef}>
                    <div className='result-header mode-bg-4'>
                        <img style={{width:'100%'}} className='asq3-img' src={asq3Icon}></img>
                    </div>
                    <div className='result-identity mode-text-9'>
                        <div className='result-identity-right'>
                            <p>نام و نام خانوادگی: {name} {lName}</p>
                            <p> نام موسسه یا کلینیک: {clinic}</p>
                            <p> نام آزمونگر: {examinerName}</p>
                        </div>
                        <div className='result-identity-left'>
                            <p>تاریخ پر کردن ای‌اس‌کیو: {new Date(testStartDate).toLocaleDateString('fa-IR')}</p>
                            <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                            <p>سن: {farsiNumbers[age]} ماهه</p>
                        </div>
                    </div>
                    <div className='result-header mode-text-14' >
                        <div className='result-modal-title d-flex'>بخش ۱. نمودار تبدیل نمرات</div>
                    </div>
                    <div className='result-table' style={{borderStyle: 'none'}}>
                        <div className='table-result-items flex-column d-ltr' style={{height: 'auto'}}>
                            {testSubscales.map((subCount, i) => (
                            <div className='d-flex w-100'>
                                <div className='font-15' style={{display: 'flex', width: '20%', height: '30px', borderStyle: 'solid', borderWidth: '1px', marginBottom: '5px'}}>
                                    <p>{userSubscaleText[i]}</p>
                                </div>
                                <div style={{display: 'flex', position: 'relative', width: '80%', height: '30px', borderStyle: 'solid', borderWidth: '1px', marginBottom: '5px'}}>
                                    {subCount > testPercentiles[1][i] &&
                                    <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: '#8dc63f'}}></div>
                                    }
                                    {subCount > testPercentiles[0][i] && subCount <= testPercentiles[1][i] &&
                                    <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: '#e86e34'}}></div>
                                    }
                                    {subCount <= testPercentiles[0][i] &&
                                    <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: 'red'}}></div>
                                    }
                                    <div style={{position: 'absolute', top:'7px', left: '2%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '9.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '17.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '25%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '32.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '40.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '48%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '55.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '63.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '71%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '78.6%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '86.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                    <div style={{position: 'absolute', top:'7px', left: '94%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-14' >
                        <div className='result-modal-title d-flex'>بخش ۲. عملکرد مجموع</div>
                    </div>
                    <div className='result-table' style={{marginBottom: '0', marginTop: '0', borderStyle: 'none'}}>
                        <div className='table-result-items'>
                            <p className='result-item-title' ></p>
                            <p className='result-item-stats mode-text-14 w-35'>خرده مقیاس‌ها: </p>
                            <p className='result-item-stats mode-text-14 w-35'>نقطه بحرانی اول: </p>
                            <p className='result-item-stats mode-text-14 w-35'>نقطه بحرانی دوم: </p>
                            <p className='result-item-stats mode-text-14 w-35'>تفسیر: </p>
                        </div>
                    </div>
                    <div style={{marginTop: '5px'}} className='result-table'>
                    {testSubscales.map((subCount, i) => (
                        <div className='table-result-items' key={i}>
                            <p className='result-item-title' >{userSubscaleText[i]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{testSubscales[i]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{testPercentiles[0][i]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{testPercentiles[1][i]}</p>
                            <p className='result-item-stats mode-text-15 w-35'>{adsResults[i]}</p>
                        </div>
                    ))}
                    </div>
                    <div className='result-header mode-text-14' >
                        <div className='result-modal-title d-flex'>بخش ۳. اقدامات مورد نیاز</div>
                    </div>
                    <div className='result-table' style={{borderStyle: 'none'}}>
                        <div className='table-result-items'>
                            <p className='result-item-title'>{"-"}</p>
                        </div>
                        <div className='table-result-items'>
                            <p className='result-item-title'>{"-"}</p>
                        </div>
                    </div>
                    <div className='result-table'>
                        <div style={{marginTop: '10px', marginBottom: '10px'}} className='result-header mode-text-14'>
                            <div className='result-modal-title'> سوالات: </div>
                        </div>
                        {testQuestion.map((queCount, i) => (
                        <div className='table-result-items' >
                            <p className='result-item-stats'>{queCount}</p>
                            <p className='result-item-title font-12'>{userQuestion[i]}</p>
                            {testAnswer[i] === 0 ?
                            <p className='result-item-stats'>{"-"}</p>
                            :
                            <p className='result-item-stats'>{testAnswer[i]-1}</p>
                            }
                        </div>
                        ))}
                    </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint 
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج تست ای‌اس‌کیو ۳'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>

            </div>
            
        </div>
    )
}

export default ResultModalAsq3
import React, { useEffect, useState } from 'react'
import './index.css'
import axios from 'axios'
import LoadingPage from '../../loading-page'

const engText = require('../../../utils/musicTherapyEngTexts.js')
const faText = require('../../../utils/musicTherapyFaTexts.js')

function MusicTherapy(props) {
    const [showNameError, setShowNameError] = useState(false)
    const [showPhoneError, setShowPhoneError] = useState(false)   
    const [showPhoneNumberCountError, setShowPhoneNumberCountError] = useState(false)
    const [showAgeError, setShowAgeError] = useState(false)
    const [showPriceError, setShowPriceError] = useState(false)
    const [showMainError, setShowMainError] = useState(false)  
    const [nameError, setNameError] = useState(true)
    const [familyError, setFamilyError] = useState(true)
    const [phoneError, setPhoneError] = useState(true)  
    const [phoneNumberCountError, setPhoneNumberCountError] = useState(true)
    const [ageError, setAgeError] = useState(true)
    const [priceError, setPriceError] = useState(true)
    const [mainError, setMainError] = useState(true)     
    const [childName, setChildName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [childAge, setChildAge] = useState(0)
    const [mobileNumber, setMobileNumber] = useState('')
    const [musicTherapyText, setmusicTherapyText] = useState([])
    const [countryCode, setCountryCode] = useState(props.languageInput)
    const [childGender, setchildGender] = useState('boy')
    const [priceAmount, setPriceAmount] = useState('')
    // const [sendMessage, setSendMessage] = useState("Hello,I'mInMusicoboPage")

    useEffect(() => {
    // handleRedirect();
    const lang = localStorage.getItem('lang')
    if(lang == null) {
        if(countryCode =='IR'){
            setmusicTherapyText(faText)
        } else {
            setmusicTherapyText(engText)
        }
    }
    else if(lang =='IR'){
        setCountryCode('IR')
        setmusicTherapyText(faText)

    } else {
        setCountryCode('US')
        setmusicTherapyText(engText)
    }
}, [])

    useEffect(() => {
    if (props.languageInput != countryCode) {
        setCountryCode(props.languageInput)
        if(props.languageInput=='IR'){
            setmusicTherapyText(faText)
            setCountryCode('IR')
        } else {
            setmusicTherapyText(engText)
            setCountryCode('US')
       }
    }
    })

    // const handleRedirect = async() => {
    //     const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: sendMessage})
    //     return true
    // }

    const getLocation = async() => {
        const locationProps = await axios.get('https://ip.nf/me.json')
        setCountryCode(locationProps.data.ip.country_code)
    }

    const childNameInput = (event) => {
        if (event.target.value == "")
            setNameError(true)
        else
            setNameError(false)
        if (event.target.value != "" && !phoneError && !ageError && !priceError && !familyError)
            setMainError(false)
        else
            setMainError(true)
        setChildName(event.target.value)
    } 

    const childFamilyInput = (event) => {
        setFamilyName(event.target.value)
    } 

    const mobileNumberInput = (event) => {
        if (event.target.value == "")
            setPhoneError(true)
        else
            setPhoneError(false)
        if (!nameError && !familyError && event.target.value != "" && 9 < event.target.value.length < 13 && !ageError && !priceError)
            setMainError(false)
        else
            setMainError(true)
        if(mobileNumber.length != 0 && mobileNumber.length<9 || mobileNumber.length>13)
            setPhoneNumberCountError(true)
        else 
            setPhoneNumberCountError(false)
        setMobileNumber(event.target.value)
    }

    const childAgeInput = (event) => {
        if (event.target.value == 0)
            setAgeError(true)
        else
            setAgeError(false)
        if (!priceError && 9 < mobileNumber.length < 13 && !nameError && !phoneError && event.target.value != "")
            setMainError(false)
        else
            setMainError(true)
        setChildAge(event.target.value)
    }

    const childGenderCheck = (event) => {
        setchildGender(event.target.value)
    }

    const priceInput = (event) => {
        if (event.target.value == 0)
            setPriceError(true)
        else
            setPriceError(false)

    if (!nameError && 9 < mobileNumber.length < 13 && !phoneError && !ageError && event.target.value != "")
            setMainError(false)
        else
            setMainError(true)
        setPriceAmount(event.target.value)
    }
    

    const submitHandler = async(event) => {
        event.preventDefault()
        if(childName != "") 
            setShowNameError(false)
        else 
            setShowNameError(true)
        if(mobileNumber != "")
            setShowPhoneError(false)
        else    
            setShowPhoneError(true)
        if(mobileNumber.length != 0 && mobileNumber.length<9 || mobileNumber.length>13)
            setShowPhoneNumberCountError(true)
        else 
            setShowPhoneNumberCountError(false)
        if(childAge != 0) 
            setShowAgeError(false)
        else 
            setShowAgeError(true)
        if(priceAmount != 0) 
            setShowPriceError(false)
        else 
            setShowPriceError(true)
        if(childAge !=0 && mobileNumber !='' && 9 < mobileNumber.length < 13 && childName !='' && familyName !='' && priceAmount !='') {
            setShowMainError(false)
            const receipt = await axios.post('https://request.sweetobot.com/receipts/request', 
            {
                firstName: childName, 
                lastName: familyName, 
                phoneNumber: mobileNumber,
                amount: priceAmount,
                websitetoken: "Not signed in, counseling page!",
                bookingtoken: "Mehrobo website, not needed",
                status: "going to the bank"
            })
            if (priceAmount == 1)
                window.location.href = `https://www.sweetobot.com/bank-redirection?status=2&FN=${childName}&LN=${familyName}&PN=${mobileNumber}&WT=None`
            if (priceAmount == 2)
                window.location.href = `https://www.sweetobot.com/bank-redirection?status=3&FN=${childName}&LN=${familyName}&PN=${mobileNumber}&WT=None`
        }
        else
            setShowMainError(true)
    }

    const submitHandlerPaypal = (event) => {
        event.preventDefault()
        if(childName != "") 
            setShowNameError(false)
        else
            setShowNameError(true)
        if(mobileNumber != "") 
            setShowPhoneError(false)
        else
            setShowPhoneError(true)   
        if(mobileNumber.length != 0 && mobileNumber.length<9 || mobileNumber.length>13)
            setShowPhoneNumberCountError(true)
        else 
            setShowPhoneNumberCountError(false)           
        if(childAge != 0) 
            setShowAgeError(false)
        else
            setShowAgeError(true)
    }

    if (countryCode==''){
        return (
            <LoadingPage />
        )
      }

    return (
        <div className='music-therapy-container'>
            <div className='music-therapy-page'>
                <div className='left-music-therapy'>
                    <h1>{musicTherapyText[0]}</h1>
                    <p>{musicTherapyText[1]}</p>
                </div>
                <div className='right-music-therapy'>
                    <div className='form'>
                        <div className='name-familyname'>
                            <input type='text' placeholder={musicTherapyText[2]} onChange={(event) => childNameInput(event) }
                              style={{marginLeft:'5px', marginRight:'5px'}}  className='input-box-childName'>
                            </input>
                            <input type='text' placeholder={musicTherapyText[24]} onChange={(event) => childFamilyInput(event)} 
                              style={{marginRight:'5px', marginLeft:'5px'}}  className='input-box-childName'>
                            </input>
                        </div>      
                        <div className='error-text'>{showNameError ? <p>{musicTherapyText[18]}</p>: null}</div>          
                        <input type='number' placeholder={musicTherapyText[3]} onChange={(event) => mobileNumberInput(event)}
                            className='input-box-mobileNumber'>
                        </input>
                        <div className='error-text'>
                            {showPhoneError ? <p>{musicTherapyText[19]}</p>: null}
                            {showPhoneNumberCountError ? <p>{musicTherapyText[31]}</p>: null}
                        </div>
                        <select type='dropdown' onChange={(event) => childAgeInput(event)} className='drop-down'>
                            <option value={0}>
                                {musicTherapyText[4]}
                            </option>
                            <option value={1}>
                                {musicTherapyText[5]}
                            </option>
                            <option value={2}>
                                {musicTherapyText[6]}
                            </option>
                            <option value={3}>
                                {musicTherapyText[7]}
                            </option>
                            <option value={4}>
                                {musicTherapyText[8]}
                            </option>
                        </select>
                        <div className='error-text'>{showAgeError ? <p>{musicTherapyText[20]}</p>: null}</div>
                        <div className='radiobox-section'>
                            <div className='gender-radiobox'>
                                <p>{musicTherapyText[9]}</p>
                                <div className='radio-buttons-box'>
                                    <div className='radio-button-1'>
                                        <input type='radio' checked={childGender === "girl"} value= 'girl' onChange={(event) => childGenderCheck(event)}/>
                                        <label>{musicTherapyText[10]}</label>
                                    </div>
                                    <div className='radio-button-2'>
                                        <input type='radio' checked={childGender === "boy"} value= 'boy' onChange={(event) => childGenderCheck(event)}/>
                                        <label>{musicTherapyText[11]}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='field-container'>
                            {countryCode == "IR" &&
                                <form className='price-field' onSubmit={(event) => submitHandler(event)}>
                                    <select type='dropdown' onChange={(event) => priceInput(event)} className='price-field-dropdown'>
                                        <option value={0} >
                                            {musicTherapyText[26]}
                                        </option>
                                        <option value={1} >
                                            {musicTherapyText[16]}
                                        </option>
                                        <option value={2} >
                                            {musicTherapyText[23]}
                                        </option>
                                    </select>
                                    <div className='error-text'>{showPriceError ? <p>{musicTherapyText[27]}</p>: null}</div>
                                    <div className='price-field-amount'>
                                        {priceAmount == 0 && <p>{musicTherapyText[30]}</p>}
                                        {priceAmount == 1 && <p>{musicTherapyText[28]}</p>}
                                        {priceAmount == 2 && <p>{musicTherapyText[29]}</p>}
                                    <div className='price-field-discount'>
                                        <input type='text' placeholder={musicTherapyText[22]}></input>
                                        <button type='button'></button>
                                    </div >
                                    </div>
                                <button type='submit' className='control-button'> {musicTherapyText[17]} </button>
                                </form>
                                }
                                {countryCode != "IR" && mainError != true &&
                                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                    <input type="hidden" name="cmd" value="_s-xclick"></input>
                                    <input type="hidden" name="hosted_button_id" value="Y6FSAST43FUVW"></input>
                                    <table>
                                    <tbody><tr><td><input type="hidden" name="on0" value="Buy Coffee for Us!"></input></td></tr></tbody>
                                    <tbody><tr><td><select name="os0">
                                        <option value="1 Cup">1 Cup $5,00 USD</option>
                                        <option value="2 Cups">2 Cups $10,00 USD</option>
                                        <option value="3 Cups">3 Cups $15,00 USD</option>
                                        <option value="4 Cups">4 Cups $20,00 USD</option>
                                        <option value="5 Cups">5 Cups $25,00 USD</option>
                                        <option value="10 Cups">10 Cups $50,00 USD</option>
                                    </select></td></tr></tbody>
                                    </table>
                                    <input type="hidden" name="currency_code" value="USD"></input>
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"></input>
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"></img>
                                </form>
                                }
                                {countryCode != "IR" && mainError != false &&
                                <form onSubmit={(event) => submitHandlerPaypal(event)}>
                                    <input type="hidden" name="cmd" value="_s-xclick"></input>
                                    <input type="hidden" name="hosted_button_id" value="Y6FSAST43FUVW"></input>
                                    <table>
                                    <tbody><tr><td><input type="hidden" name="on0" value="Buy Coffee for Us!"></input></td></tr></tbody>
                                    <tbody><tr><td><select name="os0">
                                        <option value="1 Cup">1 Cup $5,00 USD</option>
                                        <option value="2 Cups">2 Cups $10,00 USD</option>
                                        <option value="3 Cups">3 Cups $15,00 USD</option>
                                        <option value="4 Cups">4 Cups $20,00 USD</option>
                                        <option value="5 Cups">5 Cups $25,00 USD</option>
                                        <option value="10 Cups">10 Cups $50,00 USD</option>
                                    </select></td></tr></tbody>
                                    </table>
                                    <input type="hidden" name="currency_code" value="USD"></input>
                                    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"></input>
                                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"></img>
                                </form>
                                }
                            </div>
                            <div className='error-text-main'>{showMainError ? <p>{musicTherapyText[21]}</p>: null}</div>
                        </div>        
                </div>
            </div>
        </div>
    )
}

export default MusicTherapy
module.exports = [
    'اکنون ۵ دقیقه زمان بگذارید برای یک عمر آسودگی خاطر',
    "کافی است امروز هزینه‌ای را که می‌خواهید صرف خریدی غیرضروری کنید را، برای پر کردن دقیق‌ترین تست غربالگری اتیسم در دنیا بپردازید و از رشد کودک خود مطمئن شوید و یک مشاوره‌ی رایگان هم از ما هدیه بگیرید.",
    "نام کودک",
    "شماره موبایل",
    "بازه‌ی سنی کودک خود را مشخص کنید",
    "۱۸ ماه تا ۲ سال",
    "۲ سال تا ۳ سال",
    "۳ سال تا ۴ سال",
    "بالای ۴ سال",
    "جنسیت کودک خود را انتخاب کنید",
    "دختر",
    "پسر",
    "آیا سابقه بیماری اتیسم در خانواده وجود دارد؟",
    "بله",
    "خیر",
    "برای کودک خود قبلا تشخیص اتیسم گرفته ام.",
    "۳۰ هزار  تومان",
    "پرداخت و شروع تست",
    "نام کودک خود را وارد کنید",
    "شماره موبایل خود را وارد کنید",
    "سن کودک خود را وارد کنید",
    "لطفا تمام قسمت‌ها را پر کنید",
    "کد تخفیف",
    "نام خانوادگی کودک"

]
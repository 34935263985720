import React from 'react'
import './style.css'
import axios from 'axios'
import Modal from './modal-evaluations'
import AccessModal from './access-modal'

class List extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            clinicUsers: null,
            sendMessage: "Hello,I'mInAsq3ListPage",
            evaluation: null,
            currentDateTime: null,
            openModal: false,
            errorModal: false,
            modalClinicUser: '',
            modalUserId: '',
            videocallSession: false,
            sessName: 0,
            currentUser: 0,
            products: null,
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        console.log("Current Date is:", dateTime);
        console.log("Current Date is:", today.getTime());
        console.log("Current Date is:", randDate < today);
    }

    componentDidMount = async () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const receivedTest = params.get('test');
        this.setState({sessName: receivedTest});

        const userResult = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        localStorage.setItem('currentUser', userResult.data.user.access)
        if (userResult.data.message == "Yo, You are authenticated!") {
            // this.setState({isLogin: true});
            this.setState({products: userResult.data.user.products});
            console.log(userResult.data.user.products);
            if (receivedTest == 'SCOBO') {
                if (userResult.data.user.products.Scobo < 1) window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
            } else if (receivedTest == 'GARS2') {
                if (userResult.data.user.products.Gars2 < 1) window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
            } else if (receivedTest == 'GARS3') {
                if (userResult.data.user.products.Gars3 < 1) window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
            } else if (receivedTest == 'ASQ3') {
                if (userResult.data.user.products.Asq3 < 1) window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
            } else if (receivedTest == null) {
                window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
            }

        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
        this.handleRedirect();

        const result = await axios.post('https://request.sweetobot.com/evaluation/list', {testName: receivedTest}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Received Data:", result.data);
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({clinicUsers: result.data.users})
            this.setState({isLogin: true});
            this.getCurrentDateAndTime()
        } else {
            this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }
    
    buttonStartSession = async (i, audId) => {
        const response = await axios.post('https://request.sweetobot.com/evaluation/start', {audienceId: audId}, {headers: {'x-access-token': localStorage.getItem('token')}})
        if(response.data.status ==1 && this.state.sessName == 'SCOBO')
            window.location.href = '/fa/panel/evaluations/scobo'
        if(response.data.status ==1 && this.state.sessName == 'GARS2')
            window.location.href = '/fa/panel/evaluations/gars2'
        if(response.data.status ==1 && this.state.sessName == 'GARS3')
            window.location.href = '/fa/panel/evaluations/gars3'
        if(response.data.status ==1 && this.state.sessName == 'ASQ3')
            window.location.href = '/fa/panel/evaluations/asq3'
    }

    buttonCreateSession = async (i, audId) => {
        const result = await axios.post('https://request.sweetobot.com/evaluation/create', {audienceId: audId, sessionName: this.state.sessName}, {headers: {'x-access-token': localStorage.getItem('token')}})

        const { clinicUsers } = this.state;
        clinicUsers[i].activesession = !this.state.clinicUsers[i].activesession
        this.setState({ clinicUsers })
        console.log("clinic user is: ", clinicUsers);
    }

    modalHandler = (modal) => {
        this.setState({openModal: modal})
    }

    accessHandler = (modal) => {
        this.setState({errorModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    clientIdHandler = (i, clinicUser) => {
        this.setState({openModal: true})
        this.setState({modalUserId: i})
        this.setState({modalClinicUser: clinicUser})
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>لیست کاربران کلینیک</span>
                                </p>
                            </div>
                        </div>
                        <div className='test-list mt-1'  style={{display:'flex' , justifyContent:'center' , alignContent:'center'}}>
                        {this.state.openModal && <Modal
                            closeModal={(modalBool) => this.modalHandler(modalBool)} 
                            startSession={(sessionBool) => this.sessionHandler(sessionBool)}
                            testName={this.state.sessName}
                            selectedUser={this.state.clinicUsers[this.state.modalUserId]}
                        />}
                        {this.state.errorModal && <AccessModal
                            closeModal={(modalBool) => this.accessHandler(modalBool)} 
                        />}
                            <div className='panel mode-bg-8 mb-4'>
                                <div className='d-flex flex-column w-100'>
                                    <div className='test-title-wrapper d-flex justify-content-between align-items-center pr-4 pl-4'>
                                        <p className='font-14 mode-text-4'>
                                            لیست تست‌های انجام شده
                                        </p>
                                    </div>
                                    <div className='test-table-wrapper'>
                                        <div className='test-table'>
                                            <div className='table-head'>
                                                <div className='test-table-header d-flex align-items-center mode-bg-5 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15 '>ردیف</p>
                                                    {/* <p className='mode-text-9 w-15'>تاریخ</p> */}
                                                    <p className='mode-text-9 w-35 '>مراجع</p>
                                                    {/* <p className='mode-text-9 w-15'>موسسه</p> */}
                                                    {/* <p className='mode-text-9 w-15 '>جلسه</p> */}
                                                </div>
                                            </div>
                                            {this.state.clinicUsers.map((clinicUser, i) => (
                                            <div className='table-row' key={i}>
                                                <a className='test-table-row w-100 d-flex align-items-center mode-bg-9 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>{i+1}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.firstname} {clinicUser.lastname}</p>
                                                </a>
                                                {clinicUser.activesession ?
                                                <a className='btn-default mode-bg-3' onClick={() => this.buttonStartSession(i, clinicUser.id)}>
                                                    <div>ورود به تست</div>
                                                </a>
                                                :
                                                <a className='btn-default mode-bg-2' onClick={() => this.clientIdHandler(i, clinicUser.id)}>
                                                    <div>ایجاد تست</div>
                                                </a>
                                                }
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default List
import React from 'react'
import './index.css'
import axios from 'axios'
import Modal from './modal-vidobo'

class Videocall extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            clinicUsers: null,
            sendMessage: "Hello,I'mInVideocallPage",
            evaluation: null,
            currentDateTime: null,
            openModal: false,
            modalClinicUser: '',
            modalUserId: '',
            videocallSession: false,
            sessName: ''
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        console.log("Current Date is:", dateTime);
        console.log("Current Date is:", today.getTime());
        console.log("Current Date is:", randDate < today);
    }

    componentDidMount = async () => {
        const userResult = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        if (userResult.data.message == "Yo, You are authenticated!") {
            // this.setState({isLogin: true});
            // this.setState({firstName: userResult.data.firstname})
            // this.setState({lastName: userResult.data.lastname})
            console.log(userResult.data)
        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/login'
        }
        this.handleRedirect();

        const result = await axios.post('https://request.sweetobot.com/videocall/list', {some: 'some'}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Received Data:", result.data);
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({clinicUsers: result.data.users})
            this.setState({isLogin: true});
            this.getCurrentDateAndTime()
        } else {
            this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/login'
        }
        
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }
    
    buttonStartSession = (audId) => {
        window.location.href = '/fa/panel/sessions/session'
    }

    buttonCreateSession = async (i, audId) => {
        console.log("Start the session!", audId);
        const result = await axios.post('https://request.sweetobot.com/videocall/create', {audienceId: audId, sessionName: this.state.sessName}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Start requested the session!", audId);

        const { clinicUsers } = this.state;
        clinicUsers[i].activesession = !this.state.clinicUsers[i].activesession
        this.setState({ clinicUsers })
    }

    modalHandler = (modal) => {
        this.setState({openModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    nameServerHandler = (nameServer) => {
        this.setState({sessName: nameServer})
        console.log("user name is:", nameServer)
    }

    clientIdHandler = (i, clinicUser) => {
        this.setState({openModal: true})
        this.setState({modalUserId: i})
        this.setState({modalClinicUser: clinicUser})
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>لیست کاربران کلینیک</span>
                                </p>
                            </div>
                        </div>
                        <div className='test-list mt-1'  style={{display:'flex' , justifyContent:'center' , alignContent:'center'}}>
                        {this.state.openModal && <Modal
                            closeModal={(modalBool) => this.modalHandler(modalBool)} 
                            startSession={(sessionBool) => this.sessionHandler(sessionBool)} 
                            nameChildServer={(nameString) => this.nameServerHandler(nameString)}
                        />}
                            <div className='panel mode-bg-8 mb-4'>
                                <div className='d-flex flex-column w-100'>
                                    <div className='test-title-wrapper d-flex justify-content-between align-items-center pr-4 pl-4'>
                                        <p className='font-14 mode-text-4'>
                                            لیست تست‌های انجام شده
                                        </p>
                                    </div>
                                    <div className='test-table-wrapper'>
                                        <div className='test-table'>
                                            <div className='table-head'>
                                                <div className='test-table-header d-flex align-items-center justify-content-between mode-bg-5 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>ردیف</p>
                                                    {/* <p className='mode-text-9 w-15'>تاریخ</p> */}
                                                    <p className='mode-text-9 w-35'>کاربر</p>
                                                    {/* <p className='mode-text-9 w-15'>موسسه</p> */}
                                                    {/* <p className='mode-text-9 w-15'>جلسه</p> */}
                                                </div>
                                            </div>
                                            {this.state.clinicUsers.map((clinicUser, i) => (
                                            <div className='table-row' key={i}>
                                                <div className='test-table-row w-100 d-flex align-items-center mode-bg-9 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>{i+1}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.firstname} {clinicUser.lastname}</p>
                                                </div>
                                                {clinicUser.activesession ?
                                                <a className='btn-default mode-bg-3' onClick={() => this.buttonStartSession(clinicUser.id)}>
                                                    <div>ورود جلسه</div>
                                                </a>
                                                :
                                                <a className='btn-default mode-bg-2' onClick={() => this.clientIdHandler(i, clinicUser.id)}>
                                                    <div>ایجاد جلسه</div>
                                                </a>
                                                }
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Videocall
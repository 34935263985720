module.exports = [
    "If your child is Below 3 and still doesn't speak, you are in the right place!",
    "Tracking children developmental milstones is one of most important thing for parents to do. A child of 3 years old must have eye contact, speak some words, comunicate with other children, etc. If yor child is above 2 years old and still doesn't have these abilities, click the button below.",
    'start test',
    '97%',
    'screening accuracy',
    "in young age",
    '810',
    'people have taken ',
    'this test',
    'mehrobo partners',
]

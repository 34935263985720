import React from 'react'
import './style.css'
import axios from 'axios'
import SettingModal from './setting-modal'
import DownloadModal from './download-modal'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FactCheckOutlined from '@mui/icons-material/FactCheckOutlined'
import { AiOutlineDownload, AiOutlineFilePdf, AiOutlineApple } from 'react-icons/ai'
import VideogameAssetOutlinedIcon from '@mui/icons-material/VideogameAssetOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import GameplayModal from './gameplay-modal'

class Musicobo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            accessType: "",
            clinicUsers: null,
            clinicName: null,
            downloadModal: false,
            gameplayModal: false,
            settingModal: false,
            evaluation: null,
            musicoboTotalSize: 0,
            musicoboDownloadSize: 0,
            isLogin: false,
            sendMessage: "Hello,I'mInMusicoboPage",
            currentDateTime: null,
            errorModal: false,
            modalClinicUser: '',
            selectedUser: null,
            videocallSession: false,
            sessName: 0,
            currentUser: 0
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;
    }

    componentDidMount = async () => {
        const userResult = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        localStorage.setItem('currentUser', userResult.data.access)
        if (userResult.data.message == "Yo, You are authenticated!") {
            // this.setState({isLogin: true});
            // this.setState({firstName: userResult.data.firstname})
            // this.setState({lastName: userResult.data.lastname})
            this.setState({accessType: userResult.data.access})
            this.setState({clinicName: userResult.data.clinic})
            console.log("Received user data is:", userResult.data)
        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
        this.handleRedirect();

        const result = await axios.post('https://request.sweetobot.com/musicobo/list', {some: 'some'}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Received musicobo users:", result.data);
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({isLogin: true});
            this.setState({clinicUsers: result.data.users})
            this.getCurrentDateAndTime()
        } else {
            this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    modalHandler = (modal) => {
        this.setState({settingModal: modal})
        this.setState({gameplayModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    accessHandler = (modal) => {
        this.setState({downloadModal: modal})
    }

    sessionHandler = (session) => {
        if (session == true) {
            this.buttonCreateSession(this.state.modalUserId, this.state.modalClinicUser)
        }
    }

    testNameServerHandler = (nameServer) => {
        this.setState({sessName: nameServer})
        console.log("session name is:", nameServer)
    }

    buttonDownloadGame = async() => {
        fetch('https://request.sweetobot.com/musicobo/download', {method: 'get',headers: {'x-access-token': localStorage.getItem('token')}})
            .then(async(response) => {
                // response.blob()
                // .then(blob => download(blob, 'musicobo.apk'))

                // const download = (blob, filename) => {
                //     const url = window.URL.createObjectURL(blob);
                //     const a = document.createElement('a');
                //     a.style.display = 'none';
                //     a.href = url;
                //     // the filename you want
                //     a.download = filename;
                //     document.body.appendChild(a);
                //     a.click();
                //     document.body.removeChild(a);
                //     window.URL.revokeObjectURL(url);
                // }
                
                const download = (blob, filename) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }
                
                const reader = response.body.getReader();
                const resHeaders = [...response.headers]
                console.log(resHeaders[0][1]);
                const totalSize = parseInt(resHeaders[0][1])
                this.setState({musicoboTotalSize: totalSize})
                this.setState({downloadModal: true})
                var bytesReceived = 0
                var buffer = []
                while (true) {
                    const {value, done} = await reader.read();
                    if (done) {
                        const blob = new Blob(buffer)
                        download(blob, 'musicobo.apk')
                        break;
                    }
                    // console.log('Received', value.length / 1024, "Kb", value.length, value);
                    bytesReceived = bytesReceived + value.length
                    this.setState({musicoboDownloadSize: bytesReceived})
                    buffer.push(value)
                }

                console.log('Response fully received:', bytesReceived / (1024*1024), "Mb");
            })
    }

    buttonDownloadGuide = async() => {
        fetch('https://request.sweetobot.com/musicobo/document', {method: 'get',headers: {'x-access-token': localStorage.getItem('token')}})
            .then(async(response) => {
                
                const download = (blob, filename) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    // the filename you want
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }
                
                const reader = response.body.getReader();
                const resHeaders = [...response.headers]
                console.log(resHeaders[0][1]);
                const totalSize = parseInt(resHeaders[0][1])
                this.setState({musicoboTotalSize: totalSize})
                this.setState({downloadModal: true})
                var bytesReceived = 0
                var buffer = []
                while (true) {
                    const {value, done} = await reader.read();
                    if (done) {
                        const blob = new Blob(buffer)
                        download(blob, 'musicobo-document.pdf')
                        break;
                    }
                    // console.log('Received', value.length / 1024, "Kb", value.length, value);
                    bytesReceived = bytesReceived + value.length
                    this.setState({musicoboDownloadSize: bytesReceived})
                    buffer.push(value)
                }

                console.log('Response fully received:', bytesReceived / (1024*1024), "Mb");
            })
    }

    buttonGameplay = (audId) => {
        this.setState({selectedUser: audId}) // I think selectedUser is better name for this variable
        this.setState({gameplayModal: true})
        console.log("Gameplay button clicked for user", audId);
    }

    buttonSetting = (audId) => {
        this.setState({selectedUser: audId}) // I think selectedUser is better name for this variable
        this.setState({settingModal: true})
        console.log("Setting button clicked for user", audId);
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>لیست کاربران</span>
                                </p>
                            </div>
                        </div>
                        <div className='test-list mt-1 d-flex flex-column'>
                            {this.state.settingModal && 
                            <SettingModal
                                closeModal={(modalBool) => this.modalHandler(modalBool)}
                                musicoboData={this.state.clinicUsers[this.state.selectedUser].musicobo}
                                audienceId={this.state.clinicUsers[this.state.selectedUser].id}
                            />}
                            {this.state.gameplayModal && 
                            <GameplayModal
                                closeModal={(modalBool) => this.modalHandler(modalBool)}
                                selectedUser={this.state.clinicUsers[this.state.selectedUser]}
                                audienceId={this.state.clinicUsers[this.state.selectedUser].id}
                                clinicName={this.state.clinicName}
                            />}
                            {this.state.downloadModal && 
                            <DownloadModal
                                closeModal={(modalBool) => this.accessHandler(modalBool)}
                                totalSize={this.state.musicoboTotalSize}
                                downloadSize={this.state.musicoboDownloadSize}
                            />}
                            <div className='boxes my-4 mb-4'>
                                <a className='box d-flex flex-column align-items-center justify-content-center' onClick={() => this.buttonDownloadGame()}>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center'>
                                        <span style={{display: 'flex', color: '#3ecc97'}}><AiOutlineDownload></AiOutlineDownload></span>
                                    </div>
                                    <span className='font-14 mt-4 mode-text-7'>دانلود بازی</span>
                                </a>
                                <a className='box d-flex flex-column align-items-center justify-content-center' onClick={() => this.buttonDownloadGuide()}>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{backgroundColor: '#ffe7e6'}}>
                                        <span style={{display: 'flex', color: '#f40f02'}}><AiOutlineFilePdf></AiOutlineFilePdf></span>
                                    </div>
                                    <span className='font-14 mt-4 mode-text-7'>دانلود راهنما</span>
                                </a>
                                <a href='/fa/panel/games/musicobo/online' className='box d-flex flex-column align-items-center justify-content-center'>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{backgroundColor: '#c2cccf'}}>
                                        <span style={{display: 'flex', color: '#555'}}><AiOutlineApple></AiOutlineApple></span>
                                    </div>
                                    <span className='font-14 mt-4 mode-text-7'>موزیکوبو آنلاین</span>
                                </a>
                            </div>
                            <div className='panel mode-bg-8 mb-4'>
                                <div className='d-flex flex-column w-100'>
                                    <div className='test-title-wrapper d-flex justify-content-between align-items-center pr-4 pl-4'>
                                        <p className='font-14 mode-text-4'>
                                            لیست کاربران
                                        </p>
                                    </div>
                                    <div className='test-table-wrapper'>
                                        <div className='test-table'>
                                            <div className='table-head'>
                                                <div className='test-table-header d-flex align-items-center mode-bg-5 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>ردیف</p>
                                                    <p className='mode-text-9 w-35'>نام کاربر</p>
                                                    <p className='mode-text-9 w-35'>نوع کاربر</p>
                                                    <p className='mode-text-9 w-35 d-flex justify-content-end pr-4'>عملیات‌ها</p>
                                                </div>
                                            </div>
                                            {this.state.clinicUsers.map((clinicUser, i) => (
                                            <div className='table-row' key={i}>
                                                <a className='test-table-row w-100 d-flex align-items-center mode-bg-9 pr-4 pl-4'>
                                                    <p className='mode-text-9 w-15'>{i+1}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.firstname} {clinicUser.lastname}</p>
                                                    <p className='mode-text-9 w-35'>{clinicUser.access == 'Therapist' ? 'درمانگر' : 'مراجع'}</p>
                                                    <div className='w-50 d-flex justify-content-end'>
                                                        {this.state.accessType !== "User" &&
                                                        <div className="users-control" style={{backgroundColor: '#006552'}} onClick={() => this.buttonGameplay(i)}>
                                                            <QueryStatsOutlinedIcon/>
                                                        </div>
                                                        }
                                                        {this.state.accessType !== "User" &&
                                                        <div className="users-control" style={{backgroundColor: '#3b38c1'}} onClick={() => this.buttonSetting(i)}>
                                                            <VideogameAssetOutlinedIcon/>
                                                        </div>
                                                        }
                                                    </div>
                                                </a>
                                            </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Musicobo
import React from 'react'
import './style.css'
import axios from 'axios'

class Binet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            firstName: "",
            lastName: "",
            sendMessage: "Hello,I'mInPanelPage"
        }
    }

    render() {
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2 d-inline-block'>برای شما هنوز تستی ثبت نشده ...</span>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Binet
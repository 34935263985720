import React, {useState, useEffect} from 'react'
import './style.css'
import dataSlider from './dataSlider.js'
import { IoIosArrowBack } from 'react-icons/io'

// import sliderTestHp from '../../../../assets/hp-test-image.png'
// import sliderClinicHp from '../../../../assets/hp-clinic-image.png'
// import sliderMusicoboHp from '../../../../assets/hp-musicobo-image.png'

import sliderClinicHp from '../../../../assets/CarouselImage01-HP.png'
import sliderTestHp from '../../../../assets/CarouselImage02-HP.png'
import sliderMusicoboHp from '../../../../assets/CarouselImage03-HP.png'

function SliderHP() {

    const [slideIndex, setSlideIndex] = useState(0)
    const sliderImage = [sliderClinicHp, sliderTestHp, sliderMusicoboHp]
    const sliderHeader = ["کلینیکتو هوشمند کن!", "دقیق‌ترین غربالگری دنیا!", "بازی موسیقی کودک"]

    useEffect(() => {
        var slidesCounter = 1
        const interval = setInterval(() => {
            setSlideIndex(slidesCounter)
            if (slidesCounter > 1) {
                slidesCounter = 0
            } else {
                slidesCounter = slidesCounter + 1
            }
        }, 4000);
        return () => clearInterval(interval);
      }, []);

    return (

        <div className="container-slider">
            {/* {dataSlider.map((slider, i) => {
                return (
                    <div
                    key={slider.id}
                    className={slideIndex === i ? "slide active-anim" : "slide"}
                    >
                        <div className='slide-content'>
                            {slideIndex === 0 && <h1>{"کلینیکتو هوشمند کن!"}</h1>}
                            {slideIndex === 1 && <h1>{"دقیق‌ترین غربالگری دنیا!"}</h1>}
                            {slideIndex === 2 && <h1>{"بازی موسیقی کودک"}</h1>}
                            {slideIndex === 0 && <p>{"تنها با یه اپ، با همه‌ی مراجعینت در ارتباط باش و ارزیابی کن"}</p>}
                            {slideIndex === 1 && <p>{"اگر کودکت بین ۱۸ ماه تا ۳ سالشه، حتما این تست رو از دست نده"}</p>}
                            {slideIndex === 2 && <p>{"باورت میشه با یه بازی، هم بازی کنی و هم موسیقی یاد بگیری"}</p>}
                            {slideIndex === 0 && <a href={'/fa/products/clinic-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                            {slideIndex === 1 && <a href={'/fa/products/screening-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                            {slideIndex === 2 && <a href={'/fa/products/musicobo-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                        </div>
                        <img src={sliderImage[i]}/>
                    </div>
                )
            })}

            <div className="container-dots">
                {Array.from({length: 3}).map((item, i) => (
                    <div 
                    onClick={() => setSlideIndex(i)}
                    className={slideIndex === i ? "dot active" : "dot"}
                    ></div>
                ))}
            </div> */}
            <div className='slide'>
                <div className='slide-content'>
                    <h1>{sliderHeader[slideIndex]}</h1>
                    {slideIndex === 0 && <p>{"تنها با یه اپ، با همه‌ی مراجعینت در ارتباط باش و ارزیابی کن"}</p>}
                    {slideIndex === 1 && <p>{"اگر کودکت بین ۱۸ ماه تا ۳ سالشه، حتما این تست رو از دست نده"}</p>}
                    {slideIndex === 2 && <p>{"باورت میشه یه بازی موبایلی، بهت کمک کنه موسیقی یاد بگیری"}</p>}
                    {slideIndex === 0 && <a href={'/fa/products/clinic-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                    {slideIndex === 1 && <a href={'/fa/products/screening-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                    {slideIndex === 2 && <a href={'/fa/products/musicobo-ticket'}>اطلاعات بیشتر<IoIosArrowBack/></a>}
                </div>
                <div className='slide-image'>
                    <img src={sliderImage[slideIndex]}></img>
                </div>
            </div>
            <div className='container-dots'>
                <div onClick={() => setSlideIndex(0)} className={slideIndex === 0 ? "dot active" : "dot"}></div>
                <div onClick={() => setSlideIndex(1)} className={slideIndex === 1 ? "dot active" : "dot"}></div>
                <div onClick={() => setSlideIndex(2)} className={slideIndex === 2 ? "dot active" : "dot"}></div>
            </div>
        </div>
  );
}

export default SliderHP

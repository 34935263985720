module.exports = [
    'آیا کودک از توانایی گفتار در حد ساختن جملات و انجام گفتگو برخوردار است؟',
    'اگر به چیز جالبی اشاره کنید، کودک برای نگاه کردن به آن، رویش را برمی‌گرداند',
    'اگر چیزی را در فاصله‌ای دور یا خارج از پنجره به کودک نشان دهید، به آن توجه می‌کند',
    'زمانی که کودک را صدا می‌زنید به سوی صدا بر‌می‌گردد',
    'در سن ۱۸ ماهگی به درخواست شما اعضای بدنش را با اشاره کردن نشان می‌داد',
    'فقدان توجه، دامنه توجه کوتاه یا توجه ضعیف دارد',
    'در سن ۱ تا ۳ سالگی کودک علائم کم‌شنوایی یا ناشنوایی نشان می‌داد',
    'والدین نگران تاخیر در رشد زبان کودک بوده‌اند',
    'در مقایسه با کودکان دیگر ارتباط چشمی ضعیفی دارد',
    'کودک از انگشت اشاره برای تقاضای برخی چیزها استفاده می‌کند',
    'کودک وسایلی را می‌آورد تا آن را به شما نشان دهد',
    'زمانی‌که او را به پارک می‌برید، چیزهایی را که دوست دارد به شما نشان می‌دهد',
    'فقط برای رفع نیازهایش (مثل گرسنگی و ... یا هنگام نیاز به یک اسباب بازی) با شما ارتباط برقرار می‌کند',
    'سعی می‌کند با صدا درآوردن یا صدا زدن، توجه شما را جلب کند و به شما نگاه کند',
    'بی‌تناسب یا بی‌دلیل می‌خندد، جیغ می‌کشد، می‌ترسد و یا گریه می‌کند',
    'برای تحریک خود صداهای بلند تکراری تولید می‌کند (مثل اِ اِ اِ اِ اِ اِ اِ)',
    'آهنگ یا کیفیت صدای کودک غیرعادی است',
    'از زمان نوزادی یا نوباوگی از در آغوش نگه داشته شدن و نوازش لذت می‌برد',
    'الگوهای خواب نامنظمی با بیدار شدن‌های مکرر در شب برای دوره‌های طولانی مدت را دارد',
    'حرکات کلیشه‌ای مثل چرخش، تکان خوردن، پرش، بال بال زدن، حرکات دست یا انگشت جلوی صورت دارد',
    'کودک مجذوب چرخاندن اشیا می‌شود (مثلاً چرخاندن چرخ‌های ماشین، بشقاب و پنکه)',
    'با اسباب بازی های متنوعی بازی می کند',
    'به جمع آوری کلکسیون های کامل می پردازد',
    'علایق و مشغولیت‌های ذهنی عجیب دارد(مانند چراغ راهنمایی، ناودان، برنامه حرکات قطار و هواپیما)',
    'علاقه‌مندی شدید به کارهای خاصی (مثلاً نخ، چرخاندن چرخ‌های ماشین، روشن و خاموش کردن چراغ) بدون توجه به سرزنش‌ها دارد',
    'کاری را بارها و تنها به یک شیوه تکرار می‌کند',
    'به سختی تغییرات را می‌پذیرد',
    'زمانی که می‌خواهید کودک را به فعالیت دیگری سوق دهید، قشقرق می‌کند',
    'در مقایسه با همسالان، ابراز هیجانات کودک نا‌متناسب با موقعیت‌های اجتماعی است',
    'نوسانات عاطفی و هیجاناتش در برخورد با دیگران، متناسب با موقعیت نمی‌باشد (یعنی، تناسب سطوح ابراز هیجان با مردم مختلف را درک نمی‌کند)',
    'در ۲ سالگی دامنه تغییر حالات چهره ای کودک(مثلا تبسم, تعجب, خشم) در مقایسه با کودکان دیگر محدودتر بود',
    'میزان همدردی کودک پایین است',
    'حافظه بلندمدت استثنایی برای حوادث و وقایع دارد (برای مثال خیلی واضح صحنه‌هایی را که سال‌ها پیش اتفاق افتاده است، به یاد می‌آورد)',
    'کودک دارای توانمندی‌های شگفت آوری است',
    'اگر در مقابل کودک حرکتی کنید، او می‌تواند تقلید کند',
    'اگر اعضای چهره خود را حرکت دهید، کودک می‌تواند تقلید کند (مثلاً شکلک درآوردن، اخم کردن، گریه کردن یا بیرون آوردن زبان)',
    'مداد یا خودکار را به طور مناسب نگه می‌دارد',
    'با مدادرنگی نقاشی می‌کند',
    'در سن ۵–۴ سالگی با بالا انداختن سر و شانه مخالفت خود را با درخواست بزرگتر ها نشان می داد',
    'در سنین ۳ تا ۵ سالگی به نظر می رسد کودک سر در گم و نگران است',
    'بیان کودک تحت الفظی و سطحی است( ضرب المثل ها یا کنایه ها را نمی فهمد)',
    'کودک برای بازی کردن از مادر اجازه می گیرد',
    'فرق شوخی و جدی را می‌فهمد',
    'می‌تواند نام دوستانش را صدا بزند',
    'ضمایر شخصی را به درستی به کار می برد؟(من, تو, او...)',
    'از کلمه «من» نابجا استفاده می کند',
    'راجع به اتفاقات روزمره سوال می کند',
    'درباره به اتفاقات روزانه، مثلا از مهد کودک صحبت میکند',
    'می تواند گفتگو را به موضوع دیگری تغییر دهد',
    'در اصلاح مجدد یک گفتگو مشکل دارد(برای مثال زمانی که گیج می شود, برای واضح تر شدن نمی پرسد بلکه به سادگی موضوع بحث را عوض می کند یا مدت های طولانی برای پاسخ دادن فکر می کند)',
    'می تواند مکالمه دوطرفه را ادامه دهد',
    'با دیگران بر اساس علایق خود به صورت یکسویه صحبت می کند',
    'می تواند مکالمه را با هم سن و سالانش ادامه دهد',
    'توانایی شروع صحبت با بزرگترها یا همسالان خود را دارد',
    'در بازی‌های تخیلی با کودکان دیگر شرکت می‌کند و نقش خاصی را بازی می‌کند',
    'کودک علاقه‌ای به بازی‌های دو به دو یا تعاملی در یک تا سه سالگی نشان می‌داد',
    'با همسالان تعامل اجتماعی دارد و ارتباط برقرار می‌کند',
    'به نظر می‌رسد کودک می‌خواهد دوستانی داشته باشد اما نمی‌داند چگونه دوست پیدا کند',
    'کودک در لاک خویش فرو می‌رود، رفتار کناره‌گیری و تمایل به تنهایی دارد',
    'در یک جمع به دیگران بی‌توجه است، مثل اینکه کسی حضور نداشته باشد',
    'وجود همسالان را نادیده می‌گیرد و از ارتباط با آنان اجتناب می‌کند',
    'کودک ترجیح می‌دهد به تنهایی بازی کند',
    'دیگران را در سرگرمی‌هایش مشارکت می‌دهد',
    'می‌تواند به آسانی خودش را در گروه همسالان جا کند',
    'نحوه بازی با دیگران را درک نمی کند(برای مثال عدم اطلاع از قوانین دسته جمعی)',
    'در ۳ سالگی می توانست بازی های نمادین انجام دهد(مثلا ادای یک قهرمان را در آورد یا میهمانی عروسکی راه بیندازد)',
    'در تعامل با گروه و هماهنگ شدن با قوانین جمعی مشکل دارد',
    'در هم حسی با دیگران در بازی‌های مشارکتی و کارگروهی مشکل دارد',
    'در راه‌ اندازی بازی ساده با دیگران یا مشارکت در بازی‌های اجتماعی اولیه شکست می‌خورد',
]
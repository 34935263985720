import React from 'react'
import './style.css'
import axios from 'axios'

import ApiIcon from '../../../../assets/api-icon.svg'
import PersonalPayIcon from '../../../../assets/personal-pay-icon.svg'
import FaktorIcon from '../../../../assets/faktor-icon.svg'

class Wechsler extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            firstName: "",
            lastName: "",
            sendMessage: "Hello,I'mInPanelPage"
        }
    }

    render() {
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>تحلیل تست شما انجام شده و به زودی در اینجا بارگزاری می‌شود ...</span>
                                </p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default Wechsler
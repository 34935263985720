import React from 'react'
import '../../screening-test/index.css'
import arrowImage from '../../../../assets/arrow.png'
import axios from 'axios'
import resultImageG01 from '../../../../assets/FlatChartG01.svg'
import resultImageG02 from '../../../../assets/FlatChartG02.svg'
import resultImageO01 from '../../../../assets/FlatChartO01.svg'
import resultImageO02 from '../../../../assets/FlatChartO02.svg'
import resultImageR01 from '../../../../assets/FlatChartR01.svg'
import resultImageR02 from '../../../../assets/FlatChartR02.svg'

const faQuestions = require('../../../../utils/gars3TestQuestionsFa.js')
const engQuestions = require('../../../../utils/scoboTestQuestionsEng.js')
const faAnswers = require('../../../../utils/scoboTestAnswersFa')
const engAnswers = require('../../../../utils/scoboTestAnswersEng')
const faTestResults = require('../../../../utils/scoboTestResultsFa.js')
const engTestResults = require('../../../../utils/scoboTestResultsEng.js')

class Gars3 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      gars3Result: null,
      autismBackground: '',
      autismDiagnosis: '',
      firstName: '',
      childAge: 0,
      gender: '',
      lastName: '',
      phoneNumber: '',
      questionsCounter:  0,
      questionsToShow: [],
      questionsSelectionByAge: [0],
      questionNumber: 0,
      questionsTotalNumber: 58,
      questions: {},
      screeningAnswer: [],
      screeningbooking: false,
      execute: false,
      results: {},
      finishedAnswering: false,
      resultNumber: 0,
      countryCode: 'IR', // this.props.languageInput,
      testAnswersOptions: [],
      testResultsText: [],
      isLoaded: false,
      pictureSelector: '',
    }
  }
  

  componentDidMount = async() => {
    // const lang = localStorage.getItem('lang')
    // if(lang==null){
    //   if(this.state.countryCode == 'IR' ){
    //     this.setState({questionsToShow: [...faQuestions]})
    //     this.setState({testAnswersOptions: [...faAnswers]})
    //     this.setState({testResultsText: [...faTestResults]})
    //   } else {
    //     this.setState({questionsToShow: [...engQuestions]})
    //     this.setState({testAnswersOptions: [...engAnswers]})
    //     this.setState({testResultsText: [...engTestResults]})
    //   }
    // } else if(lang =='IR'){
    //   this.setState({questionsToShow: [...faQuestions]})
    //   this.setState({testAnswersOptions: [...faAnswers]})
    //   this.setState({testResultsText: [...faTestResults]})
    // } else {
    //   this.setState({questionsToShow: [...engQuestions]})
    //   this.setState({testAnswersOptions: [...engAnswers]})
    //   this.setState({testResultsText: [...engTestResults]})
    // }

    this.setState({questionsToShow: [].concat(faQuestions)})
    this.setState({testAnswersOptions: [...faAnswers]})
    this.setState({testResultsText: [...faTestResults]})

    const response = await axios.post('https://request.sweetobot.com/evaluation/request', {testName: 'GARS3'}, {headers: {'x-access-token': localStorage.getItem('token')}})
    if (response.data.status == 0) {
      window.location.href = '/fa/panel/evaluations/list?test=GARS3' // It could be changes to show some message to user
    }
    this.setState({userId: response.data.audienceId})
    var receivedAge = response.data.age

    if (response.data.age < 24) receivedAge = 0
    else if (response.data.age < 36) receivedAge = 1
    else if (response.data.age < 48) receivedAge = 2
    else receivedAge = 3
    this.setState({childAge: receivedAge})
  }

  // componentDidUpdate = () => {
  //   if (this.props.languageInput != this.state.countryCode) {
  //     this.setState({countryCode: this.props.languageInput})
  //     if(this.props.languageInput == 'IR' ){
  //       this.setState({questionsToShow: [...faQuestions]})
  //       this.setState({testAnswersOptions: [...faAnswers]})
  //       this.setState({testResultsText: [...faTestResults]})
  //     } else {
  //       this.setState({questionsToShow: [...engQuestions]})
  //       this.setState({testAnswersOptions: [...engAnswers]})
  //       this.setState({testResultsText: [...engTestResults]})
  //     }
  //   }
  // }
  
  questionChildNoSpeak = () => {
    this.state.questionsToShow.splice(45, 14)
    this.setState({questionsTotalNumber: this.state.questionsToShow.length-1})
  }

  screeningAnswerHandler = (value) => {

    this.state.questions[this.state.questionsCounter] = value
    console.log(this.state.questions);
    
    if (this.state.questionsCounter==0 && value!=1){
      this.setState({questionsToShow: [].concat(faQuestions)})
      this.setState({questionsTotalNumber: 58})
    }
    if (this.state.questionsCounter==0 && value==1){
      this.questionChildNoSpeak()
    }
    if (this.state.questionsCounter != this.state.questionsTotalNumber) {
      this.setState({questionsCounter: this.state.questionsCounter+1})
    } else {
      this.setState({finishedAnswering: true})
      this.sendAnsweredQuestions()
    }
  }

  previousQuestionNumberHandler = () => {
    if (this.state.questionsCounter > 0){
      this.setState({questionNumber: 1})
      this.setState({questionsCounter: this.state.questionsCounter-1})
    }
  }

  nextQuestionNumberHandler = () => {
    if (this.state.questionNumber == 1){
      this.setState({questionsCounter: this.state.questionsCounter+1})
      this.setState({questionNumber: 0})
    }
  }

  sendAnsweredQuestions = async() => {
    const results = await axios.post('https://request.sweetobot.com/evaluation/evaluate', {
      audienceId: this.state.userId,
      age: this.state.childAge, // 0, 1, 2, 3
      sex: this.state.gender,
      hasAutism: this.state.autismBackground,
      diagAutism: this.state.autismDiagnosis,
      questions: Object.keys(this.state.questions), 
      answers: Object.values(this.state.questions),
      test: 'GARS3'
    }, {
      headers: {'x-access-token': localStorage.getItem('token')}
    })
    console.log('server answer is: ' , results.data);
    this.setState({gars3Result: results.data.result})
  }

  render() {
    if (this.state.gars3Result != null){
      if (this.state.gars3Result.Result == 1){
        this.setState({pictureSelector: resultImageG02})
      } 
      if (this.state.gars3Result.Result == 2){
        this.setState({pictureSelector: resultImageO02})
      } 
      if (this.state.gars3Result.Result == 3){
        this.setState({pictureSelector: resultImageR02})
      }

      return (
        <div className='result-page'>
          <div className='result-page-container'>
            <div className='result-page-right-wrapper'>
              {this.state.gars3Result.Result==1 && <img src={this.state.pictureSelector} className='result-answer'></img>}
              {this.state.gars3Result.Result==2 && <img src={resultImageO02} className='result-answer'></img>}
              {this.state.gars3Result.Result==3 && <img src={resultImageR02} className='result-answer'></img>}
              {this.state.gars3Result.Result==4 && <img src={resultImageR02} className='result-answer'></img>}
            </div>
            <div className='result-page-left-wrapper'>
              {this.state.gars3Result.Result==1 && <div className='result-answer-text'>{this.state.testResultsText[1]}</div>}

              {this.state.gars3Result.Result==2 && <div className='result-answer-text'>{this.state.testResultsText[2]}</div>}
              {this.state.gars3Result.Result==2 && <p className='result-answer-title mt-1' >{this.state.testResultsText[3]}</p>}

              {this.state.gars3Result.Result==3 && <div className='result-answer-text'>{this.state.testResultsText[4]}</div>}
              {this.state.gars3Result.Result==3 && <p className='result-answer-title mt-1' >{this.state.testResultsText[5]}</p>}

              {this.state.gars3Result.Result==4 && <div className='result-answer-text'>{this.state.testResultsText[4]}</div>}
              {this.state.gars3Result.Result==4 && <p className='result-answer-title mt-1' >{this.state.testResultsText[5]}</p>}
              <div className='d-flex'>
                <a href={`/fa/panel/evaluations/list/results?id=${this.state.userId}`}> {"رفتن به صفحه نتایج"} </a>
                <a href='/fa/panel'> {"بازگشت به داشبورد"} </a>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (this.state.finishedAnswering == true){
      return (
        <div className='screening-test' style={{backgroundImage: 'none'}}>
          {this.state.testResultsText[0]}
        </div>
      )
    }

    return (
      <div className={this.state.countryCode=='IR' ? 'screening-test' : 'screening-test-eng'} style={{backgroundImage: 'none'}}>
        <button onClick={() => this.nextQuestionNumberHandler()} className='pushButton-next-question'> 
          <img src={arrowImage}></img>
        </button>
        <div className='test-container'>
          <div className='question-number'>{this.state.questionsCounter}/{this.state.questionsTotalNumber}</div>
          <div className='container-screening-question'>
            <div className='screening-test-question'>
              <p>{this.state.questionsToShow[this.state.questionsCounter]}</p>
            </div>
          </div>
          <div className='container-screening-answer'>
          <button onClick={() => this.screeningAnswerHandler(4)} className='screening-answer-option mode-bg-4'>{this.state.testAnswersOptions[0]}</button>
            <button onClick={() => this.screeningAnswerHandler(3)} className='screening-answer-option mode-bg-4'>{this.state.testAnswersOptions[1]}</button>
            <button onClick={() => this.screeningAnswerHandler(2)} className='screening-answer-option mode-bg-4'>{this.state.testAnswersOptions[2]}</button>
            <button onClick={() => this.screeningAnswerHandler(1)} className='screening-answer-option mode-bg-4'>{this.state.testAnswersOptions[3]}</button>
          </div>
        </div>
          <button onClick={() => this.previousQuestionNumberHandler()} className='pushButton-previous-question'> 
            <img src={arrowImage}></img>
          </button>
      </div>
    )
  }
}

export default Gars3
import React from 'react'
import './index.css'
import LoadingGif from '../../assets/loading-gif.gif'


function LoadingPage() {
  return (
    <div className='loading-container'>
      <img src={LoadingGif} alt={'loading-gif'}></img>
    </div>
  )
}

export default LoadingPage
module.exports = [
    "Spend 5 minutes to check your child's growth milestones",
    "You just have to to save the money you want to waste on unnecessary purchases today and spend it To complete the most accurate autism screening test in the world to make sure your child grows normaly and also get a free consultation from us ",
    "child name",
    "mobile number",
    "Select your child's age",
    "between 18 months to 2 years",
    "between 2 years to 3 years",
    "between 3 years to 4 years",
    "overy 4 years",
    "select your child's gender",
    "girl",
    "boy",
    "Is there any record of autism in your family?",
    "yes",
    "no",
    "my child is already diagnosed with autism.",
    "10 USD",
    "start",
    "enter your childs name",
    'enter your phone number',
    "enter your childs age",
    "please fill all the information",
    "discount code",
    "what ever",
    'family name'
]
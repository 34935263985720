module.exports = [
    [   // 2 months questionair
        'آیا کودک شما گاهی اوقات صداهای حبابی و از ته گلو در می‌آورد؟',
        'آیا کودک شما صداهای کوتاه و از روی ذوق مثل "آاااا" یا "اوووو" یا "اَاَاَاَاَ" در می‌آورد؟',
        'وقتی با کودک خود صحبت می‌کنید، آیا با درآوردن صداهایی سعی می‌کند واکنش نشان دهد؟',
        'آیا وقتی با کودک صحبت می‌کنید، لبخند می‌زند؟',
        'آیا کودک لبخند کوچک می‌زند؟',
        'بعد از اینکه از دیدِ کودک خارج شوید، آیا وقتی شما را ببیند لبخند می‌زند یا هیجان زده می‌شود؟',
        'وقتی که کودک شما به پشت است، آیا دست‌ها و پاهایش را تکان می‌دهد؟ بالا و پایین می‌کند؟ بدنش را خم می‌کند؟',
        'وقتی کودک شما به شکم است، آیا سرش را به طرفین می‌چرخاند؟',
        'وقتی کودک شما به شکم است، آیا سرش را بیش از چند ثانیه بالا نگه می‌دارد؟',
        'وقتی کودک شما به پشت است، آیا با پایش لگد می‌زند؟',
        'در حالی که کودک شما به پشت است، آیا سرش را از سمتی به سمت دیگر می‌چرخاند؟',
        'بعد از اینکه سرش را در حالتی که روی شکم است بالا نگه داشت، آیا کودک شما سرش را برای استراحت روی زمین می‌گذارد؟ در مقایسه با حالتی که سرش به سمت جلو بیفتد یا قرار گیرد.',
        'آیا دست کودک شما معمولا کاملا مُشت است هنگامی که بیدار است؟ (اگر کودک شما قبلا این کار را می‌کرده، اما اکنون دیگر انجام نمی‌دهد، "بله" را بزنید.)',
        'آیا کودک انگشت شما را وقتی که آن را داخل دست کودک قرار می‌دهد، در دستش می‌گیرد؟',
        'وقتی که اسباب‌بازی را درون دستش قرار می‌دهید، آیا آن را برای لحظاتی در دستش نگه می‌دارد؟',
        'آیا کودک با دستانش صورتش را لمس می‌کند؟',
        'آیا کودک شما دستانش را باز یا نیمه باز نگه می‌دارد در حالتی که بیدار است؟ (در مقایسه با زمانی که تازه به دنیا آمده بود و اغلب آن‌ها را مشت می‌کرد)',
        'آیا کودک لباس‌هایش را می‌گیرد یا چنگ می‌زند؟',
        'آیا کودک شما به اشیائی که ۲۰ تا ۲۵ سانت دورتر هستند نگاه می‌کند؟',
        'وقتی که در دور و بر حرکت می‌کنید، آیا کودک با نگاهش شما را دنبال می‌کند؟',
        'هنگامی که شما یک اسباب‌بازی را جلوی صورت کودک (با فاصله‌ی حدودا ۲۵ سانت) از سمتی به سمت دیگر می‌برید، آیا کودک با چشمانش آن را دنبال می‌کند؟',
        'وقتی که یک اسباب‌بازی کوچک را به آرامی به بالا و پایین در جلوی صورت کودک حرکت می‌دهید (با حدود ۲۰ سانت فاصله)، آیا کودک شما اسباب‌بازی را با چشمانش دنبال می‌کند؟',
        'وقتی که کودک را در یک حالت نشسته نگه می‌دارید، آیا به یک اسباب‌بازی (به اندازه‌ی سایز یک فنجان یا جغجغه) که در جلویش روی میز یا زمین می‌گذارید نگاه می‌کند؟',
        'وقتی یک اسباب‌بازی را در حالی که کودک به پشت دراز کشیده بالای سر او آویزان می‌کنید، آیا دستانش را به سمت اسباب‌بازی تکان می‌دهد؟',
        'آیا کودک شما گاهی اوقات تلاش در مکیدن می‌کند، حتی وقتی که در حال شیر خوردن نباشد؟',
        'آیا کودک شما وقتی که گرسنه یا خسته است یا خودش را خیس کرده یا دلش بغل می‌خواهد، گریه می‌کند؟',
        'آیا کودک شما برای شما لبخند می‌زند؟',
        'وقتی شما به کودک لبخند می‌زنید، آیا او هم با لبخند پاسخ شما را می‌دهد؟',
        'آیا کودک شما به دستانش نگاه می‌کند؟',
        'وقتی کودک شما سینه‌ی مادر یا شیشه‌ی شیرش را می‌بیند، آیا به نظر می‌آید که می‌داند قرار است به او غذایش داده شود؟',
    ],
    [   // 4 months questionair
        'آیا کودک شما به طور ملایم می‌خندد؟',
        'بعد از اینکه از دید کودک خارج می‌شوید، آیا کودک وقتی مجدد شما را می‌بیند لبخند می‌زند یا هیجان زده می‌شود؟',
        'آیا کودک با شنیدن صدایی به جز صدای شما، به گریه‌اش خاتمه می‌دهد؟',
        'آیا کودک شما جیغ‌های با صدای بسیار تیز می‌زند؟',
        'آیا کودک شما با صدا می‌خندد؟',
        'آیا کودک وقتی به اطرافیان یا اسباب‌بازی‌هایش نگاه می‌کند، از خودش صداهایی در می‌آورد؟',
        'وقتی که کودک به پشت است، آیا سرش را از سمتی به سمت دیگر حرکت می‌دهد؟',
        'پس از بالا گرفتن سرش در حالی که به روی شکمش است، آیا کودک شما سرش را مجددا به روی زمین می‌گذارد؟ به جای اینکه سرش روی زمین یا رو به  جلو بیفتد',
        'وقتی که کودک شما روی شکمش است، آیا سرش را به گونه‌ای که چانه‌اش ۷٫۵ سانت بالاتر از زمین قرار بگیرد بالا نگه می‌دارد؟',
        'وقتی که کودک شما به شکم است، آیا سرش را مستقیما رو به بالا نگه می‌دارد و به اطراغ نگاه کند؟ (ممکن است هنگام این کار به روی آرنج تکیه داده باشد)',
        'وقتی که او را در حالت نشسته نگه می‌دارید، آیا کودک شما سرش را در حالت پایدار نگه می‌دارد؟',
        'وقتی که کودک شما به پشت دراز کشیده است، آیا دستانش را کنار هم به روی سینه‌اش می‌آورد و انگشتانش را لمس کند؟',
        'آیا کودک شما دستانش را باز یا نیمه‌باز نگه می‌دارد؟ (در مقایسه با زمانی که تازه متولد شده بود و دستانش غالبا مشت بود)',
        'وقتی که یک اسباب‌بازی را در دستش قرار می‌دهید، آیا کودک شما آن را تکان می‌دهد؟ حتی شده برای مدت کوتاهی',
        'آیا کودک شما لباس‌هایش را می‌گیرد یا به آن‌ها چنگ می‌اندازد؟',
        'وقتی که یک اسباب‌بازی را در دستانش قرار می‌دهید، آیا کودک شما آن را نزدیک به یک دقیقه نگه می‌دارد در حالی که دارد به آن نگاه می‌کند، آن را تکان می‌دهد یا آن را گاز می‌گیرد؟',
        'ایا کودک شما انگشتانش را روی میزی که رو به روی او می‌باشد جمع می‌کند یا روی آن ناخن می‌اندازد؟ چه در  حالتی که در حالت نشسته نگه داشته باشد یا روی شکمش دراز کشیده باشد',
        'وقتی که کودک را در حالت نشسته قرار می‌دهید آیا سعی می‌کند به یک اسباب‌بازی که روی میز هست دست یابد، حتی با اینکه ممکن است دستش به آن نرسد؟',
        'وقتی که یک اسباب‌بازی را به آرامی از طرفی به طرف دیگر در جلوی صورت کودک حرکت می‌دهید (با حدود ۲۰ سانت فاصله)، آیا کودک شما اسباب‌بازی را با چشمانش دنبال می‌کند، حتی گاهی اوقات سرش را نیز بچرخاند؟',
        'وقتی که یک اسباب‌بازی را به آرامی در جلوی صورت کودک به بالا و پایین حرکت می‌دهید (با حدود ۲۰ سانت فاصله)، آیا کودک شما اسباب‌بازی را با نگاهش دنبال می‌کند؟',
        'وقتی کودک خود را در حالتی نشسته نگه می‌دارید، آیا به یک اسباب‌بازی (در حدود سایز یک فنجان یا جغجغه) که روی میز یا زمین جلوی او قرار داده‌اید، نگاه می‌کند؟',
        'وقتی یک اسباب‌بازی را در دستانش قرار می‌دهید، آیا کودک شما به آن نگاه می‌کند؟',
        'وقتی یک اسباب‌بازی را در دستانش قرار می‌دهید، آیا اسباب‌بازی را در دهانش قرار می‌دهد؟',
        'وقتی یک اسباب‌بازی را در بالاسر کودک خود در حالی که به پشت دراز کشیده است آویزان می‌کنید، آیا کودک شما دست‌هاش را در مقابل اسباب‌بازی تکان می‌دهد؟',
        'آیا کودک شما به دستانش نگاه می‌کند؟',
        'وقتی دستان کودک شما در کنار هم قرار می‌گیرند، آیا با انگشتانش بازی می‌کند؟',
        'وقتی کودک شما سینه یا شیشه‌ی شیر را می‌بیند، آیا اینطور به نظر میاد می‌داند که قرار است به او غذا داده شود؟',
        'آیا کودک شما بطری را با هر دو دستش نگه می‌دارد، یا به هنگام شیردهی، آیا سینه را با دست آزادش می‌گیرد؟',
        'قبل از اینکه شما به کودک لبخند بزنید یا با او صحبت کنید، آیا وقتی او شما را در نزدیکی می‌بیند به شما لبخند می‌زند؟',
        'وقتی کودک شما در مقابل آینه‌ای بزرگ قرار می‌گیرد، آیا به خودش لبخند می‌زند یا برای خودش صدا در می‌اورد؟'
    ],
    [   // 6 months questionair
        'آیا کودک شما جیغ‌های با صدای بسیار تیز می‌زند؟',
        'وقتی بازی‌های با صدا با کودک می‌کنید، آیا کودک صداهایی شبیه به ناله، صداهای از ته گلو یا صداهای بم از خودش در می‌آورد؟',
        'اگر کودک خود را وقتی که در خارج از دید او هستید صدا بزنید، آیا او به سمت صدای شما نگاه می‌کند؟',
        'وقتی سر و صدای بلندی ایجاد می‌شود، آیا کودک شما بر می‌گردد که ببیند صدا از کجا آمده است؟',
        'آیا کودک شما صداهایی مانند "دا"، "گا"، "کا" و "با" در می‌آورد؟',
        'اگر صداهایی را که کودک شما در می‌آورد تقلید کنید، آیا کودک مجددا آن صداها را برای شما تکرار می‌کند؟',
        'وقتی کودک شما به پشتش دراز کشیده است، آیا کودک شما پاهایش را به اندازه کافی برای دیدن نوک انگشتانش بالا می‌آورد؟',
        'وقتی کودک شما روی شکمش است، آیا هر دو دستانش را دراز می‌کند و سینه‌اش را به سمت دیگر تخت یا زمین هدایت کند؟ (خودش را یه ور کند)',
        'آیا کودک شما از حالتی که به پشت دراز کشیده به حالتی که به شکم باشد غلت می‌زند، به طوری که هر دو دستش را از زیرش خارج کند؟',
        'وقتی که کودک خود را روی زمین می‌گذارید، آیا در هنگام نشستن به دستانش تکیه می‌دهد؟ (اگر هم‌اکنون بدون تکیه به دستانش می‌تواند بنشیند، گزینه بله را برای این آیتم بزنید)',
        'اگر دو دست کودک را برای کمک به تعادلش بگیرید، آیا می‌تواند وزن خود را در حالت ایستاده نگه دارد؟',
        'آیا کودک شما به حالت چهار دست و پا قرار می‌گیرد، به طوری که روی دست و زانوهایش تکیه کند؟',
        'آیا کودک شما اسباب‌بازی که به او می‌دهید را در دستش می‌گیرد و آن را نگاه کند، تکان دهد یا آن را برای نزدیک به ۱ دقیقه در دهانش بگذارد؟',
        'آیا کودک شما به سمت یک اسباب‌بازی با دو دست می‌رود یا آن را با دو دست به طور همزمان در دستانش می‌گیرد؟',
        'آیا کودک شما به سمت یک خوراکی کوچک یا خرده نان می‌رود و آن را با انگشت یا لمس کند؟ (اگر هم‌اکنون چیز کوچکی به اندازه یک لوبیا را می‌تواند بردارد، برای این سوال "بله" را انتخاب کنید) ',
        'آیا کودک شما اسباب‌بازی کوچکی را، به طوری که آن را در مرکز دستش نگه دارد و انگشتانش را دور آن گرفت هباشد، بر می‌دارد؟',
        'آیا کودک شما تلاش می‌کند یک خوراکی کوچک یا خرده نان را با استفاده از انگشت شست و سایر انگشتانش در حالتی چنگ مانند بردارد؟ حتی اگر موفق به برداشتنش نشود (اگر هم‌اکنون می‌تواند یک خوراکی کوچک یا خرده نان را بردارد، برای این آیتم "بله" را بزنید)',
        'آیا کودک شما یک اسباب بازی کوچک را تنها با یک دست می‌تواند بلند کند؟',
        'وقتی که یک اسباب‌بازی روبروی کودک شماست، آیا با دو دستش به سمت آن می‌رود؟',
        'وقتی که کودک شما به پشت دراز کشیده است، آیا وقتی یک اسباب‌بازی را می‌اندازد، سرش را می‌چرخاند تا اسباب‌بازی را پیدا کند؟ (اگر هم‌اکنون آن را بر هم می‌دارد، گزینه "بله" را برای این آیتم بزنید)',
        'وقتی که کودک شما به پشتش است، اگر اسباب‌بازی را که انداخته بتواند ببیند، آیا تلاش می‌کند که آن را بردارد؟',
        'آیا کودک شما اسباب‌بازی را بر می‌دارد و آن را در دهانش بگذارد؟',
        'آیا کودک شما یک اسباب‌بازی را از این دست به آن دستش می‌دهد؟',
        'آیا کودک شما با پرت کردن اسباب‌بازی به بالا و پایین روی میز یا زمین، بازی می‌کند؟',
        'وقتی که در مقابل یک آینه‌ی بزرگ قرار می‌گیرد، آیا کودک شما لبخند می‌زند یا با خودش دالی می‌کند؟',
        'آیا کودک شما در مقابل غریبه‌ها نسبت به شما و سایر افراد آشنا، رفتار متفاوتی دارد؟ (این رفتارها نسبت به غریبه‌ها می‌تواند شامل زل زدن، اخم کردن، امتناع کردن یا گریه کردن باشد)',
        'وقتی که به پشتش دراز کشیده است، آیا کودک شما با گرفتن پاهایش، بازی می‌کند؟',
        'وقتی که در مقابل یک آینه‌ی بزرگ قرار می‌گیرد، آیا کودکتان می‌رود که به آینه با کف دست ضربه بزند؟',
        'وقتی که کودک شما به پشتش است، آیا پایش را در دهانش می‌گذارد؟',
        'آیا کودک شما تلاش می‌کند به اسباب‌بازی که در دسترسش نیست، دست یابد؟ (ممکن است غلت بزند، جهتش را به شکمش عوض کند یا چهاردست و پا برود تا آن را بگیرد)'
    ],
    [   // 8 months questionair
        'اگر کودک خود را وقتی در ناحیه‌ی دید او نیستید صدا می‌زنید، آیا او به سمت صدای شما نگاه می‌کند؟',
        'وقتی سر و صدای بلندی رخ می‌دهد، آیا کودک شما بر می‌گردد که ببیند صدا از کجا آمده است؟',
        'اگر صداهایی را که کودک شما ایجاد می‌کند در بیاورید، آیا کودک همان صداها را برای شما مجددا تکرار می‌کند؟',
        'آیا کودک شما صداهایی چون "دا"، "گا"، "کا" و "با" را در می‌آورد؟',
        'آیا کودک شما به لحن صدای شما عکس‌العملی نشان می‌دهد و فعالیتش را هنگامی که شما به او می‌گویید "نه نه" حتی شده برای لحظاتی متوقف می‌کند؟',
        'آیا کودک شما دو صدای مشابه مانند "با-با" یا "گا-گا" در می‌آورد؟ (صداها نیازی نیست که معنای خاصی داشته باشند)',
        'هنگامی که کودک خود را روی زمین می‌گذارید، آیا هنگام نشستن به دستانش تکیه می‌دهد؟ (اگر هم اکنون بدون تکیه به دستانش هم می‌تواند صاف بنشیند، برای این آیتم "بله" را انتخاب کنید)',
        'آیا کودک شما از حالت پشت به حالت شکم غلت می‌زند، به طوری که هر دو دستش در زیرش نماند؟',
        'آیا کودک شما با قرار گرفتن روی دست‌ها و زانوانش به حالت چهار دست و پا در می‌آید؟',
        'اگر دو دست کودک را جهت ایستادنش نگه دارید، آیا می‌تواند در حالت ایستاده وزن خود را تحمل کند؟',
        'در حالی که کودک شما روی زمین نشسته، آیا می‌تواند بدون تکیه دادن به دستانش برای چند دقیقه صاف بنشیند؟',
        'وقتی که کودک خود را نزدیک به مبلمان یا محافظ تخت به صورت ایستاده می‌گذارید، آی می‌تواند خودش را بدون تکیه دادن سینه‌اش به مبلمان جهت حفظ تعادل نگه دارد؟',
        'آیا کودک شما به سمت یک خوراکی کوچک یا تکه نان می‌رود تا آن را با دست یا انگشتش لمس کند؟ (اگر هم‌اکنون می‌تواند اشیاء کوچک را بردارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما اسباب بازی کوچکی را بر می‌دارد و آن را در کف کف دستش قرار دهد، به طوری که انگشتانش را دور آن جمع کرده باشد؟',
        'آیا کودک شما سعی می‌کند که یک خوراکی کوچک یا تکه نان را با کمک شست و سایر انگشتانش با جمع کردن انگشتان بردارد، حتی اگر موفق نشود آن را بردارد؟ (اگر هم اکنون یک خوراکی کوچک یا تکه نان را بر می‌دارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما می‌تواند یک اسباب‌بازی کوچک را تنها با یک دستش بردارد؟',
        'آیا کودک شما می‌تواند با موفقیت یک خوراکی کوچک یا خرده نان را با کمک شست و سایر انگشتانش با جمع کردن انگشتان بردارد؟ (اگر هم‌اکنون یک خوراکی کوچک یا تکه نان را بر می‌دارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما یک اسباب‌بازی کوچک را با نوک شست و انگشتانش برمی‌دارد؟ (در این حالت باید فضایی را نیز بین کف دست و اسباب‌بازی مشاهده کنید)',
        'آیا کودک شما یک اسباب‌بازی را برمی‌دارد و آن را در دهانش می‌گذارد؟',
        'وقتی کودک شما به پشت است، آیا تلاش می‌کند یک اسباب‌بازی را که پیش‌تر روی زمین انداخته، در صورت دیدنِ آن، بردارد؟',
        'آیا کودک شما با پرتاب یک اسباب‌بازی به بالا پایین رو زمین یا میز، به بازی می‌پردازد؟',
        'آیا کودک شما یک اسباب‌بازی را از این دست به آن دستش می‌دهد؟',
        'آیا کودک شما دو اسباب‌بازی کوچک را بر می‌دارد، به طوری که هر کدام را در یک دستش قرار دهد و آن‌ها را برای یک دقیقه بتواند در دستش نگه‌دارد؟',
        'وقتی که یک اسباب‌بازی را در دستش نگه می‌دارد، آیا کودک شما آن را به سمت اسباب‌بازی دیگری روی میز پرت می‌کند؟',
        'وقتی که به پشتش دراز کشیده است، آیا با گرفتن پاهایش به بازی می‌پردازد؟',
        'وقتی در مقابل یک آینه‌ی بزرگ قرار می‌گیرد، آیا کودک شما به سمت آینه می‌رود تا به آن ضربه بزند؟',
        'آیا کودک شما تلاش می‌کند تا یک اسباب‌بازی را که از دسترس خارج است بگیرد؟ (ممکن است غلت بزند، به سمت شکمش تغییر حالت دهد یا به سمت آن چهار دست و پا برود)',
        'وقتی که کودک شما به پشت است، آیا پایش را در دهانش می‌کند؟',
        'آیا کودک شما آب، نوشیدنی یا مایعات را از داخل لیوان وقتی شما آن را نگه داشته‌اید می‌تواند بنوشد؟',
        'آیا کودک شما می‌تواند خودش یک شیرینی یا کوکی را بردارد بخورد؟'
    ],
    [   // 9 months questionair
        'آیا کودک شما صداهایی مانند "دا"، "گا"، "، "کا" و "با" در می‌آورد؟',
        'اگر صداهایی که کودکتان در می‌آورد را کپی کنید، آیا کودک شما همان صداها را برای شما تکرار می‌کند؟',
        'آیا کودک شما دو صدای مشابه مانند "با-با"، "دا-دا" یا "گا-گا" را در می‌آورد؟ (نیازی نیست این صداها معنی خاصی داشته باشند)',
        'اگر از کودکتان بخواهید، آیا او حداقل یکی از بازی‌های کودکانه را حتی بدون اینکه شما برایش انجام دهید، انجام می‌دهد؟ (مانند "بای-بای"، "دالی"، "دست دستی"، "هورررا")',
        'آیا کودک شما سک دستور ساده را اجرا می‌کند، مانند "بیا اینجا"، "بده به من" یا "بذار سر جاش"، بدون اینکه شما بخواهید از حالات اشاره‌ای استفاده کنید؟',
        'آیا کودک شما سه کلمه اولیه را می‌گوید، مانند "ماما"، "بابا" و "دادا"؟ (یک "کلمه" یعنی صدا یا صداهایی که کودک شما به صورت پشت سر هم به کار می‌برد تا مفهومِ کسی یا چیزی را برساند)',
        'اگر دو دست کودک را برای کمک به حفظ تعادل کودک نگه دارید، آیا می‌تواند وزنش را در حالت ایستاده نگه دارد؟',
        'وقتی که روی زمین نشسته است، آیا کودک شما برای چند دقیقه بدون استفاده از دستانش جهت کمک، می‌تواند صاف بنشیند؟ ',
        'وقتی که کودک را نزدیک به مبلمان یا محافظ تخت به صورت ایستاده قرار می‌دهید، آیا می‌تواند خودش را بدون تکیه دادن سینه‌اش  به مبلمان نگه دارد؟',
        'در حالی که مبلمان را نگه داشته است، آیا کودک شما خم می‌شود و یک اسباب‌بازی را از روی زمین بردارد و سپس به حالت ایستاده بازگردد؟',
        'وقتی مبلمان را نگه داشته است، آیا می‌تواند خودش را به صورت کنترل شده خم کند؟ (بدون افتادن یا تلو تلو خوردن)',
        'آیا کودک شما می‌تواند در کنار مبلمان راه برود در حالی که تنها یک دستش را به آن نگه داشته؟',
        'آیا کودک شما یک اسباب بازی کوچک را می‌تواند تنها با یک دست نگه دارد؟',
        'آیا کودک شما با موفقیت می‌تواند یک خوراکی کوچک یا یک خرده نان را با کمک انگشت شست و سایر انگشتانش با جمع کردن آن‌ها بردارد؟ (اگر هم‌اکنون  می‌تواند یک خوراکی کوچک یا خرده نان را بردارد گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما یک اسباب‌بازی کوچک را با نوک شست و انگشتانش برمی‌دارد؟ (باید بین اسباب‌بازی و کف دستش فاصله‌ای ببینید)',
        'پس از یک یا دو تلاش، آیا کودک شما یک تکه از نخ را می‌تواند با انگشت سبابه و شستش بردارد؟ (ممکن است این نخ متصل به یک اسباب‌بازی باشد)',
        'آیا کودک شما یک خوراکی کوچک یا خرده نان را با نوک شست و سایر انگشتانش برمی‌دارد؟ ممکن است به هنگام این کار کتف یا دستش را روی میز تکیه دهد.',
        'آیا کودک شما یک اسباب‌بازی کوچک را زمین می‌گذارد، بدون آن که آن را بیاندازد و سپس اسباب‌بازی را روی زمین رها کند؟',
        'آیا کودک شما یک اسباب‌بازی را از یک دست به دست دیگرش می‌دهد؟',
        'آیا کودک شما دو اسباب‌بازی کوچک را هر کدام در یک دست بر می‌دارد و آن‌ها را برای یک دقیقه نگه دارد؟',
        'هنگامی که یک اسباب‌بازی کوچک در یکی از دستانش است، آیا کودک شما آن را به سمت اسباب‌بازی دیگری که روی میز است پرتاب می‌کند؟',
        'در حالی که یک اسباب‌بازی کوچک در هر یک از دستانش است، آیا کودک شما اسباب‌بازی‌ها را به هم می‌کوبد؟ (مثل دست زدن)',
        'آیا کودک شما انگشتش را داخل بطری که داخل آن یک خوراکی یا خرده نان است فرو می‌کند یا تلاش می‌کند آن را به دست آورد؟ (مثل بطری نوشابه یا بطری نوشیدنی کودک)',
        'بعد از اینکه ببیند شما یک اسباب‌بازی کوچک را زیر یک تکه کاغذ یا لباس قایم کردید، آیا کودک شما آن را پیدا می‌کند؟ (مطمئن شوید که اسباب‌بازی کاملا نامعلوم است)',
        'وقتی که کودک شما به پشت است، آیا پایش را در دهانش می‌گذارد؟',
        'آیا کودک شما آب، نوشیدنی یا مایعات را می‌تواند از داخل لیوان هنگامی که شما برایش نگه داشته‌اید بنوشد؟',
        'آیا کودک شما یک شیرینی یا کوکی را می‌تواند خودش بخورد؟',
        'وقتی که دستتان را می‌برید جلو و از کودک اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را به سمت شما می‌آورد حتی اگر آن را به شما ندهد؟ (اگر هم‌اکنون اسباب‌بازی را در دست شما می‌گذارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'وقتی به کودک خود لباس می‌پوشانید، آیا دستانش را با نزدیک شدن به حفره‌ی آستین در آن فرو می‌کند؟',
        'وقتی که دستتان را به جلو می‌برید و اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را در دست شما می‌گذارد؟'

    ],
    [   // 10 months questionair
        'آیا کودک شما صداهایی مانند "دا"، "گا"، "، "کا" و "با" در می‌آورد؟',
        'اگر صداهایی که کودکتان در می‌آورد را کپی کنید، آیا کودک شما همان صداها را برای شما تکرار می‌کند؟',
        'آیا کودک شما دو صدای مشابه مانند "با-با"، "دا-دا" یا "گا-گا" را در می‌آورد؟ (نیازی نیست که صداها معنای خاصی داشته باشند)',
        'اگر از کودک بخواهید، آیا حداقل یک بازی کودکانه را حتی بدون اینکه شما آن را خودتان انجام دهید، انجام می‌دهد؟ (مثل "بای-بای"، "دالی"، دست دستی"، "هورررا")',
        'آیا کودک شما یک دستور ساده مثل "بیا اینجا"، "بده به من" یا "بذار سر جاش" را بدون کمک اشاره‌ای انجام می‌دهد؟',
        'آیا کودک شما سه لغا مانند "ماما"، "دادا" و "بابا" را می‌گوید؟ (یک "لغت" صدا یا صداهایی است که کودک شما به طور پشت سر هم به کار می‌برد تا مفهوم کسی یا چیزی را برساند)',
        'اگر دو دست کودک را برای کمک به حفظ تعادل کودک نگه دارید، آیا می‌تواند وزنش را در حالت ایستاده نگه دارد؟',
        'وقتی که روی زمین نشسته است، آیا کودک شما برای چند دقیقه بدون استفاده از دستانش جهت کمک، می‌تواند صاف بنشیند؟ ',
        'وقتی که کودک را نزدیک به مبلمان یا محافظ تخت به صورت ایستاده قرار می‌دهید، آیا می‌تواند خودش را بدون تکیه دادن سینه‌اش  به مبلمان نگه دارد؟',
        'در حالی که که مبلمان را نگه داشته است، آیا کودک شما خم می‌شود و یک اسباب‌بازی را از روی زمین بردارد و سپس به حالت ایستاده بازگردد؟',
        'وقتی مبلمان را نگه داشته است، آیا می‌تواند خودش را به صورت کنترل شده خم کند؟ (بدون افتادن یا تلو تلو خوردن)',
        'آیا کودک شما می‌تواند در کنار مبلمان راه برود در حالی که تنها یک دستش را به آن نگه داشته؟',
        'آیا کودک شما یک اسباب بازی کوچک را می‌تواند تنها با یک دست نگه دارد؟',
        'آیا کودک شما با موفقیت می‌تواند یک خوراکی کوچک یا یک خرده نان را با کمک انگشت شست و سایر انگشتانش با جمع کردن آن‌ها بردارد؟ (اگر هم‌اکنون  می‌تواند یک خوراکی کوچک یا خرده نان را بردارد گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما یک اسباب‌بازی کوچک را با نوک شست و انگشتانش برمی‌دارد؟ (باید بین اسباب‌بازی و کف دستش فاصله‌ای ببینید)',
        'پس از یک یا دو تلاش، آیا کودک شما یک تکه از نخ را می‌تواند با انگشت سبابه و شستش بردارد؟ (ممکن است این نخ متصل به یک اسباب‌بازی باشد)',
        'آیا کودک شما یک خوراکی کوچک یا خرده نان را با نوک شست و سایر انگشتانش برمی‌دارد؟ ممکن است به هنگام این کار کتف یا دستش را روی میز تکیه دهد.',
        'آیا کودک شما یک اسباب‌بازی کوچک را زمین می‌گذارد، بدون آن که آن را بیاندازد و سپس اسباب‌بازی را روی زمین رها کند؟',
        'آیا کودک شما یک اسباب‌بازی را از یک دست به دست دیگرش می‌دهد؟',
        'آیا کودک شما دو اسباب‌بازی کوچک را هر کدام در یک دست بر می‌دارد و آن‌ها را برای یک دقیقه نگه دارد؟',
        'هنگامی که یک اسباب‌بازی کوچک در یکی از دستانش است، آیا کودک شما آن را به سمت اسباب‌بازی دیگری که روی میز است پرتاب می‌کند؟',
        'در حالی که یک اسباب‌بازی کوچک در هر یک از دستانش است، آیا کودک شما اسباب‌بازی‌ها را به هم می‌کوبد؟ (مثل دست زدن)',
        'آیا کودک شما انگشتش را داخل بطری که داخل آن یک خوراکی یا خرده نان است فرو می‌کند یا تلاش می‌کند آن را به دست آورد؟ (مثل بطری نوشابه یا بطری نوشیدنی کودک)',
        'بعد از اینکه ببیند شما یک اسباب‌بازی کوچک را زیر یک تکه کاغذ یا لباس قایم کردید، آیا کودک شما آن را پیدا می‌کند؟ (مطمئن شوید که اسباب‌بازی کاملا نامعلوم است)',
        'وقتی که کودک شما به پشت است، آیا پایش را در دهانش می‌گذارد؟',
        'آیا کودک شما آب، نوشیدنی یا مایعات را می‌تواند از داخل لیوان هنگامی که شما برایش نگه داشته‌اید بنوشد؟',
        'آیا کودک شما یک شیرینی یا کوکی را می‌تواند خودش بخورد؟',
        'وقتی که دستتان را می‌برید جلو و از کودک اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را به سمت شما می‌آورد حتی اگر آن را به شما ندهد؟ (اگر هم‌اکنون اسباب‌بازی را در دست شما می‌گذارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'وقتی به کودک خود لباس می‌پوشانید، آیا دستانش را با نزدیک شدن به حفره‌ی آستین در آن فرو می‌کند؟',
        'وقتی که دستتان را به جلو می‌برید و اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را در دست شما می‌گذارد؟'
    ],
    [   // 12 months questionair
        'آیا کودک شما دو صدای مشابه، مثل "با-با"، دا-دا" یا "گا-گا" را در می‌آورد؟ (نیازی نیست که صداها حتما معنایی داشته باشند)',
        'اگر از کودک بخواهید، آیا حداقل یک بازی کودکانه را حتی بدون اینکه شما آن را خودتان انجام دهید، انجام می‌دهد؟ (مثل "بای-بای"، "دالی"، دست دستی"، "هورررا")',
        'آیا کودک شما یک دستور ساده مثل "بیا اینجا"، "بده به من" یا "بذار سر جاش" را بدون کمک اشاره‌ای انجام می‌دهد؟',
        'آیا کودک شما سه لغا مانند "ماما"، "دادا" و "بابا" را می‌گوید؟ (یک "لغت" صدا یا صداهایی است که کودک شما به طور پشت سر هم به کار می‌برد تا مفهوم کسی یا چیزی را برساند)',
        'وقتی می‌پرسید "توپ کجاست؟ (یا کلاه یا کفش یا ...)" آیا کودک شما به آن شیء نگاه می‌کند؟ (مطمئن شوید آن شیء حضور دارد. اگر آن شیء را می‌شناسد گزینه "بله" را بزنید)',
        'وقتی کودک شما چیزی را می‌خواهد، آیا با اشاره به آن این موضوع را به شما می‌گوید؟',
        'در حالی که که مبلمان را نگه داشته است، آیا کودک شما خم می‌شود و یک اسباب‌بازی را از روی زمین بردارد و سپس به حالت ایستاده بازگردد؟',
        'وقتی مبلمان را نگه داشته است، آیا می‌تواند خودش را به صورت کنترل شده خم کند؟ (بدون افتادن یا تلو تلو خوردن)',
        'آیا کودک شما می‌تواند در کنار مبلمان راه برود در حالی که تنها یک دستش را به آن نگه داشته؟',
        'اگر دو دست کودک را برای حفظ تعادلش بگیرید، آیا می‌تواند چند قدم را بدون تلو خوردن یا افتادن بردارد؟ (اگر کودک شما هم‌اکنون به تنهایی می‌تواند راه برود، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'وقتی که یک دست کودک را جهت حفظ تعادلش می‌گیرید، آیا او می‌تواند چند قدم به جلو برود؟ (اگر کودک شما هم‌اکنون به تنهایی می‌تواند راه برود، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما خودش به تنهایی روی زمین از جایش بلند می‌شود و چند قدم به جلو برمی‌دارد؟',
        'پس از یک یا دو تلاش، آیا کودک شما یک تکه از نخ را می‌تواند با انگشت سبابه و شستش بردارد؟ (ممکن است این نخ متصل به یک اسباب‌بازی باشد)',
        'آیا کودک شما یک خوراکی کوچک یا خرده نان را با نوک شست و سایر انگشتانش برمی‌دارد؟ ممکن است به هنگام این کار کتف یا دستش را روی میز تکیه دهد.',
        'آیا کودک شما یک اسباب‌بازی کوچک را زمین می‌گذارد، بدون آن که آن را بیاندازد و سپس اسباب‌بازی را روی زمین رها کند؟',
        'بدون تکیه دادن کتف یا دستش به روی میز، آیا کودک شما یک خوراکی کوچک یا خرده نان را با دو انگشت شست و اشاره‌اش برمی‌دارد؟',
        'آیا کودک شما یک توپ کوچک را با یک حرکت رو به جلوی دست پرت می‌کند؟ (اگر در این هنگام توپ از دستش میفتد، گزینه "نه هنوز" را برای این آیتم انتخاب کنید)',
        'آیا کودک شما به شما کمک می‌کند صفحات کتاب را ورق بزنید؟ (ممکن است یک صفحه را برایش بلند کنید تا در دستش بگیرد)',
        'در حالی که یک اسباب‌بازی کوچک در هر یک از دستانش است، آیا کودک شما اسباب‌بازی‌ها را به هم می‌کوبد؟ (مثل دست زدن)',
        'آیا کودک شما انگشتش را داخل بطری که داخل آن یک خوراکی یا خرده نان است فرو می‌کند یا تلاش می‌کند آن را به دست آورد؟ (مثل بطری نوشابه یا بطری نوشیدنی کودک)',
        'بعد از اینکه ببیند شما یک اسباب‌بازی کوچک را زیر یک تکه کاغذ یا لباس قایم کردید، آیا کودک شما آن را پیدا می‌کند؟ (مطمئن شوید که اسباب‌بازی کاملا نامعلوم است)',
        'اگر شما یک اسباب‌بازی کوچک را درون یک کاسه یا جعبه بگذارید، آیا کودک کار شما را با قرار دادن یک اسباب‌بازی درون آن تقلید می‌کند، حتی اگر آن را درون آن رها نکند؟ (اگر هم‌اکنون اسباب‌بازی را درون کاسه یا جعبه رها می‌کند، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'اگر یک کاغذ را به صورت رفت و برگشتی با مدادرنگی (یا مداد یا خودکار) خط خطی کنید، آیا کودک کار شما را با خط خطی کردن تقلید می‌کند؟ (اگر هم‌اکنون خودش به تنهایی می‌تواند خط خطی کردن را انجام دهد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'وقتی که دستتان را می‌برید جلو و از کودک اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را به سمت شما می‌آورد حتی اگر آن را به شما ندهد؟ (اگر هم‌اکنون اسباب‌بازی را در دست شما می‌گذارد، گزینه "بله" را برای این آیتم انتخاب کنید)',
        'وقتی به کودک خود لباس می‌پوشانید، آیا دستانش را با نزدیک شدن به حفره‌ی آستین در آن فرو می‌کند؟',
        'وقتی که دستتان را به جلو می‌برید و اسباب‌بازی‌اش را می‌خواهید، آیا کودک شما آن را در دست شما می‌گذارد؟',
        'وقتی لباس تن کودکتان می‌کنید، آیا پاهایش را برای پوشیدن کفش، جوراب یا شلوار بلند می‌کند؟',
        'آیا کودک شما یک توپ را به سمت شما پرت می‌کند یا قل می‌دهد تا شما آن را مجدد به سمت او بیاندازید؟',
        'آیا کودک شما با عروسک یا حیوانات اسباب‌بازی از طریق آویزان کردنشان بازی می‌کند؟'
    ]
]
module.exports = [
    "کودک شما هنوز حرف نمی‌زند؟ یا به شما نگاه نمی‌کند؟",
    'سنین زیر سه سال دوران طلایی رشد کودک به حساب می‌آیند و مغز کودک هنوز در حال شکل‌گیری است. با انجام دقیق‌ترین تست غربالگری اتیسم، می‌توانید از بروز بسیاری از اختلالات رشدی کودک جلوگیری کنید.',
    'شروع تست',
    '۹۷%',
    'دقت غربالگری',
    "در سنین پایین",
    '۸۱۰',
    'نفر تا کنون این تست را',
    'انجام داده‌اند',
    'همراهان مهروبو',
]
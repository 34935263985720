module.exports = [
    'Mehrobo',
    'About us',
    'Login',
    'Products',
    'autism screening',
    'music therapy',
    'ai clinic',
    'EN',
    'فا'
]
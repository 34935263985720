import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import moment from 'moment-jalaali'
import './style.css'
import '../../videocall/modal-vidobo/index.css'
import { Area, LineChart, linearGradient, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart } from 'recharts';

import gars3Banner from '../../../../../assets/SarbargGars3.png'
import ReactToPrint from 'react-to-print'
import { textAlign } from '@mui/system'

const faNumbers = require('../../../../../utils/numbersFa.js')

const data = [
    {
      date: '۰۹/۰۹',
      corrects: 5,
      wrongs: 1,
      level: '۲',
    },
    {
      date: '۰۹/۰۹',
      corrects: 5,
      wrongs: 1,
      level: '۳',
    }
];

function ChartModalGars3({ evaluation, clinicName, testStats, closeModal }) {
    
    console.log("Evaluation data is:", evaluation);
    console.log("Evaluation data 0 is:", evaluation[0].EndDate);

    const [firstName, setFirstName] = useState(testStats.firstname)
    const [lastName, setLastName] = useState(testStats.lastname)
    const [birthResult, setBirthResult] = useState(testStats.birthdate)
    const [firstDate, setFirstDate] = useState(null)
    const [clinic, setClinic] = useState(clinicName)
    const [gars3Data, setGars3Data] = useState([])
    const farsiNumbers = faNumbers

    const componentRef = useRef(null);
    
    useEffect(() => {
        if (gars3Data.length > 0) {
            return
        }
        
        for (let i=0; i<evaluation.length; i++) {
            if (evaluation[i].Session == "GARS3") {
                gars3Data.push({date: new Date(evaluation[i].EndDate).toLocaleDateString('fa-IR'),
                                index: evaluation[i].Index})
            }
        }

        setFirstDate(gars3Data[0].date)

    },[])

    return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print font-12' ref={componentRef}>
                    <div className='result-header mode-bg-4'>
                        <img style={{width:'100%'}} className='gars3-img' src={gars3Banner}></img>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۱. مشخصات فردی</div>
                    </div>
                    <div className='result-identity mode-text-9'>
                        <div className='result-identity-right' style={{marginLeft: '20px', marginRight: '20px'}}>
                            <p>نام کودک: {firstName} {lastName}</p>
                            <p> نام موسسه: {clinic}</p>
                        </div>
                        <div className='result-identity-left'>
                            <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                            <p>تاریخ اولین آزمون: {firstDate}</p>
                        </div>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۲. نمودار گارس۳</div>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"در نمودار زیر، نمره‌ی مقیاس اتیسم کودک در طول زمان را می‌توانید مشاهده کنید:"}</p>
                    </div>
                    <div className='w-90 d-flex flex-column' style={{marginTop: '0', marginBottom: '0', marginLeft: '20px', marginRight: '20px'}}>
                        <div className='d-flex mb-2'>
                            <div className='chart-data panel-card'>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                    width={100}
                                    height={100}
                                    data={gars3Data}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        left: -20,
                                        bottom: 10,
                                    }}
                                    >
                                      <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="5%" stopColor="#DD0B86" stopOpacity={0.8}/>
                                          <stop offset="95%" stopColor="#DD0B86" stopOpacity={0}/>
                                        </linearGradient>
                                      </defs>
                                      <XAxis dataKey="date" />
                                      <CartesianGrid strokeDasharray="3 3" />
                                      {/* <XAxis xAxisId="0" dataKey="level" /> */}
                                      {/* <XAxis xAxisId="1" dataKey="date" allowDuplicatedCategory={false} /> */}
                                      {/* <YAxis label={{ value: 'پیشرفت کودک: ریتم‌ها', angle: -90, position: 'insideMiddle' }} /> */}
                                      <YAxis dataKey="index" allowDecimals={false} domain={[100, 174]} />
                                      <Tooltip />
                                      {/* <Legend /> */}
                                      {/* <Line type="monotone" dataKey="level" stroke="#5bcdbe" activeDot={{ r: 8 }} /> */}
                                      {/* <Line type="monotone" dataKey="corrects" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                      <Line type="monotone" dataKey="wrongs" stroke="#f9a597" /> */}
                                      <Area type="monotone" dataKey="index" stroke="#DD0B86" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"در این نمودار، هر چه شیب نزولی‌تر بیشتر باشد، شدت نشانگان اتیسم در کودک کاهش بیشتری داشته است."}</p>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۳. نظر آزمونگر</div>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"-   "}</p>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"-   "}</p>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"-   "}</p>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"-   "}</p>
                    </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج موزیکوبو۱'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ChartModalGars3
import React, { useEffect, useState } from 'react'
import './style.css'
import axios from 'axios'
import ResultModalGars3 from './modal-resultsgars3'
import ResultModalGars2 from './modal-resultsgars2'
import ResultModalScobo from './modal-resultsscobo'
import ResultModalAsq3 from './modal-resultsasq3'
import { AiOutlineLineChart } from 'react-icons/ai'
import ChartModalGars3 from './modal-chartgars3'

const Results = () => {
    const [pageMessage, setPageMessage] = useState("Hello,I'mInResultsPage")
    const [isLogin, setIsLogin] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)
    const [testResults, setTestResults] = useState(null)
    const [selectedTestStats, setSelectedTestStats] = useState(null)
    const [testStats, setTestStats] = useState(null)
    const [showModalScobo, setShowModalScobo] = useState(false)
    const [showModalGars3, setShowModalGars3] = useState(false)
    const [showModalGars2, setShowModalGars2] = useState(false)
    const [showModalAsq3, setShowModalAsq3] = useState(false)
    const [showModalGars3Chart, setShowModalGars3Chart] = useState(false)
    const [userId, setUserId] = useState(null)
    const [selectedEvaluation, setSelectedEvaluation] = useState(null)
    const [clinicName, setClinicName] = useState('')

    useEffect (() => {
        handleRedirect();
        loginCheck();

        const search = window.location.search;
        const params = new URLSearchParams(search);
        const receivedId = params.get('id');
        console.log("received id in this page is:", receivedId);
        setUserId(parseInt(receivedId))

        loadUserData(receivedId);
        
    }, [])

    const handleRedirect = async() => {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: pageMessage})
    }

    const loginCheck = async() => {
        const response = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        if (response.data.message == "Yo, You are authenticated!") {
            setIsLogin(true)
            setClinicName(response.data.clinic)
        } else {
            setIsLogin(false);
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
    }

    const loadUserData = async(id) => {
        const response = await axios.post('https://request.sweetobot.com/evaluation/results', {requestId: id}, {headers: {'x-access-token': localStorage.getItem('token')}})
        console.log("Received Data:", response.data.results);
        setTestResults(response.data.results.evaluations)
        setTestStats(response.data.results)
        if (response.data.message == "Yo, You are authenticated!") {
            setIsLoaded(true)
        } else {
            setIsLoaded(false);
            window.location.href = 'https://www.mehrobo.com/fa/panel/evaluations'
        }
    }

    const activateModalChart = (testName) => {
        if (testName == 'GARS3')
            setShowModalGars3Chart(true)
    }

    const deactivateModalChart = (testName) => {
        if (testName == 'GARS3')
            setShowModalGars3Chart(false)
    }

    const activateModal = (testId) => {
        setSelectedTestStats(testStats)
        setSelectedEvaluation(testResults[testId])
        if (testResults[testId].Session == 'SCOBO')
            setShowModalScobo(true)
        if (testResults[testId].Session == 'GARS2')
            setShowModalGars2(true)
        if (testResults[testId].Session == 'GARS3')
            setShowModalGars3(true)
        if (testResults[testId].Session == 'ASQ3')
            setShowModalAsq3(true)
    }

    const diactivateModal = (testId) => {
        setShowModalScobo(false)
        setShowModalGars2(false)
        setShowModalGars3(false)
        setShowModalAsq3(false)
    }

    if (isLogin == false || isLoaded == false) {
        return(
            <div className="container-fluid">

            </div>
        )
    }
    
    return(
        <div className="container-fluid">
            <div className='row no-gutters'>
                <div className='col-12'>
                    <div className='d-flex mb-3 align-items-center'>
                        <div className='d-flex flex-column ml-2'>
                            <p className='d-flex mb-2 align-items-center'>
                                <span className='font-medium font-18 mode-text-2'>لیست کاربران کلینیک</span>
                            </p>
                        </div>
                    </div>
                    <div className='test-list mt-1 d-flex flex-column'  style={{justifyContent:'center' , alignContent:'center'}}>
                    {showModalGars3Chart &&
                    <ChartModalGars3
                        evaluation={testResults}
                        testStats={testStats}
                        clinicName={clinicName}
                        closeModal={(modalBool) => deactivateModalChart("GARS3")} 
                    />}
                    {showModalScobo &&
                    <ResultModalScobo
                        evaluation={selectedEvaluation}
                        testStats={selectedTestStats}
                        clinicName={clinicName}
                        closeModal={(modalBool) => diactivateModal(modalBool)} 
                    />}

                    {showModalGars2 &&
                    <ResultModalGars2
                        evaluation={selectedEvaluation}
                        testStats={selectedTestStats}
                        clinicName={clinicName}
                        closeModal={(modalBool) => diactivateModal(modalBool)} 
                    />}

                    {showModalGars3 &&
                    <ResultModalGars3
                        evaluation={selectedEvaluation}
                        testStats={selectedTestStats}
                        clinicName={clinicName}
                        closeModal={(modalBool) => diactivateModal(modalBool)} 
                    />}
                    {showModalAsq3 &&
                    <ResultModalAsq3
                        evaluation={selectedEvaluation}
                        testStats={selectedTestStats}
                        clinicName={clinicName}
                        closeModal={(modalBool) => diactivateModal(modalBool)} 
                    />}
                    <div className='boxes my-4 mb-4' style={{gridGap: '0'}}>
                        <a className='box d-flex flex-column align-items-center justify-content-center' onClick={() => activateModalChart("GARS3")}>
                            <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{background: '#fee4f3'}}>
                                <AiOutlineLineChart style={{color: '#DD0B86', fontSize: '28px'}}> + </AiOutlineLineChart>
                            </div>
                            <span className='font-14 mt-4 mode-text-7'>نمودار گارس ۳</span>
                        </a>
                    </div>
                        <div className='panel mode-bg-8 mb-4'>
                            <div className='d-flex flex-column w-100'>
                                <div className='test-title-wrapper d-flex justify-content-between align-items-center pr-4 pl-4'>
                                    <p className='font-14 mode-text-4'>
                                        لیست تست‌های انجام شده
                                    </p>
                                </div>
                                <div className='test-table-wrapper'>
                                    <div className='test-table'>
                                        <div className='table-head'>
                                            <div className='test-table-header d-flex align-items-center justify-content-between mode-bg-5 pr-4 pl-4'>
                                                <p className='mode-text-9 w-15 '>ردیف</p>
                                                <p className='mode-text-9 w-15'>تاریخ</p>
                                                <p className='mode-text-9 w-15 '>تست</p>
                                                <p className='mode-text-9 w-15 d-none-mobile'>نمره درصدی</p>
                                                <p className='mode-text-9 w-15 '>جزئیات</p>
                                            </div>
                                        </div>
                                        {testResults.map((testResult, i) => (
                                        <div className='table-row' key={i}>
                                            <a className='test-table-row w-100 d-flex align-items-center justify-content-between mode-bg-9 pr-4 pl-4'>
                                                <p className='mode-text-9 w-15'>{i}</p>
                                                <p className='mode-text-9 w-15'>{new Date(testResult.EndDate).toLocaleDateString('fa-IR')}</p>
                                                <p className='mode-text-9 w-15'>{testResult.Session}</p>
                                                <p className='mode-text-9 w-15 d-none-mobile'>{testResult.Score}</p>
                                                <a className='btn-default mode-bg-2' onClick={() => activateModal(i)}>مشاهده</a>
                                            </a>
                                        </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Results
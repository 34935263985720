module.exports = [
    'آیا کودک از توانایی گفتار در حد ساختن جملات و انجام گفتگو برخوردار است؟',
    'از برقراری تماس چشمی اجتناب می‌کند؛ وقتی با او تماس چشمی برقرار می‌شود به سمت دیگری نگاه می‌کند',
    'به دستانش، اشیاء و یا چیزهایی که در محیط هستند حداقل برای ۵ ثانیه خیره می‌شود',
    'انگشتانش را به مدت ۵ ثانیه یا بیشتر به سرعت جلوی چشمانش تکان می‌دهد',
    'غذاهای به خصوصی را می‌خورد و از خوردن غذاهایی که به طور معمول اکثر افراد می‌خورند اجتناب می‌کند',
    'اشیاء غیر قابل خوردن را تلاش می‌کند بخورد، بچشد یا لیس بزند (برای مثال دستانش، اسباب‌بازی‌هایش یا کتاب‌هایش)',
    'اشیاء را بو می‌کند (مثل اسباب‌بازی، مو و ...)',
    'دور خودش می‌چرخد یا به ترتیب و ریتم خاصی حرکت می‌کند',
    'اشیائی را که برای چرخیدن نیست، می‌چرخاند (مثل نعلبکی، فنجان و ...)',
    'وقتی نشسته است، به عقب و جلو تکان می‌خورد',
    'حرکت ناگهانی و یا از جا کنده شدن دارد',
    'روی پنجه‌ی پا حرکت می‌کند',
    'حرکت دست به صورت بال بال زدن دارن',
    'صداهای بلند و تکراری برای تحریک خود دارد',
    'به خودش سیلی می‌زند، خودش را گاز می‌گیرد یا به گونه دیگر به خودش آسیب می‌رساند',
    'کلمات را تکرار می‌کند',
    'کلمات را به صورت نادرست به کار می‌برد',
    'کلمات یا عباراتی را بیش از حد تکرار می‌کند',
    'با یکنواختی عاطفی و بدون ریتم صحبت می‌کند',
    'به دستورات ساده به طور نامناسب پاسخ می‌دهد',
    'وقتی اسمش را صدا می‌کنی به جای دیگری نگاه می‌کند یا از نگاه کردن به گوینده اجتناب می‌کند',
    'از درخواست چیزهایی که می‌خواهد اجتناب می‌کند',
    'توانایی شروع صحبت با دیگران را ندارد',
    'بله و خیر را به طور نادرست به کار می‌برد',
    'ضمیرها را به طور نامناسب به کار می‌برد',
    'ضمیر من را نامناسب به کار می‌برد',
    'صداهایی غیر قابل فهم را بیش از حد تکرار می‌کند',
    'به جای استفاده از زبان از حرکات و علائم بدنی برای به دست آوردن اشیاء استفاده می‌کند',
    'پاسخ‌های نامناسب به سوالاتی در مورد یک عبارت یا داستان کوتاه می‌دهد',
    'از نماس چشمی پرهیز می‌کند (وقتی یک نفر به او نگاه می‌کند)',
    'پاسخگویی نامناسب در برابر محبت و تشویق دارد',
    'از تماس فیزیکی و در بغل گرفته شدن حذر می‌کند',
    'از کارها و بازی دیگران تقلید نمی‌کند',
    'میل به تنهایی دارد',
    'به طور غیر منطقی‌ای هراسان و با ترس رفتار می‌کند ',
    'بی‌احساس به نظر می‌رسد، پاسخ‌های احساسی مناسبی ارائه نمی‌ده (برای مثال، به هنگام بغل کردن یا بوسیدن)',
    'هیچ عکس‌العملی نسبت به حضور کسی ندارد (برای مثال، به فرد نگاه کند)',
    'به گونه‌ای نامناسب قهقهه می‌زند، می‌خندد یا گریه می‌کند',
    'اسباب‌بازی‌ها یا اشیاء را به گونه‌ای نادرست استفاده می کند (مثلا ماشین‌های اسباب‌بازی را می‌چرخاند، بخش‌های مختلف اسباب‌بازی‌ها را از هم جدا می‌کند)',
    'کارهای معینی را به صورت پشت سر هم تکرار می‌کند',
    'اگر در برنامه‌های روزانه یا ترتیب آن تغییری ایجاد شود، ناراحت می‌شود',
    'وقتی به او دستوری می‌دهیم یا می‌خواهیم کاری را انجام دهد پاسخ منفی می‌دهد',
    'اشیا را به دقت و با ترتیب خاصی می‌چیند و اگر این ترتیب به هم بخورد ناراحت می‌شود',
]
import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment-jalaali'
import './index.css'
import '../../videocall/modal-vidobo/index.css'

function SettingModal({ closeModal, musicoboData, audienceId }) {

    const [playerLevel, setPlayerLevel] = useState(musicoboData.Level)
    
    const submitMusicoboData = async() => {
        const result = await axios.post('https://request.sweetobot.com/musicobo/setauddata',
                                        {
                                            level: playerLevel,
                                            audId: audienceId
                                        },
                                        {
                                            headers: {'x-access-token': localStorage.getItem('token')}
                                        }
        )
        if (result.data.status == 1) {
            musicoboData.Level = playerLevel
            closeModal(false)
        } else {

        }
    }
    
    const SetWorldOfInstruments = (e) => {
        var playerLevelTemp = playerLevel;
        playerLevelTemp[0] = parseInt(e.target.value);
        setPlayerLevel(playerLevelTemp);
    }

    const SetWorldOfRhythms = (e) => {
        var playerLevelTemp = playerLevel;
        playerLevelTemp[1] = parseInt(e.target.value);
        setPlayerLevel(playerLevelTemp);
    }

    return (
        <div className='modal-background'>
            <div className='modal-container'>
                <div className='modal-title'>
                    پنل تنظیمات
                </div>
                <div className='users-modal-title mode-text-7'>
                در اینجا می‌توانید با وارد کردن مشخصات خواسته شده، بازی را برای کاربر خود تنظیم کنید.
                </div>
                <div className='w-90'>
                    <div className='d-flex mb-2 mode-text-9 font-15 '> 
                        تنظیمات بازی 
                    </div>
                    <div className='d-flex mb-2 '>
                        {/* <div className='d-flex w-50'>
                            <input type='text' placeholder='نام' className='users-input w-100' onChange={(e) => setFirstName(e.target.value)}></input>
                        </div> */}
                        <div className='d-flex w-50 mt-2 mr-3 mb-2 mode-text-7'>
                            مرحله بازیکن: سازها
                        </div>
                        {/* <div className='d-flex w-50'>
                            <input type='text' placeholder='نام خانوادگی' className='users-input w-100' onChange={(e) => setLastName(e.target.value)}></input>
                        </div> */}
                        <select className='users-input' onChange={(e) => SetWorldOfInstruments(e)}>
                            <option value={0} selected={playerLevel[0] == 0}>قفل</option>
                            <option value={1} selected={playerLevel[0] == 1}>۱</option>
                            <option value={2} selected={playerLevel[0] == 2}>۲</option>
                            <option value={3} selected={playerLevel[0] == 3}>۳</option>
                            <option value={4} selected={playerLevel[0] == 4}>۴</option>
                            <option value={5} selected={playerLevel[0] == 5}>۵</option>
                            <option value={6} selected={playerLevel[0] == 6}>۶</option>
                            <option value={7} selected={playerLevel[0] == 7}>۷</option>
                            <option value={8} selected={playerLevel[0] == 8}>۸</option>
                            <option value={9} selected={playerLevel[0] == 9}>۹</option>
                            <option value={10} selected={playerLevel[0] == 10}>۱۰</option>
                            <option value={11} selected={playerLevel[0] == 11}>۱۱</option>
                            <option value={12} selected={playerLevel[0] == 12}>۱۲</option>
                        </select>
                    </div>
                    <div className='d-flex mb-2'>
                        {/* <div className='d-flex w-50'>
                            <input type='number' placeholder='شماره موبایل' className='users-input w-100' onChange={(e) => setMobileNumber(e.target.value)}></input>
                        </div> */}
                        <div className='d-flex w-50 mt-2 mr-3 mb-2 mode-text-7'>
                            مرحله بازیکن: ریتم‌ها
                        </div>
                        {/* <div className='d-flex w-50'>
                            <input type='number' placeholder='شماره منزل' className='users-input w-100' onChange={(e) => setHomeNumber(e.target.value)}></input>
                        </div> */}
                        <select className='users-input' onChange={(e) => SetWorldOfRhythms(e)}>
                            <option value={0} selected={playerLevel[1] == 0}>قفل</option>
                            <option value={1} selected={playerLevel[1] == 1}>۱</option>
                            <option value={2} selected={playerLevel[1] == 2}>۲</option>
							<option value={3} selected={playerLevel[1] == 3}>۳</option>
							<option value={4} selected={playerLevel[1] == 4}>۴</option>
                            <option value={5} selected={playerLevel[1] == 5}>۵</option>
                            <option value={6} selected={playerLevel[1] == 6}>۶</option>
                            <option value={7} selected={playerLevel[1] == 7}>۷</option>
                            <option value={8} selected={playerLevel[1] == 8}>۸</option>
                            <option value={9} selected={playerLevel[1] == 9}>۹</option>
                            <option value={10} selected={playerLevel[1] == 10}>۱۰</option>
                            <option value={11} selected={playerLevel[1] == 11}>۱۱</option>
                            <option value={12} selected={playerLevel[1] == 12}>۱۲</option>
                        </select>
                    </div>
                </div>
                <div className='modal-footer mode-bg-8'>
                    <button onClick={() => closeModal(false)}>
                        انصراف
                    </button>
                    <button onClick={submitMusicoboData}>
                        تایید
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SettingModal
import React, { useState, useRef, useEffect } from 'react'
import './index.css'
import ReactToPrint from 'react-to-print'
import garsIcon from '../../../../../assets/SarbargGars3.png'

const faScoboQuestions = require('../../../../../utils/scoboTestQuestionsFa.js')
const faGars2Questions = require('../../../../../utils/gars2TestQuestionsFa.js')
const faGars3Questions = require('../../../../../utils/gars3TestQuestionsFa.js')
const faNumbers = require('../../../../../utils/numbersFa.js')

function ResultModalGars3({ evaluation, closeModal, testStats, clinicName }) {

    const [testName, setTestName] = useState(evaluation.Session)
    const [testIndex, setTestIndex] = useState(evaluation.Index)
    const [testAnswer, setTestAnswer] = useState(evaluation.Answers)
    const [testQuestion, setTestQuestion] = useState(evaluation.Questions)
    const [testSubscales, setTestSubscales] = useState(evaluation.Subscales)
    const [testPercentiles, setTestPercentiles] = useState(evaluation.Percentiles)
    const [testStartDate, setTestStartDate] = useState(evaluation.StartDate)
    const [examinerName, setExaminerName] = useState(evaluation.Examiner)
    const [resultAds, setResultAds] = useState(evaluation.Result)
    const [score, setScore] = useState(evaluation.Score)
    const [name, setName] = useState(testStats.firstname)
    const [lName, setLName] = useState(testStats.lastname)
    const [birthResult, setBirthResult] = useState(testStats.birthdate)
    const [clinic, setClinic] = useState(clinicName)
    const [adsResults, setAdsResults] = useState([])
    const [age, setAge] = useState(0)
    const farsiNumbers = faNumbers

    const userSubscaleText = 
    [
        "رفتارهای کلیشه‌ای و تکراری",
        "تعاملات اجتماعی",
        "ارتباطات اجتماعی",
        "پاسخ‌های هیجانی",
        "چارچوب شناختی",
        "گفتار ناهنجار",
    ]
    const userSubscaleSixOrFour =
    [
        "شاخص اتیسم (۶ خرده مقیاس)",
        "شاخص اتیسم (۴ خرده مقیاس)"
    ]
	if (testSubscales.length == 6) {
        userSubscaleSixOrFour.splice(1,1)
    } else {
        userSubscaleText.splice(4,2)
        userSubscaleSixOrFour.splice(0,1)
    }

    const resultAdsText = 
    [
        "احتمال ابتلا به ASD",
        "سطح شدت ASD بر اساس DSM5",
        "توصیف"
    ]

    const componentRef = useRef(null);

    const resultIdentifier = () => {
        if (resultAds==1){
            setAdsResults(
                ["وجود ندارد",
                "",
                "عدم ابتلا به ASD"]
            )
        }
        else if (resultAds==2){
            setAdsResults(
                ["تا حدی احتمال دارد",
                "سطح ۱",
                "حمایت کم"]
            )
        }
        else if (resultAds==3){
            setAdsResults(
                ["به احتمال خیلی زیاد",
                "سطح ۲",
                "حمایت قابل توجه"]
            )
        }       
        else if (resultAds==4){
            setAdsResults(
                ["به احتمال خیلی زیاد",
                "سطح ۳",
                "حمایت خیلی زیاد"]
            )
        }
    }

    const ageCalculator = () => {
        var tavalod = new Date(birthResult)
        var date = new Date()
        var y1 = tavalod.getFullYear()
        var y2 = date.getFullYear()
        setAge(y2 - y1)
    }

    useEffect (() => {
        console.log("received id in this page is:", evaluation);
        resultIdentifier()
        ageCalculator()
    }, [])

    var userQuestion = []
    if (testName == 'SCOBO') userQuestion = faScoboQuestions
    if (testName == 'GARS2') userQuestion = faGars2Questions
    if (testName == 'GARS3') userQuestion = faGars3Questions

        return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print' ref={componentRef}>
                    <div className='result-header mode-bg-4'>
                        <img style={{width:'100%'}} className='gars3-img' src={garsIcon}></img>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۱. مشخصات فردی</div>
                    </div>
                    <div className='result-identity mode-text-9'>
                        <div className='result-identity-right'>
                            <p>نام و نام خانوادگی: {name} {lName}</p>
                            <p>تاریخ ثبت فرم: {new Date(testStartDate).toLocaleDateString('fa-IR')}</p>
                            <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                            <p>سن: {farsiNumbers[age]}</p>
                        </div>
                        <div className='result-identity-left'>
                            <p> نام موسسه یا کلینیک: {clinic}</p>
                            <p> نام آزمونگر: {examinerName}</p>
                            <p> نسبت فردی که تست را تکمیل نموده:</p>
                        </div>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۲. عملکرد خرده‌سنج‌ها</div>
                    </div>
                    <div className='result-table' style={{marginBottom: '0', marginTop: '0', borderStyle: 'none'}}>
                        <div className='table-result-items'>
                            <p className='result-item-title' ></p>
                            <p className='result-item-stats mode-text-13 w-35'>خرده مقیاس‌ها: </p>
                            <p className='result-item-stats mode-text-13 w-35'>درصد شباهت با اتیسم: </p>
                            <p className='result-item-stats mode-text-13 w-35'>خطای استاندارد: </p>
                        </div>
                    </div>
                    <div style={{marginTop: '5px'}} className='result-table'>
                    {testSubscales.map((subCount, i) => (
                        <div className='table-result-items' key={i}>
                            <p className='result-item-title' >{userSubscaleText[i]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{farsiNumbers[subCount]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{farsiNumbers[testPercentiles[i]]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{farsiNumbers[1]}</p>
                        </div>
                    ))}
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۳. عملکرد مجموع</div>
                    </div>
                    <div className='result-table' style={{marginBottom: '0', marginTop: '0', borderStyle: 'none'}}>
                        <div className='table-result-items'>
                            <p className='result-item-title' ></p>
                            <p className='result-item-stats mode-text-13 w-35'>شاخص اتیسم</p>
                            <p className='result-item-stats mode-text-13 w-35'>درصد شباهت</p>
                            <p className='result-item-stats mode-text-13 w-35'>احتمال اتیسم</p>
                        </div>
                    </div>
                    <div style={{marginTop: '5px'}} className='result-table'>
                        <div className='table-result-items'>
                            <p className='result-item-title' >{userSubscaleSixOrFour[0]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{farsiNumbers[testIndex]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{farsiNumbers[score]}</p>
                            <p className='result-item-stats mode-text-13 w-35'>{adsResults[0]}</p>
                        </div>
                    </div>
                    <div className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۴. نتیجه‌گیری</div>
                    </div>
                    <div style={{marginTop: '5px'}} className='result-table'>
                    {adsResults.map((count, i) => (
                        <div className='table-result-items' key={i}>                   
                            <p className='result-item-title' >{resultAdsText[i]}</p>
                            <p style={{marginLeft: '70px'}} className='result-item-stats mode-text-13 w-35'>{count}</p>
                        </div>
                    ))}
                    </div>
                    <div className='result-table'>
                        <div style={{marginTop: '10px', marginBottom: '10px'}} className='result-header mode-text-13'>
                            <div className='result-modal-title'> سوالات: </div>
                        </div>
                        {testQuestion.map((queCount, i) => (
                        <div className='table-result-items' >
                            <p className='result-item-stats'>{queCount}</p>
                            <p className='result-item-title font-12'>{userQuestion[i]}</p>
                            {testAnswer[i] === 0 ?
                            <p className='result-item-stats'>{"-"}</p>
                            :
                            <p className='result-item-stats'>{testAnswer[i]-1}</p>
                            }
                        </div>
                        ))}
                    </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint 
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج تست گارس ۳'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>

            </div>
            
        </div>
    )
}

export default ResultModalGars3
import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment-jalaali'
import './style.css'
import '../../videocall/modal-vidobo/index.css'
import { useEffect } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

function EditPersonalInfoModal({ closeModal, selectedUser }) {

    
    const [firstName, setFirstName] = useState(selectedUser.firstname)
    const [lastName, setLastName] = useState(selectedUser.lastname)
    const [mobileNumber, setMobileNumber] = useState(selectedUser.phonenumber)
    const [homeNumber, setHomeNumber] = useState(selectedUser.homenumber)
    const [userName, setUserName] = useState(selectedUser.username)
    const [nationalId, setNationalId] = useState(selectedUser.nationalid)
    const [passWord, setPassWord] = useState(selectedUser.password)
    const [passWordCheck, setPassWordCheck] = useState(selectedUser.password)
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordCheck, setShowPasswordCheck] = useState(false)
    const [userAccess, setUserAccess] = useState(selectedUser.access)
    const [birthDay, setBirthDay] = useState(moment(selectedUser.birthdate,'YYYY-M-D HH:mm:ss').format('jD'))
    const [birthMonth, setBirthMonth] = useState(moment(selectedUser.birthdate,'YYYY-M-D HH:mm:ss').format('jM'))
    const [birthYear, setBirthYear] = useState(moment(selectedUser.birthdate,'YYYY-M-D HH:mm:ss').format('jYYYY'))
    const [addUserError, setAddUserError] = useState(false)
    const [messageError, setMessageError] = useState('')

    const submitEditUser = async() => {
        var birthDate = ''
        if (firstName == '' || lastName == '' || userName == '' || passWord == '' || passWordCheck == '' || mobileNumber == '' ||
            birthDay == 0 || birthMonth == 0 || birthYear == 0) {
            setAddUserError(true)
            setMessageError('لطفا تمامی مقادیر الزامی را وارد کنید')
            return
        } else {
            birthDate = moment(birthYear.toString()+'/'+birthMonth.toString()+'/'+birthDay.toString()+' 00:00:00', 'jYYYY/jM/jD HH:mm:ss').format('YYYY-M-D HH:mm:ss')
            setAddUserError(false)
        }

        if (passWord != passWordCheck) {
            setAddUserError(true)
            setMessageError('لطفا برابری مقادیر رمز عبور را چک کنید')
            return
        } else {
            setAddUserError(false)
        }

        const result = await axios.post('https://request.sweetobot.com/user/edit',
                                        {
                                            editId: selectedUser.id,
                                            firstname: firstName,
                                            lastname: lastName,
                                            useraccess: userAccess,
                                            mobilenumber: mobileNumber,
                                            homenumber: homeNumber,
                                            username: userName,
                                            password: passWord,
                                            nationalid: nationalId,
                                            birthdate: birthDate
                                        },
                                        {
                                            headers: {'x-access-token': localStorage.getItem('token')}
                                        }
        )
        if (result.data.status == 1) {
            closeModal(false)
        } else {
            setAddUserError(true)
            setMessageError(result.data.message)
        }

    }

    return (
        <div className='modal-background'>
            <div className='modal-container'>
                <div className='modal-title'>
                    افزودن کاربر
                </div>
                <div className='users-modal-title mode-text-7'>
                در اینجا می‌توانید با وارد کردن مشخصات خواسته شده، کاربر مورد نظر خود را به کلینیک هوشمند اضافه کنید.
                </div>
                <div>
                    <div className='d-flex mb-2 mode-text-9 font-15 '> 
                        مشخصات فردی 
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-flex w-50'>
                            <input type='text' placeholder='نام' value={firstName} className='users-input w-100' onChange={(e) => setFirstName(e.target.value)}></input>
                        </div>
                        <div className='d-flex w-50'>
                            <input type='text' placeholder='نام خانوادگی' value={lastName} className='users-input w-100' onChange={(e) => setLastName(e.target.value)}></input>
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-flex w-50'>
                            <input type='number' placeholder='شماره موبایل' value={mobileNumber} className='users-input w-100' onChange={(e) => setMobileNumber(e.target.value)}></input>
                        </div>
                        <div className='d-flex w-50'>
                            <input type='number' placeholder='شماره منزل' value={homeNumber} className='users-input w-100' onChange={(e) => setHomeNumber(e.target.value)}></input>
                        </div>
                    </div>
                    <div className='d-flex mb-4'>
                        <div className='d-flex w-50'>
                            <input type='number' placeholder='کد ملی' value={nationalId} className='users-input w-100' onChange={(e) => setNationalId(e.target.value)}></input>
                        </div>
                        <div className='d-flex w-50'>
                            <select className='users-input' value={birthDay} onChange={(e) => setBirthDay(e.target.value)}>
                                <option value={0}>روز</option>
                                <option value={1}>۱</option>
                                <option value={2}>۲</option>
                                <option value={3}>۳</option>
                                <option value={4}>۴</option>
                                <option value={5}>۵</option>
                                <option value={6}>۶</option>
                                <option value={7}>۷</option>
                                <option value={8}>۸</option>
                                <option value={9}>۹</option>
                                <option value={10}>۱۰</option>
                                <option value={11}>۱۱</option>
                                <option value={12}>۱۲</option>
                                <option value={13}>۱۳</option>
                                <option value={14}>۱۴</option>
                                <option value={15}>۱۵</option>
                                <option value={16}>۱۶</option>
                                <option value={17}>۱۷</option>
                                <option value={18}>۱۸</option>
                                <option value={19}>۱۹</option>
                                <option value={20}>۲۰</option>
                                <option value={21}>۲۱</option>
                                <option value={22}>۲۲</option>
                                <option value={23}>۲۳</option>
                                <option value={24}>۲۴</option>
                                <option value={25}>۲۵</option>
                                <option value={26}>۲۶</option>
                                <option value={27}>۲۷</option>
                                <option value={28}>۲۸</option>
                                <option value={29}>۲۹</option>
                                <option value={30}>۳۰</option>
                            </select>
                            <select className='users-input' value={birthMonth} onChange={(e) => setBirthMonth(e.target.value)}>
                                <option value={0}>ماه</option>
                                <option value={1}>۱</option>
                                <option value={2}>۲</option>
                                <option value={3}>۳</option>
                                <option value={4}>۴</option>
                                <option value={5}>۵</option>
                                <option value={6}>۶</option>
                                <option value={7}>۷</option>
                                <option value={8}>۸</option>
                                <option value={9}>۹</option>
                                <option value={10}>۱۰</option>
                                <option value={11}>۱۱</option>
                                <option value={12}>۱۲</option>
                            </select>
                            <select className='users-input' value={birthYear} onChange={(e) => setBirthYear(e.target.value)}>
                                <option value={0}>سال</option>
                                <option value={1401}>۱۴۰۱</option>
                                <option value={1400}>۱۴۰۰</option>
                                <option value={1399}>۱۳۹۹</option>
                                <option value={1398}>۱۳۹۸</option>
                                <option value={1397}>۱۳۹۷</option>
                                <option value={1396}>۱۳۹۶</option>
                                <option value={1395}>۱۳۹۵</option>
                                <option value={1394}>۱۳۹۴</option>
                                <option value={1393}>۱۳۹۳</option>
                                <option value={1392}>۱۳۹۲</option>
                                <option value={1391}>۱۳۹۱</option>
                                <option value={1390}>۱۳۹۰</option>
                                <option value={1389}>۱۳۸۹</option>
                                <option value={1388}>۱۳۸۸</option>
                                <option value={1387}>۱۳۸۷</option>
                                <option value={1386}>۱۳۸۶</option>
                                <option value={1385}>۱۳۸۵</option>
                                <option value={1384}>۱۳۸۴</option>
                                <option value={1383}>۱۳۸۳</option>
                                <option value={1382}>۱۳۸۲</option>
                                <option value={1381}>۱۳۸۱</option>
                                <option value={1380}>۱۳۸۰</option>
                                <option value={1379}>۱۳۷۹</option>
                                <option value={1378}>۱۳۷۸</option>
                                <option value={1377}>۱۳۷۷</option>
                                <option value={1376}>۱۳۷۶</option>
                                <option value={1375}>۱۳۷۵</option>
                                <option value={1374}>۱۳۷۴</option>
                                <option value={1373}>۱۳۷۳</option>
                                <option value={1372}>۱۳۷۲</option>
                                <option value={1371}>۱۳۷۱</option>
                                <option value={1370}>۱۳۷۰</option>
                                <option value={1369}>۱۳۶۹</option>
                                <option value={1368}>۱۳۶۸</option>
                                <option value={1367}>۱۳۶۷</option>
                                <option value={1366}>۱۳۶۶</option>
                                <option value={1365}>۱۳۶۵</option>
                                <option value={1364}>۱۳۶۴</option>
                                <option value={1363}>۱۳۶۳</option>
                                <option value={1362}>۱۳۶۲</option>
                                <option value={1361}>۱۳۶۱</option>
                                <option value={1360}>۱۳۶۰</option>
                                <option value={1359}>۱۳۵۹</option>
                                <option value={1358}>۱۳۵۸</option>
                                <option value={1357}>۱۳۵۷</option>
                                <option value={1356}>۱۳۵۶</option>
                                <option value={1355}>۱۳۵۵</option>
                                <option value={1354}>۱۳۵۴</option>
                            </select>
                        </div>
                    </div>
                    <div className='d-flex mb-2 mode-text-9 font-15 '> 
                        مشخصات اکانت    
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-flex w-50'>
                            <select type='dropdown' value={userAccess} className='users-input w-100' onChange={(e) => setUserAccess(e.target.value)}>
                                <option style={{color: '#707070'}} value={0}>نوع کاربر</option>
                                <option value={'User'}>مراجع</option>
                                <option value={'Therapist'}>درمانگر</option>
                            </select>
                        </div>
                        <div className='d-flex w-50'>
                            <input  type='text' placeholder='نام کاربری' value={userName} className='users-input w-100 d-ltr' onChange={(e) => setUserName(e.target.value)}></input>
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-flex w-100 align-items-center'>
                            <input type={showPassword ? "text" : "password"} placeholder='رمز عبور' value={passWord} className='users-input w-100 d-ltr' onChange={(e) => setPassWord(e.target.value)}></input>
                            <a style={{position: 'absolute', marginRight: '12px', color: '#808080', fontSize: '20px'}} onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ?
                                <AiOutlineEyeInvisible></AiOutlineEyeInvisible>
                                :
                                <AiOutlineEye></AiOutlineEye>
                                }
                            </a>
                        </div>
                    </div>
                    <div className='d-flex mb-2'>
                        <div className='d-flex w-100'>
                            <input type={showPasswordCheck ? "text" : "password"}  placeholder='تکرار رمز عبور' value={passWordCheck} className='users-input w-100 d-ltr' onChange={(e) => setPassWordCheck(e.target.value)}></input>
                            <a style={{position: 'absolute', marginRight: '12px', color: '#808080', fontSize: '20px'}} onClick={() => setShowPasswordCheck(!showPasswordCheck)}>
                                {showPasswordCheck ?
                                <AiOutlineEyeInvisible></AiOutlineEyeInvisible>
                                :
                                <AiOutlineEye></AiOutlineEye>
                                }
                            </a>
                        </div>
                    </div>
                </div>
                <div className='modal-footer mode-bg-8'>
                    <button onClick={() => closeModal(false)}>
                        انصراف
                    </button>
                    <button onClick={submitEditUser}>
                        تایید
                    </button>
                </div>
                <div className='error-text'>
                    {addUserError && <p>{messageError}</p>}
                </div>
            </div>
        </div>
    )
}

export default EditPersonalInfoModal
import React from 'react'
import './index.css'
import arrowImage from '../../../assets/arrow.png'
import axios from 'axios'
import LoadingPage from '../../loading-page'
import resultImageG01 from '../../../assets/FlatChartG01.svg'
import resultImageG02 from '../../../assets/FlatChartG02.svg'
import resultImageO01 from '../../../assets/FlatChartO01.svg'
import resultImageO02 from '../../../assets/FlatChartO02.svg'
import resultImageR01 from '../../../assets/FlatChartR01.svg'
import resultImageR02 from '../../../assets/FlatChartR02.svg'

const faQuestions = require('../../../utils/scoboTestQuestionsFa.js')
const engQuestions = require('../../../utils/scoboTestQuestionsEng.js')
const faAnswers = require('../../../utils/scoboTestAnswersFa')
const engAnswers = require('../../../utils/scoboTestAnswersEng')
const ageSelectionQuestions = require('../../../utils/autismSelectAge.js')
const faTestResults = require('../../../utils/scoboTestResultsFa.js')
const engTestResults = require('../../../utils/scoboTestResultsEng.js')

class TestAutismScreening extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      autismBackground: '',
      autismDiagnosis: '',
      childAge: 0,
      firstName: '',
      gender: '',
      lastName: '',
      phoneNumber: '',
      questionsCounter:  0,
      questionsToShow: [],
      questionsSelectionByAge: [0],
      questionNumber: 0,
      questionsTotalNumber: 68,
      questions: {},
      screeningAnswer: [],
      screeningbooking: false,
      execute: false,
      results: {},
      finishedAnswering: false,
      resultNumber: 0,
      countryCode: this.props.languageInput,
      testAnswersOptions: [],
      testResultsText: [],
      isLoaded: false,
      pictureSelector: '',
    }
  }
  

  componentDidMount = async() => {
    const lang = localStorage.getItem('lang')
    if(lang==null){
      if(this.state.countryCode == 'IR' ){
        this.setState({questionsToShow: [...faQuestions]})
        this.setState({testAnswersOptions: [...faAnswers]})
        this.setState({testResultsText: [...faTestResults]})
      } else {
        this.setState({questionsToShow: [...engQuestions]})
        this.setState({testAnswersOptions: [...engAnswers]})
        this.setState({testResultsText: [...engTestResults]})
      }
    }
    else if(lang =='IR'){
        this.setState({questionsToShow: [...faQuestions]})
        this.setState({testAnswersOptions: [...faAnswers]})
        this.setState({testResultsText: [...faTestResults]})
      } else {
        this.setState({questionsToShow: [...engQuestions]})
        this.setState({testAnswersOptions: [...engAnswers]})
        this.setState({testResultsText: [...engTestResults]})
      }

    const result = await axios.get('https://request.sweetobot.com/registers/receive', {headers: {'x-access-token': localStorage.getItem('token')}})
    if (result.data.message == "Yo, You are registered!") {
        this.setState({isRegistered: true});
        this.setState({firstName: result.data.firstname})
        this.setState({lastName: result.data.lastname})
        this.setState({phoneNumber: result.data.phone})
        this.setState({childAge: parseInt(result.data.age)})
        this.setState({gender: result.data.sex})
        this.setState({autismBackground: result.data.autismbackground == 'true'})
        this.setState({autismDiagnosis: result.data.autismdiagnosis})
        this.setState({screeningbooking: result.data.screeningbooking})
        this.setState({resultNumber: result.data.screeningresult})
        // this.translateResults(this.state.resultNum)
    }
    else {
        this.setState({isRegistered: false});
        window.location.href = '/'
    }

    this.setState({questionsSelectionByAge: [...ageSelectionQuestions[parseInt(result.data.age)]]})
    this.setState({questionsTotalNumber: ageSelectionQuestions[parseInt(result.data.age)].length -1})

    this.setState({isLoaded: true})
  }

  componentDidUpdate = () => {
    if (this.props.languageInput != this.state.countryCode) {
      this.setState({countryCode: this.props.languageInput})
      if(this.props.languageInput == 'IR' ){
        this.setState({questionsToShow: [...faQuestions]})
        this.setState({testAnswersOptions: [...faAnswers]})
        this.setState({testResultsText: [...faTestResults]})
      } else {
        this.setState({questionsToShow: [...engQuestions]})
        this.setState({testAnswersOptions: [...engAnswers]})
        this.setState({testResultsText: [...engTestResults]})
      }
    }
  }
  
  questionChildNoSpeak = () => {
    if (this.state.childAge == 0){
      this.state.questionsSelectionByAge.splice(39, 2)
      this.setState({questionsTotalNumber: this.state.questionsSelectionByAge.length-1})
    }

    if (this.state.childAge == 1){
      this.state.questionsSelectionByAge.splice(40, 2)
      this.state.questionsSelectionByAge.splice(41, 7)
      this.setState({questionsTotalNumber: this.state.questionsSelectionByAge.length-1})
    }

    if (this.state.childAge == 2){
      this.state.questionsSelectionByAge.splice(39, 1)
      this.state.questionsSelectionByAge.splice(44, 8)
      this.setState({questionsTotalNumber: this.state.questionsSelectionByAge.length-1})
    }

    if (this.state.childAge == 3){
      this.state.questionsSelectionByAge.splice(40, 1)
      this.state.questionsSelectionByAge.splice(45, 8)
      this.setState({questionsTotalNumber: this.state.questionsSelectionByAge.length-1})
    }
  }

  screeningAnswerHandler = (value) => {

    this.state.questions[this.state.questionsSelectionByAge[this.state.questionsCounter]] = value
    
    if (this.state.questionsCounter==0 && value!=1){
      this.setState({questionsTotalNumber: this.state.questionsSelectionByAge.length-1})
    }
    if (this.state.questionsCounter==0 && value==1){
      this.questionChildNoSpeak()
    }
    if (this.state.questionsCounter != this.state.questionsTotalNumber) {
      this.setState({questionsCounter: this.state.questionsCounter+1})
    } else {
      this.setState({finishedAnswering: true})
      this.sendAnsweredQuestions()
    }
  }

  previousQuestionNumberHandler = () => {
    if (this.state.questionsCounter > 0){
      this.setState({questionNumber: 1})
      this.setState({questionsCounter: this.state.questionsCounter-1})
    }
    if (this.state.questionsCounter == 1){
      this.state.childAge == 0 && this.setState({questionsSelectionByAge: [...ageSelectionQuestions[0]]})
      this.state.childAge == 1 && this.setState({questionsSelectionByAge: [...ageSelectionQuestions[1]]})
      this.state.childAge == 2 && this.setState({questionsSelectionByAge: [...ageSelectionQuestions[2]]})
      this.state.childAge == 3 && this.setState({questionsSelectionByAge: [...ageSelectionQuestions[3]]})
    }
  }

  nextQuestionNumberHandler = () => {
    if (this.state.questionNumber == 1){
      this.setState({questionsCounter: this.state.questionsCounter+1})
      this.setState({questionNumber: 0})
    }
  }

  sendAnsweredQuestions = async() => {
    const results = await axios.post('https://fendereski.sweetobot.com/questions', {
        name: this.state.firstName,
        phone: this.state.phoneNumber,
        age: this.state.childAge, // 0, 1, 2, 3
        sex: this.state.gender,
        hasAutism: this.state.autismBackground,
        dingAutism: this.state.autismDiagnosis,
        questions: Object.keys(this.state.questions), 
        answers: Object.values(this.state.questions)
        })
        console.log('this shit is: ' ,results.data.result);
        this.setState({resultNumber: results.data.result})
  }

  render() {
    if (this.state.resultNumber != 0){
      if (this.state.resultNumber == 1){
        if(this.state.countryCode == 'IR')
          this.setState({pictureSelector: resultImageG02})
        else 
          this.setState({pictureSelector: resultImageG01})
      } 
      if (this.state.resultNumber == 2){
        if(this.state.countryCode == 'IR')
          this.setState({pictureSelector: resultImageO02})
        else 
          this.setState({pictureSelector: resultImageO01})
      } 
      if (this.state.resultNumber == 3){
        if(this.state.countryCode == 'IR')
          this.setState({pictureSelector: resultImageR02})
        else 
          this.setState({pictureSelector: resultImageR01})
      }

      return (
        <div className='result-page'>
          <div className='result-page-container'>
            <div className='result-page-right-wrapper'>
              {this.state.resultNumber==1 && <img src={this.state.pictureSelector} className='result-answer'></img>}
              {this.state.resultNumber==2 && <img src={resultImageO02} className='result-answer'></img>}
              {this.state.resultNumber==3 && <img src={resultImageR02} className='result-answer'></img>}
            </div>
            <div className='result-page-left-wrapper'>
              {this.state.resultNumber==1 && <div className='result-answer-text'>{this.state.testResultsText[1]}</div>}

              {this.state.resultNumber==2 && <div className='result-answer-text'>{this.state.testResultsText[2]}</div>}
              {this.state.resultNumber==2 && <p className='result-answer-title mt-1' >{this.state.testResultsText[3]}</p>}

              {this.state.resultNumber==3 && <div className='result-answer-text'>{this.state.testResultsText[4]}</div>}
              {this.state.resultNumber==3 && <p className='result-answer-title mt-1' >{this.state.testResultsText[5]}</p>}
              <a href='/'> {this.state.testResultsText[6]} </a>
            </div>
          </div>
        </div>
      )
    }

    if (this.state.finishedAnswering == true){
      return (
        <div className='screening-test'>
          {this.state.testResultsText[0]}
        </div>
      )
    }

    if (!this.state.isLoaded){
      return (
        <LoadingPage /> 
      )
    }

    return (
      <div className={this.state.countryCode=='IR' ? 'screening-test' : 'screening-test-eng'}>
        <button onClick={() => this.nextQuestionNumberHandler()} className='pushButton-next-question'> 
          <img src={arrowImage}></img>
        </button>
        <div className='test-container'>
          <div className='question-number'>{this.state.questionsCounter}/{this.state.questionsTotalNumber}</div>
          <div className='container-screening-question'>
            <div className='screening-test-question'>
              <p>{this.state.questionsToShow[this.state.questionsSelectionByAge[this.state.questionsCounter]]}</p>
            </div>
          </div>
          <div className='container-screening-answer'>
          <button onClick={() => this.screeningAnswerHandler(4)} className='screening-answer-option mode-bg-2'>{this.state.testAnswersOptions[0]}</button>
            <button onClick={() => this.screeningAnswerHandler(3)} className='screening-answer-option mode-bg-2'>{this.state.testAnswersOptions[1]}</button>
            <button onClick={() => this.screeningAnswerHandler(2)} className='screening-answer-option mode-bg-2'>{this.state.testAnswersOptions[2]}</button>
            <button onClick={() => this.screeningAnswerHandler(1)} className='screening-answer-option mode-bg-2'>{this.state.testAnswersOptions[3]}</button>
          </div>
        </div>
          <button onClick={() => this.previousQuestionNumberHandler()} className='pushButton-previous-question'> 
            <img src={arrowImage}></img>
          </button>
      </div>
    )
  }
}

export default TestAutismScreening
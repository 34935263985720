import React from 'react'
import { useEffect, useState } from 'react'
import './index.css'

function DownloadModal({ closeModal, totalSize, downloadSize}) {

    const [percentage, setPercentage] = useState(0)
    const farsiNumbers = ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹',
                          '۱۰','۱۱','۱۲','۱۳','۱۴','۱۵','۱۶','۱۷','۱۸','۱۹',
                          '۲۰','۲۱','۲۲','۲۳','۲۴','۲۵','۲۶','۲۷','۲۸','۲۹',
                          '۳۰','۳۱','۳۲','۳۳','۳۴','۳۵','۳۶','۳۷','۳۸','۳۹',
                          '۴۰','۴۱','۴۲','۴۳','۴۴','۴۵','۴۶','۴۷','۴۸','۴۹',
                          '۵۰','۵۱','۵۲','۵۳','۵۴','۵۵','۵۶','۵۷','۵۸','۵۹',
                          '۶۰','۶۱','۶۲','۶۳','۶۴','۶۵','۶۶','۶۷','۶۸','۶۹',
                          '۷۰','۷۱','۷۲','۷۳','۷۴','۷۵','۷۶','۷۷','۷۸','۷۹',
                          '۸۰','۸۱','۸۲','۸۳','۸۴','۸۵','۸۶','۸۷','۸۸','۸۹',
                          '۹۰','۹۱','۹۲','۹۳','۹۴','۹۵','۹۶','۹۷','۹۸','۹۹',
                          '۱۰۰']
    useEffect(() => {
        setPercentage( parseInt(100*(downloadSize/totalSize)) )
    },[downloadSize])

    return (
        <div className='access-modal-background'>
            <div className='access-modal-container'>
                <div className='access-modal-title'>
                    {percentage != 100 ?
                    <p>{farsiNumbers[percentage]+"%"+" دانلود شده است ..."}</p>
                    :
                    <p>{"دانلود تکمیل شد!"}</p>
                    }
                </div>
                <button onClick={() => closeModal(false)}>
                        متوجه شدم
                </button>
            </div>
        </div>
    )
}

export default DownloadModal
module.exports = [
    '۰','۱','۲','۳','۴','۵','۶','۷','۸','۹',
    '۱۰','۱۱','۱۲','۱۳','۱۴','۱۵','۱۶','۱۷','۱۸','۱۹',
    '۲۰','۲۱','۲۲','۲۳','۲۴','۲۵','۲۶','۲۷','۲۸','۲۹',
    '۳۰','۳۱','۳۲','۳۳','۳۴','۳۵','۳۶','۳۷','۳۸','۳۹',
    '۴۰','۴۱','۴۲','۴۳','۴۴','۴۵','۴۶','۴۷','۴۸','۴۹',
    '۵۰','۵۱','۵۲','۵۳','۵۴','۵۵','۵۶','۵۷','۵۸','۵۹',
    '۶۰','۶۱','۶۲','۶۳','۶۴','۶۵','۶۶','۶۷','۶۸','۶۹',
    '۷۰','۷۱','۷۲','۷۳','۷۴','۷۵','۷۶','۷۷','۷۸','۷۹',
    '۸۰','۸۱','۸۲','۸۳','۸۴','۸۵','۸۶','۸۷','۸۸','۸۹',
    '۹۰','۹۱','۹۲','۹۳','۹۴','۹۵','۹۶','۹۷','۹۸','۹۹',
    '۱۰۰','۱۰۱','۱۰۲','۱۰۳','۱۰۴','۱۰۵','۱۰۶','۱۰۷','۱۰۸','۱۰۹',
    '۱۱۰','۱۱۱','۱۱۲','۱۱۳','۱۱۴','۱۱۵','۱۱۶','۱۱۷','۱۱۸','۱۱۹',
    '۱۲۰','۱۲۱','۱۲۲','۱۲۳','۱۲۴','۱۲۵','۱۲۶','۱۲۷','۱۲۸','۱۲۹',
    '۱۳۰','۱۳۱','۱۳۲','۱۳۳','۱۳۴','۱۳۵','۱۳۶','۱۳۷','۱۳۸','۱۳۹',
    '۱۴۰','۱۴۱','۱۴۲','۱۴۳','۱۴۴','۱۴۵','۱۴۶','۱۴۷','۱۴۸','۱۴۹',
    '۱۵۰','۱۵۱','۱۵۲','۱۵۳','۱۵۴','۱۵۵','۱۵۶','۱۵۷','۱۵۸','۱۵۹'
]
import React from 'react'
import './style.css'
import Header from "../header-fa";
import Footer from "../footer-fa";
import moreAppsSVG from "./images/MoreApps.svg"
import axios from 'axios'
import { FaLock, FaUser} from "react-icons/fa"
import HeaderFa from '../header-fa';
import FooterFa from '../footer-fa';

class SignIn extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            usernameLogin: "",
            passwordLogin: "",
            usernameEmpty: false,
            passwordEmpty: false,
            userOrPassError: false,
            sendMessage: "Hello,I'mInLoginPage"
        }
    }

    componentDidMount = async () => {
        this.handleRedirect();

        const result = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        if (result.data.message == "Yo, You are authenticated!") {
            window.location.href = '/fa/panel'
        } else {
            ;
        }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    submitHandler = async (event) => {
        event.preventDefault()
        if (this.state.usernameLogin == "") this.setState({usernameEmpty: true})
        else this.setState({usernameEmpty: false})
        if (this.state.usernameEmpty == false) {
            const result = await axios.post('https://request.sweetobot.com/login/request', {username: this.state.usernameLogin, password: this.state.passwordLogin})
            if (result.data.auth == false) {
                this.setState({userOrPassError: true})
            }
            else if (result.data.auth == true) {
                localStorage.setItem('token', result.data.token)
                window.location.href = '/fa/panel'
            }
        }
    }

    render() {
        return(
            <div className="container">
                <HeaderFa/>
                <div className="forms-container">
                    <div className="panels-container">
                        <div className="signin-panel right-panel">
                        <div className="signin-signup">
                                <form action="" className="signin-form" onSubmit={this.submitHandler}>
                                    <h2 className="signin-title">ورود به حساب کاربری</h2>
                                    <div className='input-field d-ltr'>
                                        <FaUser />
                                        <input type="text" placeholder="نام کاربری" onChange={(event) => {this.setState({usernameLogin: event.target.value})}}></input>
                                    </div>
                                    <div className='input-field d-ltr'>
                                        <FaLock />
                                        <input type="password" placeholder="رمز عبور" onChange={(event) => {this.setState({passwordLogin: event.target.value})}}></input>
                                    </div>
                                    <button type="submit" className="btn solid">ورود</button>
                                    <div className='error-message'>
                                        {this.state.usernameEmpty && <label>*لطفا نام کاربری را وارد کنید</label>}
                                        {this.state.userOrPassError && <label>*اطلاعات وارد شده اشتباه می باشد</label>}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="signin-panel left-panel">
                            <div className="content">
                                <h3>امکانات بیشتر؟</h3>
                                <p>کافیه وارد حساب کاربریتون بشید تا با دنیایی از امکانات روبرو بشید!</p>
                            </div>
                            <img className="more-apps-svg-image" src={moreAppsSVG}></img>
                        </div>
                    </div>
                </div>
                <FooterFa/>
            </div>
        );
    }
}

export default SignIn
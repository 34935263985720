module.exports = [
    'تمرکز کودک شما پایین است یا دیر چیزی را یاد می‌گیرد؟',
    "استفاده از بازی‌های موبایلی و تکنولوژی های آموزشی، می‌تواند به کودک شما کمک کند تا بدون نیاز به حضور در کلاس، از بهترین آموزش‌ها بهره ببرد. موزیکوبو، دوره‌ای هستش که در اون با بهره‌گیری از آخرین تکنولوژی دنیا به کودک آموزش داده میشه. پس فرصت رو از دست ندید وهمین حالا در دوره‌ی آنلاین موزیکوبو ثبت نام کنید.",
    "نام کودک",
    "شماره موبایل",
    "بازه‌ی سنی کودک خود را مشخص کنید",
    "۱۸ ماه تا ۲ سال",
    "۲ سال تا ۳ سال",
    "۳ سال تا ۴ سال",
    "بالای ۴ سال",
    "جنسیت کودک خود را انتخاب کنید",
    "دختر",
    "پسر",
    "آیا سابقه بیماری اتیسم در خانواده وجود دارد؟",
    "بله",
    "خیر",
    "برای کودک خود قبلا تشخیص اتیسم گرفته ام.",
    "دوره ۴ جلسه‌ای",
    "پرداخت و ثبت نام",
    "نام کودک خود را وارد کنید",
    "شماره موبایل خود را وارد کنید",
    "سن کودک خود را وارد کنید",
    "لطفا تمام قسمت‌ها را پر کنید",
    "کد تخفیف",
    "دوره ۸ جلسه‌ای",
    "نام خانوادگی کودک",
    "نام خانوادگی کودک خود را وارد کنید",
    "محصول مورد نظر را انتخاب کنید",
    "لطفا یک محصول را انتخاب کنید",
    "۶۰۰ هزار تومان",
    "۸۰۰ هزار تومان",
    "قیمت دوره",
    "شماره موبایل معتبر نمی‌باشد"
]
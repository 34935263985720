import React, {useState, useEffect} from 'react'
import './style.css'
import dataSlider from './dataSlider.js'

function SliderHP(SliderPicture1) {

    const [slideIndex, setSlideIndex] = useState(0)
    const sliderImage = [SliderPicture1.SliderPicture1, SliderPicture1.SliderPicture2]

    useEffect(() => {
        
        var slidesCounter = 0
        const interval = setInterval(() => {
            setSlideIndex(slidesCounter)
            if (slidesCounter > 0) {
                slidesCounter = 0
            } else {
                slidesCounter = 1
            }
        }, 4000);
        return () => clearInterval(interval);
      }, []);

    return (
        <div className="products-slider-container">
            {dataSlider.map((slider, i) => {
                return (
                    <div
                    className={slideIndex === i ? "product-slide active-anim" : "product-slide"}
                    > 
                        <img src={sliderImage[i]} alt={'slider' + i}/>
                    </div>
                )
            })}
        </div>
    )
}

export default SliderHP

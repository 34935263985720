import React, { useEffect, useState } from 'react'
import './index.css'

function Modal({ closeModal, startSession, testName, selectedUser }) {

    const[userAge, setUserAge] = useState(Math.ceil( ( new Date() - new Date(selectedUser.birthdate) ) / (1000 * 60 * 60 * 24 * 30) ) )

    const  inputHandler = () => {
        closeModal(false)
        startSession(true)
    }

    return (
        <div className='vidobo-modal-background'>
            {(testName != 'ASQ3') &&
            <div className='vidobo-modal-container'>
                <div className='vidobo-modal-title'>
                    آیا از انتخاب خود اطمینان دارید؟
                </div>
                <div className='vidobo-modal-footer'>
                    <button onClick={() => closeModal(false)}>
                        انصراف
                    </button>
                    <button onClick={() => inputHandler()}>
                        تایید
                    </button>
                </div>
            </div>
            }
            {(testName == 'ASQ3' && userAge > 12) &&
            <div className='vidobo-modal-container'>
                <div className='vidobo-modal-title'>
                    تست مناسب سن کاربر نمی‌باشد!
                </div>
                <div className='vidobo-modal-footer'>
                    <button onClick={() => closeModal(false)}>
                        انصراف
                    </button>
                </div>
            </div>
            }
            {(testName == 'ASQ3' && userAge <= 12) &&
            <div className='vidobo-modal-container'>
                <div className='vidobo-modal-title'>
                    آیا از انتخاب خود اطمینان دارید؟
                </div>
                <div className='vidobo-modal-footer'>
                    <button onClick={() => closeModal(false)}>
                        انصراف
                    </button>
                    <button onClick={() => inputHandler()}>
                        تایید
                    </button>
                </div>
            </div>
            }
        </div>
    )
}

export default Modal
import React from 'react'
import './style.css'
import axios from 'axios'
import Modal from './modal-store'
import AccessModal from './access-modal'

import Asq3Icon from '../../../../assets/asq3-icon.svg'
import ScoboIcon from '../../../../assets/scobo-icon.svg'
import Gars2Icon from '../../../../assets/gars2-icon.svg'
import Gars3Icon from '../../../../assets/gars3-icon.svg'
import PanelIcon from '../../../../assets/mehrobopanel-icon.svg'

const faNumbers = require('../../../../utils/numbersFa.js')

class Store extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            clinicUsers: null,
            sendMessage: "Hello,I'mInStorePage",
            evaluation: null,
            currentDateTime: null,
            openModal: false,
            errorModal: false,
            modalClinicUser: '',
            modalUserId: '',
            videocallSession: false,
            sessName: 0,
            currentUser: 0,
            products: null,
            productId: 0,
            clinicName: '',
            clinicId: 0,
            scoboCount: 1,
            gars2Count: 1,
            gars3Count: 1,
            asq3Count: 1,
            panelCount: 1,
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        console.log("Current Date is:", dateTime);
        console.log("Current Date is:", today.getTime());
        console.log("Current Date is:", randDate < today);
    }

    componentDidMount = async () => {
        const result = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        localStorage.setItem('currentUser', result.data.access)
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({isLogin: true});
            this.setState({clinicName: result.data.clinic})
            this.setState({products: result.data.products})
            console.log(result.data)
        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
        this.handleRedirect();
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    submitPurchase = (prdId, prdName) => {
        var tempClinicId = 0
        if (this.state.clinicName == "خورشید") {
            tempClinicId = 1
        } else if (this.state.clinicName == "آوات") {
            tempClinicId = 2
        } else if (this.state.clinicName == "شخصی۱") {
            tempClinicId = 3
        } else if (this.state.clinicName == "شخصی۲") {
            tempClinicId = 4
        } else if (this.state.clinicName == "شخصی۳") {
            tempClinicId = 5
        }

        var prdCount = 0
        if (prdId == 1 || prdId == 11) {
            prdCount = this.state.scoboCount
        } else if (prdId == 2 || prdId == 12) {
            if (prdId == 2 && prdName == 'GARS2') prdCount = this.state.gars2Count
            if (prdId == 2 && prdName == 'ASQ3') prdCount = this.state.asq3Count
            if (prdId == 12) prdCount = 5
            if (prdId == 22) prdCount = 10
            if (prdId == 32) prdCount = 20
        } else if (prdId == 3 || prdId == 13 || prdId == 23 || prdId == 33) {
            if (prdId == 3) prdCount = this.state.gars3Count
            if (prdId == 13) prdCount = 5
            if (prdId == 23) prdCount = 10
            if (prdId == 33) prdCount = 20
        } else if (prdId == 5 || prdId == 15 || prdId == 25 || prdId == 35) {
            if (prdId == 5) prdCount = 1
            if (prdId == 15) prdCount = 3
            if (prdId == 25) prdCount = 6
            if (prdId == 35) prdCount = 12
        }

        window.location.href = `https://www.sweetobot.com/bank-redirection?status=${prdId}&count=${prdCount}&CN=${tempClinicId}&PN=${prdName}&FN=None&LN=None&MN=None&WT=None`
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>

                <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>تمدید اکانت</span>
                                </p>
                            </div>
                        </div>
                        <div className='boxes my-4'>
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={PanelIcon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{"----"}</p>
                                <p className='mode-text-4 mt-0'>{(faNumbers[25] + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[1] + " ماهه")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(5, 'PANEL')}>خرید</button>
                            </div>
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={PanelIcon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۷۵" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{("۶۷۵" + "٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[3] + " ماهه")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(15, 'PANEL')}>خرید</button>
                            </div>
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={PanelIcon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۱٫۵۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{("۱٫۲۰۰" + "٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[6] + " ماهه")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(25, 'PANEL')}>خرید</button>
                            </div>
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={PanelIcon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۳٫۰۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{("۲٫۱۰۰" + "٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[12] + " ماهه")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(35, 'PANEL')}>خرید</button>
                            </div>
                        </div>
                    </div>
                    

                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2 mt-4'>تست‌ها و ارزیابی‌ها</span>
                                </p>
                            </div>
                        </div>
                        <div className='boxes my-4'>
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars3Icon} alt='icon-gars3'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{"----"}</p>
                                <p className='mode-text-4 mt-0'>{faNumbers[this.state.gars3Count * 5] + "۰٫۰۰۰" + " تومان"}</p>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{width: '33px', height: '33px', cursor: 'pointer'}} onClick={() => this.state.gars3Count < 4 && this.setState({gars3Count: this.state.gars3Count + 1})}>
                                        <span style={{fontSize:'21px'}}> + </span>
                                    </div>
                                    <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[this.state.gars3Count] + " عدد")}</p>
                                    <div className='evaluations-sub-btn d-flex align-items-center justify-content-center' style={{width: '33px', height: '33px', cursor: 'pointer'}} onClick={() => this.state.gars3Count > 0 && this.setState({gars3Count: this.state.gars3Count - 1})}>
                                        <span style={{fontSize:'21px'}}> - </span>
                                    </div>
                                </div>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(3, 'GARS3')}>خرید</button>
                            </div>
                            
                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars3Icon} alt='icon-gars3'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۲۵" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۲۲" + "۵٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[5] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(13, 'GARS3')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars3Icon} alt='icon-gars3'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۵۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۴۰" + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[10] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(23, 'GARS3')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars3Icon} alt='icon-gars3'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۱٫۰۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۷۰" + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[20] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(33, 'GARS3')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars2Icon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{"----"}</p>
                                <p className='mode-text-4 mt-0'>{faNumbers[this.state.gars2Count * 3] + "۰٫۰۰۰" + " تومان"}</p>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{width: '33px', height: '33px', cursor: 'pointer'}} onClick={() => this.state.gars2Count < 4 && this.setState({gars2Count: this.state.gars2Count + 1})}>
                                        <span style={{fontSize:'21px'}}> + </span>
                                    </div>
                                    <p className='mode-text-4 mb-0 mt-0'>{(faNumbers[this.state.gars2Count] + " عدد")}</p>
                                    <div className='evaluations-sub-btn d-flex align-items-center justify-content-center' style={{width: '33px', height: '33px', cursor: 'pointer'}} onClick={() => this.state.gars2Count > 0 && this.setState({gars2Count: this.state.gars2Count - 1})}>
                                        <span style={{fontSize:'21px'}}> - </span>
                                    </div>
                                </div>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(2, 'GARS2')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars2Icon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۱۵" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۱۳" + "۵٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[5] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(12, 'GARS2')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars2Icon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۳۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۲۴" + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[10] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(22, 'GARS2')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex'>
                                    <img src={Gars2Icon} alt='icon-gars2'></img>
                                </div>
                                <p className='mode-text-5 mb-0 font-12 line-through'>{("۶۰" + "۰٫۰۰۰" + " تومان")}</p>
                                <p className='mode-text-4 mt-0'>{"۴۲" + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0 mt-0' style={{minHeight: '33px'}}>{(faNumbers[20] + " عدد")}</p>
                                <button className='font-14 mt-2 mode-text-7' onClick={() => this.submitPurchase(32, 'GARS2')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => this.setState({asq3Count: this.state.asq3Count + 1})}>
                                        <span> + </span>
                                    </div>
                                    <img src={Asq3Icon} alt='icon-asq3'></img>
                                    <div className='evaluations-sub-btn d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => this.state.asq3Count > 0 && this.setState({asq3Count: this.state.asq3Count - 1})}>
                                        <span> - </span>
                                    </div>
                                </div>
                                <p className='mode-text-4 mb-0'>{faNumbers[this.state.asq3Count * 3] + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0'>{(faNumbers[this.state.asq3Count] + " عدد")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(2, 'ASQ3')}>خرید</button>
                            </div>

                            <div className='box d-flex flex-column align-items-center justify-content-center'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='evaluations-add-btn d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => this.setState({scoboCount: this.state.scoboCount + 1})}>
                                        <span> + </span>
                                    </div>
                                    <img src={ScoboIcon} alt='icon-gars2'></img>
                                    <div className='evaluations-sub-btn d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}} onClick={() => this.state.scoboCount > 0 && this.setState({scoboCount: this.state.scoboCount - 1})}>
                                        <span> - </span>
                                    </div>
                                </div>
                                <p className='mode-text-4 mb-0'>{faNumbers[this.state.scoboCount * 2] + "۰٫۰۰۰" + " تومان"}</p>
                                <p className='mode-text-4 mb-0'>{(faNumbers[this.state.scoboCount] + " عدد")}</p>
                                <button className='font-14 mt-4 mode-text-7' onClick={() => this.submitPurchase(1, 'SCOBO')}>خرید</button>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

export default Store
import React from 'react'
import './style.css'
import avatarDefault from '../../../assets/avatar-default.png'


class Sidebar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            firstName: props.firstName,
            lastName: props.lastName,
            accessType: props.accessType,
            sendMessage: "Hello,I'mInPanelPage",
        }
    }

    render() {
        return(
            <div className='sidebar'>
                <div className='mode-bg-8 sidebar-wrapper'>
                    <div className='sidebar-overly' onClick={this.props.sidebarHandler}></div>
                    <div className='sidebar-avatar'>
                        <div className='avatar-wrapper'>
                            <div className='avatar'><img className='avatar-image' src={avatarDefault} alt={'profile'}></img></div>
                            <a className='avatar-name mode-text-14'>
                                {this.props.firstName}{" "}{this.props.lastName}
                            </a>
                        </div>
                    </div>
                    <div className='sidebar-list-menu'>
                        <div className='list-menu-wrapper'>
                            <div className='list-menu'>
                                <div className='list-menu-item'>
                                    <a href='/fa/panel' className={'list-menu-content'+(this.props.activeLink===1 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===1 ? ' active' : '')}>داشبورد</span>
                                    </a>
                                </div>
                                <div className='list-menu-item'>
                                    <a href='/fa/panel/evaluations' className={'list-menu-content'+(this.props.activeLink===2 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===2 ? ' active' : '')}>تست‌ها و ارزیابی‌ها</span>
                                    </a>
                                </div>
                                <div className='list-menu-item'>
                                    <a href='/fa/panel/games' className={'list-menu-content'+(this.props.activeLink===3 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===3 ? ' active' : '')}>بازی‌های شناختی</span>
                                    </a>
                                </div>
                                <div className='list-menu-item'>
                                    <a href='/fa/panel/sessions' className={'list-menu-content'+(this.props.activeLink===4 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===4 ? ' active' : '')}>جلسات هوشمند</span>
                                    </a>
                                </div>
                                {this.state.accessType !== "User" &&
                                <div className='list-menu-item'>
                                    <a href='/fa/panel/users' className={'list-menu-content'+(this.props.activeLink===5 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===5 ? ' active' : '')}>مدیریت کاربران</span>
                                    </a>
                                </div>
                                }
                                {this.state.accessType !== "User" &&
                                <div className='list-menu-item'>
                                    <a href='/fa/panel/store' className={'list-menu-content'+(this.props.activeLink===6 ? ' active' : '')}>
                                        <span className={'list-menu-label'+(this.props.activeLink===6 ? ' active' : '')}>خرید محصولات</span>
                                    </a>
                                </div>
                                }
                                <div className='list-menu-item' style={{marginTop: '15px'}}>
                                    <a href='/fa/login' className={'list-menu-content'+(this.props.activeLink===7 ? ' active' : '')} onClick={() => localStorage.setItem('token', null)}>
                                        <span className={'list-menu-label'+(this.props.activeLink===7 ? ' active' : '')} style={{color:"#FF0073"}} >خروج</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar
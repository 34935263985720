import React from 'react'
import axios from 'axios';
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import HomePage from './components/fa/homepage'
import AboutUs from './components/fa/about'
import Login from './components/fa/login'
import Header from './components/fa/header-fa'
import Screening from './components/fa/screening-ticket'
import MusicTherapy from './components/fa/musicobo-ticket'
import AiClinic from './components/fa/ai-clinic';
import Test from './components/fa/screening-test'
import Panel from './components/fa/panel';
import Footer from './components/fa/footer-fa';
import CheckoutFail from './components/fa/checkout-fail';
import CheckoutSuccess from './components/fa/checkout-success'
import MusicalKeyboard from './components/fa/panel/musical-keyboard'
import LoadingPage from './components/loading-page'
import Redirection from './components/redirection';
import './App.css'
import HomePageEn from './components/en/homepage-en';

class App extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      countryCode: 'IR',
      showSidebar: false,
      isLoaded: false,
    }
  }

  sidebarHandler = () => {
    this.setState({showSidebar: !this.state.showSidebar})
  }

  render() {

    return (
      <div className={this.state.countryCode=='IR' ? 'App-fa' : 'App'}>
        <Router>
          <Routes>
            <Route path='/' element={<Redirection inputURL={'/'}/>} />
            <Route path='/about' element={<Redirection inputURL={'/about'}/>} />
            <Route path='/login' element={<Redirection inputURL={'/login'}/>} />
            <Route path='/screening-ticket' element={<Redirection inputURL={'/screening-test'}/>} />
            <Route path='/screening-test' element={<Redirection inputURL={'/screening-test'}/>} />
          </Routes>
          <Routes>
            <Route path='/fa' element={<HomePage languageInput={this.state.countryCode}/>} />
            <Route path='/fa/about' element={<AboutUs/>} />
            <Route path='/fa/login' element={<Login/>} />
            <Route path='/fa/products/screening-ticket' element={<Screening languageInput={this.state.countryCode} />}  />
            <Route path='/fa/products/musicobo-ticket' element={<MusicTherapy languageInput={this.state.countryCode} /> } />
            <Route path='/fa/products/clinic-ticket' element={<AiClinic/>} />
            <Route path='/fa/screening-test' element={<Test languageInput={this.state.countryCode} />} />
            <Route path='/fa/checkout-fail' element={<CheckoutFail/>} />
            <Route path='/fa/checkout-success' element={<CheckoutSuccess/>} />
            <Route path="/fa/panel/store"
              element={<Panel activeLink={6} subLink={0} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/users"
              element={<Panel activeLink={5} subLink={0} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/sessions/session"
              element={<Panel activeLink={4} subLink={3} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/sessions/videoconf"
              element={<Panel activeLink={4} subLink={2} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/sessions/videocall"
              element={<Panel activeLink={4} subLink={1} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/sessions"
              element={<Panel activeLink={4} subLink={0} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/games"
                element={<Panel activeLink={3} subLink={0} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/games/musicobo"
                element={<Panel activeLink={3} subLink={1} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/binet"
              element={<Panel activeLink={2} subLink={7} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/wechsler"
              element={<Panel activeLink={2} subLink={6} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/gars3"
              element={<Panel activeLink={2} subLink={5} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/gars2"
              element={<Panel activeLink={2} subLink={4} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/scobo"
              element={<Panel activeLink={2} subLink={3} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/list/results"
              element={<Panel activeLink={2} subLink={2} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations/list"
                element={<Panel activeLink={2} subLink={1} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/evaluations"
                element={<Panel activeLink={2} subLink={0} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel"
              element={<Panel activeLink={1} sidebar={this.state.showSidebar} sidebarHandler={this.sidebarHandler}/>}
            />
            <Route path="/fa/panel/musical-keyboard" element={<MusicalKeyboard/>} />
          </Routes>
          <Routes>
            <Route path='/en' element={<HomePageEn languageInput={this.state.countryCode}/>} />
          </Routes>
        </Router>
      </div>
    );
  }
}

export default App;
import React, { useEffect, useState } from 'react'
import './style.css'
// import logoImage from '../../../assets/Mehrobo-Logo.png'
import logoImage from '../../../assets/Mehrobo-Logo-White.png'
import iranFlag from '../../../assets/iran-flag.png'
import ukFlag from '../../../assets/uk-flag.png'

import MenuIcon from '@mui/icons-material/Menu'
import { MdLanguage } from 'react-icons/md'

const englishText = require('../../../utils/headerEnglishTexts.js')
const farsiText = require('../../../utils/headerFarsiTexts.js')

function HeaderFa (props) {
  const [productOpen, setProductOpen] = useState([false])
  const [languageContainer, setLanguageContainer] = useState([false])
  const [menuResponsive, setMenuResponsive] = useState([false])
  const [headerText,setHeaderText] = useState(farsiText)
  const [countryCode, setCountryCode] = useState('IR')

  const changeLanguage = (number) => {
    if (number == 1) {
      setCountryCode('IR')
      setHeaderText(farsiText)
      localStorage.setItem('lang', 'IR')
    }
    else if (number==2) {
      setCountryCode('US')
      setHeaderText(englishText)
      localStorage.setItem('lang', 'US')
      window.location.href = '/en'
    }
  }
    
  return (
    <header>
      <div className='header-container'>
        <div className='header-responsive-right'>
          <a className='menu-responsive-icon' onClick={() => setMenuResponsive(!menuResponsive)}>
            <MenuIcon />
          </a>
          {!menuResponsive &&
          <div className= 'menu-responsive-container'>
            <a className='products-button' onClick={() => setProductOpen(!productOpen)}>
            <p>{headerText[3]}</p>
            {!productOpen && 
            <div className='products-container' >
              <a className='products' href='/fa/products/screening-ticket'>{headerText[4]}</a> 
              <a className='products' href='/fa/products/musicobo-ticket'>{headerText[5]}</a>
              <a className='products' href='/fa/products/clinic-ticket'>{headerText[6]}</a>
            </div>
            }
            </a>
            <a href='/fa/about' className='button-aboutus'>
              {headerText[1]}
            </a>
            <a href='/fa/login' className='button-login'>
              {headerText[2]}
            </a>
            <a className='language-button' onClick={() => setLanguageContainer(!languageContainer)}>
                <div className='language'><MdLanguage style={{fontSize: '1.5em'}}/></div>
              {!languageContainer && 
              <div className='language-container'>
                <a className='language' onClick={() => changeLanguage(2)}>{headerText[7]}</a> 
              </div>
              }
            </a>
          </div>
          }
        </div>
        <div className='header-responsive-left'>
          <a href='/' className='header-item'>             
            <img src={logoImage}></img>
          </a>
        </div>
        <div className='header-wrapper-right'>
          <a className='language-button' onClick={() => setLanguageContainer(!languageContainer)}>
            <div className='header-item'><MdLanguage style={{fontSize: '1.5em'}}/></div>
            {!languageContainer && 
            <div className='language-container' >
              <a className='language' onClick={() => changeLanguage(2)}>{headerText[7]}</a> 
            </div>
            }
          </a>
          <a href='/fa' className='header-item'>
            {"خانه"}
          </a>
          <a className='header-item' onMouseEnter={() => setProductOpen(!productOpen)} onMouseLeave={() => setProductOpen(!productOpen)} onClick={() => setProductOpen(!productOpen)}>
            <p>{headerText[3]}</p>
            {!productOpen && 
            <div className='products-container' >
              <a className='products' href='/fa/products/screening-ticket'>{headerText[4]}</a> 
              <a className='products' href='/fa/products/musicobo-ticket'>{headerText[5]}</a>
              <a className='products' href='/fa/products/clinic-ticket'>{headerText[6]}</a>
            </div>
            }
          </a>
          <a href='/fa/about' className='header-item'>
            {headerText[1]}
          </a>
          <a href='/fa/login' className='header-item'>
            {headerText[2]}
          </a>
        </div>
        <div className='header-wrapper-left'>
          <a href='/fa' className='header-item'> 
            <img src={logoImage}></img>
          </a>
        </div>
      </div>
    </header>
  )
}

export default HeaderFa
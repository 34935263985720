import React, { useState } from 'react'
import './index.css'

function Modal({ closeModal, startSession, nameChildServer }) {

    const [childNumber, setChildNumber] = useState('')
    const [showError, setShowError] = useState(false)

    const  nameInput = (event) => {
        setChildNumber(event.target.value)
        nameChildServer(event.target.value)
        console.log(nameChildServer);
    }

    const  inputHandler = () => {
        if(childNumber == '')
            setShowError(true)
        else {
            closeModal(false)
            startSession(true)
        }
    }

    return (
        <div className='vidobo-modal-background'>
            <div className='vidobo-modal-container'>
                <div className='vidobo-modal-title'>
                    آیا می‌خواهید جلسه‌ای ایجاد کنید؟
                </div>
                <select type='dropdown' onChange={(event) => nameInput(event)} className='modal-drop-down'>
                    <option value={'0'}>
                        انتخاب جلسه... 
                    </option>
                    <option value={'SpeechTherapy'}>
                        گفتار درمانی {/* SpeechTherapy  */}
                    </option>
                    <option value={'MusicTherapy'}>
                        موسیقی درمانی {/* MusicTherapy  */}
                    </option>
                    <option value={'FamilyTherapy'}>
                        زوج درمانی {/* FamilyTherapy   */}
                    </option>
                    <option value={'GameTherapy'}>
                        بازی درمانی {/* GameTherapy  */}
                    </option>
                    <option value={'Other'}>
                        سایر {/* Other   */}
                    </option>
                </select>
                <div className='vidobo-modal-footer'>
                    <button onClick={() => closeModal(false)}>
                        خیر
                    </button>
                    <button onClick={() => inputHandler()}>
                        بله
                    </button>
                </div>
            {showError ? <div className='modal-error'>لطفا جای خالی را پر کنید</div> :null}
            </div>
        </div>
    )
}

export default Modal
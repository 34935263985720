import React from 'react'
import './style.css'
import AudioPlayer from "./AudioPlayer";
import axios from 'axios'
const audioPlayer = AudioPlayer();

class MusiacalKeyboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            isLoaded: false,
            song: ["A3","B3","C4","E4","F4","E4","F4","E4","F4","E4",
                  "F4","E4","D4","E4","D4","E4","D4","E4","D4","E4",
                  "D4","C4","D4","C4","D4","C4","B3","A3","Ab3","A3",
                  "B3",
                  "A3","B3","C4","E4","F4","E4","F4","E4","F4","E4",
                  "F4","E4","D4","E4","D4","E4","D4","E4","D4","E4",
                  "D4","C4","D4","C4","D4","C4","B3","A3","Ab3","B3",
                  "A3",
                  "A4","G4","F4","E4","F4","E4","D4","F4","E4","D4",
                  "F4","G4","F4","E4","D4","E4","D4","C4","E4","D4",
                  "C4","E4","F4","E4","D4","C4","D4","C4","B3","D4",
                  "C4","B3","D4","D4","E4","G4","F4","E4",
                  "A4","G4","F4","E4","F4","E4","D4","F4","E4","D4",
                  "F4","G4","F4","E4","D4","E4","D4","C4","E4","D4",
                  "C4","E4","F4","E4","D4","C4","D4","C4","B3","D4",
                  "C4","B3","D4","E4","D4","C4","B3","A3"],
            songDurations: [0.5,0.5,0.5,1,0.5,1,0.5,1,0.5,0.5,
                            0.5,0.5,1,0.5,1,0.5,1,0.5,0.5,0.5,
                            0.5,1,0.5,1,0.5,1,0.5,0.5,0.5,0.5,
                            1,0.5,0.5,0.5,1,0.5,1,0.5,1,0.5,
                            1,0.5,0.5,0.5,1,0.5,1,0.5,1,0.5,
                            0.5,0.5,0.5,1,0.5,1,0.5,1,0.5,0.5,
                            0.5,0.5,1,0.5,1,0.5,1,0.5,0.5,0.5,
                            0.5,1,0.5,0.5,0.5,0.5,0.75,0.25,0.5,0.75,
                            0.25,0.5,1,0.5,0.5,0.5,0.5,0.75,0.25,0.5,
                            0.75,0.25,0.5,1,0.5,0.5,0.5,0.5,0.75,0.25,
                            0.5,0.75,0.25,0.5,1,0.5,0.5,0.5,0.5,2,
                            0.5,0.5,0.5,0.5,0.75,0.25,0.5,0.75,0.25,0.5,
                            1,0.5,0.5,0.5,0.5,0.75,0.25,0.5,0.75,0.25,
                            0.5,1,0.5,0.5,0.5,0.5,0.75,0.25,0.5,0.75,
                            0.25,0.5,1,0.5,0.5,0.5,0.5,2],
            keyTurn: "A3",
            keyTurnNum: 0,
            wrongKeys: 0,
            firstName: "",
            lastName: "",
            songNumber: 0,
            playSong: false,
            startSong: false,
            progressElement: <line className="cls-2" x1="294.41" y1="30.5" x2="294.41" y2="30.5"/>,
            progressPercentage: 0,
            sendMessage: "Hello,I'mInMusicalKeyboardPage",
            songMessage: "Hello,IWantASong"
        }
    }

    componentDidMount = async () => {
        //////// Check if user signed in
        const result = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        if (result.data.message === "Yo, You are authenticated!") {
            this.setState({isLogin: true});
            this.setState({firstName: result.data.firstname})
            this.setState({lastName: result.data.lastname})
        } else {
            this.setState({isLogin: false});
            window.location.href = 'http://www.sweetobot.com/login'
        }
        const instrument = await audioPlayer.setInstrument("acoustic_grand_piano");
        if (instrument == true) {
          this.setState({isLoaded: true})
        }
        const songpackage = await axios.post('https://fendereski.sweetobot.com/musicalnotes', {message: this.state.songMessage, songNum: 0})
        this.setState({song: songpackage.data.song})
        this.setState({songDurations: songpackage.data.durations})
        //////// End of check
        //////// Send page status
        this.handleRedirect();
        //////// End of page status
        const WHITE_KEYS = ['z', 'x', 'c', 'v', 'b', 'n', 'm'] // Keyboard
        const BLACK_KEYS = ['s', 'd', 'g', 'h', 'j'] // Keyboard
        const whiteKeys = document.querySelectorAll('.key.white') // Keyboard
        const blackKeys = document.querySelectorAll('.key.black') // Keyboard
        document.addEventListener('keydown', event => { // Keyboard
            const key = event.key // Keyboard
            const whiteKeyIndex = WHITE_KEYS.indexOf(key) // Keyboard
            const blackKeyIndex = BLACK_KEYS.indexOf(key) // Keyboard
            if (whiteKeyIndex > -1) this.playNote(whiteKeys[whiteKeyIndex]) // Keyboard
            if (blackKeyIndex > -1) this.playNote(blackKeys[blackKeyIndex]) // Keyboard
        })
    }

    progressCheck = (event) => {
      var percentage = (this.state.keyTurnNum + 1)/this.state.song.length;
      if (this.state.startSong) {
        this.setState({progressPercentage: Math.round(percentage*100)})
      }
      if (percentage < 0.125) {
        this.setState({progressElement: <line className="cls-2" x1="294.41" y1="30.5" x2="294.41" y2="30.5"/>})
      }
      else if (percentage >= 0.125 && percentage < 0.25) {
        this.setState({progressElement: <path className="cls-2" d="M300,32.5a266.59,266.59,0,0,1,185.06,74.34" transform="translate(-2.5 -2.5)"/>})
      }
      else if (percentage >= 0.25 && percentage < 0.375) {
        this.setState({progressElement: <path className="cls-2" d="M299.85,32.38c147.74,0,267.5,119.76,267.5,267.5" transform="translate(-2.35 -2.38)"/>})
      }
      else if (percentage >= 0.375 && percentage < 0.5) {
        this.setState({progressElement: <path className="cls-2" d="M300,32.5c147.74,0,267.5,119.76,267.5,267.5a266.73,266.73,0,0,1-79.57,190.37" transform="translate(-2.5 -2.5)"/>})
      }
      else if (percentage >= 0.5 && percentage < 0.625) {
        this.setState({progressElement: <path className="cls-2" d="M299.85,32.38c147.74,0,267.5,119.76,267.5,267.5s-119.76,267.5-267.5,267.5" transform="translate(-2.35 -2.38)"/>})
      }
      else if (percentage >= 0.625 && percentage < 0.75) {
        this.setState({progressElement: <path className="cls-2" d="M300,32.5c147.74,0,267.5,119.76,267.5,267.5S447.74,567.5,300,567.5a266.85,266.85,0,0,1-199.93-89.78" transform="translate(-2.5 -2.5)"/>})
      }
      else if (percentage >= 0.75 && percentage < 0.875) {
        this.setState({progressElement: <path className="cls-2" d="M299.85,32.38c147.74,0,267.5,119.76,267.5,267.5s-119.76,267.5-267.5,267.5S32.35,447.61,32.35,299.88" transform="translate(-2.35 -2.38)"/>})
      }
      else if (percentage >= 0.875 && percentage < 1) {
        this.setState({progressElement: <path className="cls-2" d="M300,32.5c147.74,0,267.5,119.76,267.5,267.5S447.74,567.5,300,567.5,32.5,447.74,32.5,300a266.59,266.59,0,0,1,76.15-186.92" transform="translate(-2.5 -2.5)"/>})
      }
      else {
        this.setState({progressElement: <circle className="cls-2" cx="297.5" cy="297.5" r="267.5"/>})
      }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }
    
    playSongComplete = (event) => {
        if (this.state.playSong == false) {
            audioPlayer.playScheduledNotes(this.state.song, this.state.songDurations)
            this.setState({playSong: true});
        } else if (this.state.playSong == true) {
            this.stopSongComplete();
        }
    }

    stopSongComplete = (event) => {
        if (this.state.playSong == true) {
            audioPlayer.stopScheduledNotes(this.state.song)
            this.setState({playSong: false});
        }
    }

    startPlaying = (event) => {
      const startElement = document.getElementById(this.state.song[0])
      startElement.classList.add('toPlay'+this.state.song[0])
      this.setState({startSong: true})
      this.setState({progressPercentage: 0})
      this.setState({progressElement: <line class="cls-2" x1="294.41" y1="30.5" x2="294.41" y2="30.5"/>})
    }

    stopPlaying = async (event) => {
      let playedDurations = {}
      for (let i = 0; i < this.state.song.length; i++) {
          playedDurations[i] = 0;
      }
      const sendPlayedData = await axios.post('https://fendereski.sweetobot.com/playednotes', {
        firstname: this.state.firstName, 
        lastname: this.state.lastName, 
        songNum: this.state.songNumber, 
        lastNote: this.state.keyTurnNum, 
        noteTimes: Object.values(playedDurations), 
        falseNotes: this.state.wrongKeys
      })
      const currentElement = document.getElementById(this.state.song[this.state.keyTurnNum])
      currentElement.classList.remove('toPlay'+this.state.song[this.state.keyTurnNum])
      this.setState({startSong: false})
      this.setState({keyTurn: this.state.song[0]})
      this.setState({keyTurnNum: 0})
      this.setState({wrongKeys: 0})
    }

    handleTouchStartF3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("F3");
    };
    handleTouchEndF3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartGb3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("F#3");
    };
    handleTouchEndGb3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay')
          nextElement.classList.add('toPlay')
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartG3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("G3");
    };
    handleTouchEndG3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay')
          nextElement.classList.add('toPlay')
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartAb3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("G#3");
    };
    handleTouchEndAb3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartA3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("A3");
    };
    handleTouchEndA3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartBb3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("A#3");
    };
    handleTouchEndBb3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartB3 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("B3");
    };
    handleTouchEndB3 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartC4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("C4");
    };
    handleTouchEndC4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartDb4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("C#4");
    };
    handleTouchEndDb4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartD4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("D4");
    };
    handleTouchEndD4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartEb4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("D#4");
    };
    handleTouchEndEb4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartE4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("E4");
    };
    handleTouchEndE4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartF4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("F4");
    };
    handleTouchEndF4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartGb4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("F#4");
    };
    handleTouchEndGb4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartG4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("G4");
    };
    handleTouchEndG4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartAb4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("G#4");
    };
    handleTouchEndAb4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartA4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("A4");
    };
    handleTouchEndA4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartBb4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("A#4");
    };
    handleTouchEndBb4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartB4 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("B4");
    };
    handleTouchEndB4 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };

    handleTouchStartC5 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("C5");
    };
    handleTouchEndC5 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartDb5 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("C#5");
    };
    handleTouchEndDb5 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartD5 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("D5");
    };
    handleTouchEndD5 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartEb5 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("D#5");
    };
    handleTouchEndEb5 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };
    handleTouchStartE5 = (event) => {
      const touchedElement = event.target;
      touchedElement.classList.add('active')
      if (event.type === "touchstart")
        audioPlayer.playNote("E5");
    };
    handleTouchEndE5 = (event) => {
      const touchedElement = event.target;
      if (this.state.startSong == true) {
        const nextkeyTurn = this.state.keyTurnNum + 1
        if (nextkeyTurn == this.state.song.length) {
          this.stopPlaying()
          touchedElement.classList.remove('active')
          return
        }
        const nextElement = document.getElementById(this.state.song[nextkeyTurn])
        if (this.state.keyTurn == touchedElement.id) {
          this.setState({keyTurn: this.state.song[nextkeyTurn]})
          this.setState({keyTurnNum: nextkeyTurn})
          touchedElement.classList.remove('toPlay'+this.state.song[nextkeyTurn-1])
          nextElement.classList.add('toPlay'+this.state.song[nextkeyTurn])
        } else {
          this.setState({wrongKeys: this.state.wrongKeys + 1})
        }
      }
      touchedElement.classList.remove('active')
    };

    playNote = (key) => {
        const noteAudio = document.getElementById(key.dataset.note)
        noteAudio.currentTime = 0
        noteAudio.play()
        key.classList.add('active')
        noteAudio.addEventListener('ended', () => {
            key.classList.remove('active')
        })
    }

    playC = () => {
        const C = document.getElementById("C")
        C.play()
    }

    chooseSong = async (event) => {
        this.setState({songNumber: event.target.value})
        const songpackage = await axios.post('https://fendereski.sweetobot.com/musicalnotes', {message: this.state.songMessage, songNum: event.target.value})
        this.setState({song: songpackage.data.song})
        this.setState({songDurations: songpackage.data.durations})
        this.setState({keyTurn: songpackage.data.song[0]})
    }
    
    render() {

        if (this.state.isLogin == false) {
          return(
            <div>

            </div>
          )
        }
        return(
            <div className="piano-container">
                <div className="piano-controller">
                    <div className="piano-buttons-wrapper">
                        <div className="song-control-ui">
                            <div className="play-stop-song">
                                <button className="piano-play-song" onClick={this.playSongComplete}></button>
                                <button className="piano-stop-song" onClick={this.stopSongComplete}></button>
                            </div>
                            <div className="song-select">
                                <select required className="piano-song-select" onChange={this.chooseSong}>
                                    <option value={0}>عارف</option>
                                    <option value={1}>معین</option>
                                </select>
                            </div>
                        </div>
                        <div className="lcd-control-ui">
                            
                        </div>
                        <div className="challenge-control-ui">
                            <div className="start-stop-challenge">
                                <button className="piano-start-play" onClick={this.startPlaying}></button>
                                <button className="piano-stop-play" onClick={this.stopPlaying}></button>
                            </div>
                            <div className="circular-progressbar-wrapper">
                                <svg className="circular-progressbar" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 595 595">
                                    <circle className="cls-1" cx="297.5" cy="297.5" r="267.5"/>
                                    {this.state.progressElement}
                                </svg>
                                <label className="progress-percentage">{this.state.progressPercentage}{"%"}</label>
                                <label className={this.state.wrongKeys == 0 ? "progress-falsenotes" : "progress-falsenotes wrongnotes"}>{this.state.wrongKeys}</label>
                            </div>
                        </div>
                    </div>
                    {this.state.isLoaded ? <div></div> : <div className="loading-instrument">در حال بارگذاری، لطفا دکمه ای را لمس نکنید ...</div>}
                </div>
                <div className="piano" onTouchEnd={this.progressCheck}>
                    <div id="F3" className="key white" onTouchStart={this.handleTouchStartF3} onTouchEnd={this.handleTouchEndF3}></div>
                    <div id="Gb3" className="key black" onTouchStart={this.handleTouchStartGb3} onTouchEnd={this.handleTouchEndGb3}></div>
                    <div id="G3" className="key white" onTouchStart={this.handleTouchStartG3} onTouchEnd={this.handleTouchEndG3}></div>
                    <div id="Ab3" className="key black" onTouchStart={this.handleTouchStartAb3} onTouchEnd={this.handleTouchEndAb3}></div>
                    <div id="A3" className="key white" onTouchStart={this.handleTouchStartA3} onTouchEnd={this.handleTouchEndA3}></div>
                    <div id="Bb3" className="key black" onTouchStart={this.handleTouchStartBb3} onTouchEnd={this.handleTouchEndBb3}></div>
                    <div id="B3" className="key white" onTouchStart={this.handleTouchStartB3} onTouchEnd={this.handleTouchEndB3}></div>
                    <div id="C4" className="key white" onTouchStart={this.handleTouchStartC4} onTouchEnd={this.handleTouchEndC4}></div>
                    <div id="Db4" className="key black" onTouchStart={this.handleTouchStartDb4} onTouchEnd={this.handleTouchEndDb4}></div>
                    <div id="D4" className="key white" onTouchStart={this.handleTouchStartD4} onTouchEnd={this.handleTouchEndD4}></div>
                    <div id="Eb4" className="key black" onTouchStart={this.handleTouchStartEb4} onTouchEnd={this.handleTouchEndEb4}></div>
                    <div id="E4" className="key white" onTouchStart={this.handleTouchStartE4} onTouchEnd={this.handleTouchEndE4}></div>
                    <div id="F4" className="key white" onTouchStart={this.handleTouchStartF4} onTouchEnd={this.handleTouchEndF4}></div>
                    <div id="Gb4" className="key black" onTouchStart={this.handleTouchStartGb4} onTouchEnd={this.handleTouchEndGb4}></div>
                    <div id="G4" className="key white" onTouchStart={this.handleTouchStartG4} onTouchEnd={this.handleTouchEndG4}></div>
                    <div id="Ab4" className="key black" onTouchStart={this.handleTouchStartAb4} onTouchEnd={this.handleTouchEndAb4}></div>
                    <div id="A4" className="key white" onTouchStart={this.handleTouchStartA4} onTouchEnd={this.handleTouchEndA4}></div>
                    <div id="Bb4" className="key black" onTouchStart={this.handleTouchStartBb4} onTouchEnd={this.handleTouchEndBb4}></div>
                    <div id="B4" className="key white" onTouchStart={this.handleTouchStartB4} onTouchEnd={this.handleTouchEndB4}></div>
                    {/* <div id="C5" className="key white" onTouchStart={this.handleTouchStartC4} onTouchEnd={this.handleTouchEndC4}></div>
                    <div id="Db5" className="key black" onTouchStart={this.handleTouchStartDb4} onTouchEnd={this.handleTouchEndDb4}></div>
                    <div id="D5" className="key white" onTouchStart={this.handleTouchStartD4} onTouchEnd={this.handleTouchEndD4}></div>
                    <div id="Eb5" className="key black" onTouchStart={this.handleTouchStartEb4} onTouchEnd={this.handleTouchEndEb4}></div>
                    <div id="E5" className="key white" onTouchStart={this.handleTouchStartE4} onTouchEnd={this.handleTouchEndE4}></div> */}
                </div>
            </div>
        )
    }
}

export default MusiacalKeyboard
import React from 'react'
import './style.css'
import axios from 'axios'
import Modal from './modal-sessions'
import AccessModal from './access-modal'

import MusicoboIcon from '../../../../assets/musicobo-icon.svg'
import VideoconferenceIcon from '../../../../assets/videoconference-icon.svg'

class Games extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            clinicUsers: null,
            sendMessage: "Hello,I'mInEvaluationsPage",
            evaluation: null,
            currentDateTime: null,
            openModal: false,
            errorModal: false,
            modalClinicUser: '',
            modalUserId: '',
            videocallSession: false,
            sessName: 0,
            currentUser: 0,
            products: null,
        }
    }

    getCurrentDateAndTime = () => {
        var today =  new Date();
        var randDate = new Date("2022-5-3 7:0:0");

        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTime = date+' '+time;

        console.log("Current Date is:", dateTime);
        console.log("Current Date is:", today.getTime());
        console.log("Current Date is:", randDate < today);
    }

    componentDidMount = async () => {
        const result = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        localStorage.setItem('currentUser', result.data.access)
        if (result.data.message == "Yo, You are authenticated!") {
            this.setState({isLogin: true});
            this.setState({products: result.data.user.products})
            console.log(result.data)
        } else {
            // this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
        this.handleRedirect();
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    render() {
        if (this.state.isLogin == false) {
            return(
                <div className="container-fluid">

                </div>
            )
        }
        
        return(
            <div className="container-fluid">
                <div className='row no-gutters'>
                    <div className='col-12'>
                        <div className='d-flex mb-3 align-items-center'>
                            <div className='d-flex flex-column ml-2'>
                                <p className='d-flex mb-2 align-items-center'>
                                    <span className='font-medium font-18 mode-text-2'>بازی‌های شناختی مهروبو</span>
                                </p>
                            </div>
                        </div>
                        <div className='boxes my-4'>
                            {this.state.products.Musicobo > 0 ?
                            <a href='/fa/panel/games/musicobo' className='box d-flex flex-column align-items-center justify-content-center'>
                                <img src={MusicoboIcon} alt='icon-scobo'></img>
                                <span className='font-14 mt-3 mode-text-7'>موزیکوبو</span>
                            </a>
                            :
                            <a className='box d-flex flex-column align-items-center justify-content-center'>
                                <img src={MusicoboIcon} alt='icon-scobo'></img>
                                <span className='font-14 mt-3 mode-text-7'>موزیکوبو</span>
                            </a>
                            }
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Games
import React from 'react'
import './style.css'
import axios from 'axios'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import GameIcon from '../../../../assets/game-icon.svg'
import MeetingIcon from '../../../../assets/meeting-icon.svg'
import TestIcon from '../../../../assets/test-icon.svg'
import VideocallIcon from '../../../../assets/videocall-icon.svg'
import GroupcallIcon from '../../../../assets/videoconference-icon.svg'
import MusicoboIcon from '../../../../assets/musicobo-icon.svg'
import ScoboIcon from '../../../../assets/scobo-icon.svg'
import Gars2Icon from '../../../../assets/gars2-icon.svg'
import Gars3Icon from '../../../../assets/gars3-icon.svg'
import Asq3Icon from '../../../../assets/asq3-icon.svg'
import PanelIcon from '../../../../assets/mehrobopanel-icon.svg'

import BalanceIcon from '../../../../assets/balance-icon.svg'

import { MdOutlineDashboard } from 'react-icons/md'
import { HiOutlineUserGroup } from 'react-icons/hi'
import { AiOutlineEdit } from 'react-icons/ai'
import EditPersonalInfoModal from './personalInfo-modal';

const faNumbers = require('../../../../utils/numbersFa.js')

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            userId: props.selectedUser.id,
            accessType: props.selectedUser.access,
            firstName: props.selectedUser.firstname,
            lastName: props.selectedUser.lastname,
            email: null,
            products: props.selectedUser.products,
            sendMessage: "Hello,I'mInPanelPage",
            farsiNumbers: faNumbers,
            openEditModal: false
        }
    }

    editModalHandler = (modal) => {
        this.setState({openEditModal: modal})
    }

    render() {
        
        return(
            <div className="panel-home-page">
                {this.state.openEditModal && 
                <EditPersonalInfoModal
                    closeModal={(modalBool) => this.editModalHandler(modalBool)}
                    selectedUser={this.props.selectedUser}
                />}
                <div className='dashboard-title-container'>
                    <p className='font-medium font-18 mode-text-2 mb-3'>داشبورد</p>
                    <span className='font-light font-14 mode-text-3'>به پروفایل {this.state.firstName}، خوش آمدید!</span>
                </div>
                <div className='panel-home-page-content'>
                    <a className='home-item-1 panel-card pb-4 pr-4 py-4' onClick={() => this.setState({openEditModal: !this.state.openEditModal})}>
                        <div className='item-content'>
                            <div className='label-item-icon d-flex'>
                                <img src={GameIcon} alt='icon-balance'></img>
                            </div>
                            <div className='label-item-description d-flex flex-column mr-3'>
                                <div className='d-flex align-items-center font-13 mode-text-1'>
                                    <p className='' style={{minWidth: '150px'}}>مشخصات</p>
                                    <AiOutlineEdit style={{minWidth: '100px', fontSize: '1.5em'}}></AiOutlineEdit>
                                </div>
                                <div className='d-flex mode-text-4'>
                                    <p className='font-13' style={{minWidth: '150px', marginLeft:'15px'}}>{"ایمیل: "}{this.state.email}</p>
                                    <p className='font-13' style={{minWidth: '100px', marginLeft:'15px'}}>{"نوع کاربر: "}{(this.state.accessType==='Therapist'&&"درمانگر") || (this.state.accessType==='Clinic'&&"مدیر")}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href='/fa/panel/sessions' className='home-item-2 panel-card pb-4 pr-4 py-4'>
                        <div className='item-content'>
                            <div className='label-item-icon d-flex' style={{width: '50px', background: '#ffccdd', color: '#ff0054', justifyContent: 'center', alignItems: 'center', fontSize: '28px'}}>
                                <HiOutlineUserGroup alt='icon-panel'></HiOutlineUserGroup>
                            </div>
                            <div className='label-item-description d-flex flex-column mr-3'>
                                <p className='font-13 mode-text-1'>جلسات آنلاین</p>
                                <div className='d-flex'>
                                    <img src={VideocallIcon} alt='icon-scobo' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Videocall]}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href='/fa/panel/evaluations' className='home-item-3 panel-card pb-4 pr-4 py-4'>
                        <div className='item-content'>
                            <div className='label-item-icon d-flex'>
                                <img src={TestIcon} alt='icon-balance'></img>
                            </div>
                            <div className='label-item-description d-flex flex-column mr-3'>
                                <p className='font-13 mode-text-1'>تست‌های باقی مانده</p>
                                <div className='d-flex' style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr'}}>
                                    <img src={ScoboIcon} alt='icon-scobo' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Scobo]}</p>
                                    <img src={Gars2Icon} alt='icon-gars2' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Gars2]}</p>
                                    <img src={Gars3Icon} alt='icon-gars2' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Gars3]}</p>
                                    <img src={Asq3Icon} alt='icon-gars2' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Asq3]}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href='/fa/panel' className='home-item-4 panel-card pb-4 pr-4 py-4'>
                        <div className='item-content'>
                            <div className='label-item-icon d-flex' style={{width: '50px', background: '#d1ecfc', color: '#008cff', justifyContent: 'center', alignItems: 'center', fontSize: '28px'}}>
                                <MdOutlineDashboard alt='icon-panel'></MdOutlineDashboard>
                            </div>
                            <div className='label-item-description d-flex flex-column mr-3'>
                                <p className='font-13 mode-text-1'>اکانت پنل</p>
                                <div className='d-flex'>
                                    <img src={PanelIcon} alt='icon-scobo' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[Math.ceil((new Date(this.state.products.Panel) - new Date())/(1000*60*60*24))]}</p>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{"روز"}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                    <a href='/fa/panel/games' className='home-item-5 panel-card pb-4 pr-4 py-4'>
                        <div className='item-content'>
                            <div className='label-item-icon d-flex'>
                                <img src={GameIcon} alt='icon-balance'></img>
                            </div>
                            <div className='label-item-description d-flex flex-column mr-3'>
                                <p className='font-13 mode-text-1'>اکانت‌های بازی</p>
                                <div className='d-flex'>
                                    <img src={MusicoboIcon} alt='icon-scobo' style={{width:'30px', marginLeft:'5px'}}></img>
                                    <p className='font-13 mode-text-4' style={{marginLeft:'15px'}}>{this.state.farsiNumbers[this.state.products.Musicobo]}</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export default Dashboard
import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import moment from 'moment-jalaali'
import './index.css'
import '../../videocall/modal-vidobo/index.css'
import { Area, LineChart, linearGradient, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart } from 'recharts';

import musicoboBanner from '../../../../../assets/SarbargMusicobo1.png'
import ReactToPrint from 'react-to-print'
import { textAlign } from '@mui/system'

const faNumbers = require('../../../../../utils/numbersFa.js')

const data = [
    {
      date: '۰۹/۰۹',
      corrects: 5,
      wrongs: 1,
      level: '۲',
    },
    {
      date: '۰۹/۰۹',
      corrects: 5,
      wrongs: 1,
      level: '۳',
    },
    {
      date: '۰۹/۰۹',
      corrects: 5,
      wrongs: 1,
      level: '۴',
    },
    {
      date: '۰۹/۱۲',
      corrects: 5,
      wrongs: 4,
      level: '۵',
    },
    {
      date: '۰۹/۱۲',
      corrects: 5,
      wrongs: 4,
      level: '۴',
    },
    {
      date: '۰۹/۱۲',
      corrects: 6,
      wrongs: 3,
      level: '۵',
    },
    {
      date: '۰۹/۱۲',
      corrects: 3,
      wrongs: 6,
      level: '۵',
    },
    {
      date: '۰۹/۱۲',
      corrects: 3,
      wrongs: 6,
      level: '۴',
    },
    {
      date: '۰۹/۱۲',
      corrects: 8,
      wrongs: 1,
      level: '۵',
    },
    {
      date: '۰۹/۱۲',
      corrects: 8,
      wrongs: 1,
      level: '۶',
    },
    {
      date: '۰۹/۱۹',
      corrects: 7,
      wrongs: 5,
      level: '۸',
    },
    {
      date: '۰۹/۱۹',
      corrects: 7,
      wrongs: 5,
      level: '۷',
    },
    {
      date: '۰۹/۱۹',
      corrects: 10,
      wrongs: 2,
      level: '۸',
    },
    {
      date: '۰۹/۱۹',
      corrects: 11,
      wrongs: 1,
      level: '۹',
    },
    {
      date: '۰۹/۱۹',
      corrects: 11,
      wrongs: 1,
      level: '۱۰',
    },
];

function GameplayModal({ closeModal, selectedUser, audienceId, clinicName }) {
    
    console.log("Musicobo data is:", selectedUser);

    const [firstName, setFirstName] = useState(selectedUser.firstname)
    const [lastName, setLastName] = useState(selectedUser.lastname)
    const [birthResult, setBirthResult] = useState(selectedUser.birth)
    const [musicoboData, setMusicoboData] = useState(selectedUser.musicobo)
    const [clinic, setClinic] = useState(clinicName)
    const [world1Data, setWorld1Data] = useState([])
    const [world2Data, setWorld2Data] = useState([])
    const farsiNumbers = faNumbers

    const componentRef = useRef(null);
    
    useEffect(() => {
        if (world1Data.length > 0) {
            return
        }
        
        var crct = 5
        var wrng = 0
        for (let i=0; i<musicoboData.Gameplay.length; i++) {
            if (musicoboData.Gameplay[i].LevelPlayed < 13) {
                // if (musicoboData.Gameplay[i].LevelPlayed != 1 && 
                //     musicoboData.Gameplay[i].LevelPlayed != 4 && 
                //     musicoboData.Gameplay[i].LevelPlayed != 7 &&
                //     musicoboData.Gameplay[i].LevelPlayed != 11) {
                //     crct = 0
                //     wrng = 0
                //     for (let j=0; j<musicoboData.Gameplay[i].AvatarSelections.length; j++) {
                //         if (musicoboData.Gameplay[i].AvatarSelections[j] === musicoboData.Gameplay[i].PlayerSelections[j]) {
                //             crct = crct + 1
                //         } else {
                //             wrng = wrng + 1
                //         }
                //     }
                // }

                world1Data.push({date: new Date(musicoboData.Gameplay[i].PlayerTimes[0]).toLocaleDateString('fa-IR'),
                                 corrects: crct,
                                 wrongs: wrng,
                                 level: musicoboData.Gameplay[i].LevelPlayed})
            }
            else if (musicoboData.Gameplay[i].LevelPlayed < 25) {
                // if (musicoboData.Gameplay[i].LevelPlayed != 1 && 
                //     musicoboData.Gameplay[i].LevelPlayed != 4 && 
                //     musicoboData.Gameplay[i].LevelPlayed != 7 &&
                //     musicoboData.Gameplay[i].LevelPlayed != 11) {
                //     crct = 0
                //     wrng = 0
                //     for (let j=0; j<musicoboData.Gameplay[i].AvatarSelections.length; j++) {
                //         if (musicoboData.Gameplay[i].AvatarSelections[j] === musicoboData.Gameplay[i].PlayerSelections[j]) {
                //             crct = crct + 1
                //         } else {
                //             wrng = wrng + 1
                //         }
                //     }
                // }

                world2Data.push({date: new Date(musicoboData.Gameplay[i].PlayerTimes[0]).toLocaleDateString('fa-IR'),
                                 corrects: crct,
                                 wrongs: wrng,
                                 level: musicoboData.Gameplay[i].LevelPlayed - 12})
            }
        }

    },[])

    return (
        <div className='result-modal-background'>
            <div className='result-modal-container'>
                <div className='results-print font-12' ref={componentRef}>
                    <div className='result-header mode-bg-4'>
                        <img src={musicoboBanner}></img>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۱. مشخصات فردی</div>
                    </div>
                    <div className='result-identity mode-text-9'>
                        <div className='result-identity-right' style={{marginLeft: '20px', marginRight: '20px'}}>
                            <p>نام کودک: {firstName} {lastName}</p>
                            <p> نام موسسه: {clinic}</p>
                        </div>
                        <div className='result-identity-left'>
                            <p>تاریخ تولد: {new Date(birthResult).toLocaleDateString('fa-IR')}</p>
                            <p>تاریخ شروع جلسات: {new Date(musicoboData.Gameplay[0].AvatarTimes[0]).toLocaleDateString('fa-IR')}</p>
                        </div>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۲. مراحل آموزش سازها</div>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"در مجموعه مراحل سازها، ابتدا یک ساز به کودک آموزش داده می‌شود که شامل آموزش نام ساز و صدای آن می‌باشد. سپس از کودک خواسته می‌شود که نام و صدای ساز یادگیری شده را به آواتار بگوید. در صورتی که کودک نام و صدای ساز آموزش داده شده را فرا گرفته باشد و ساز دیگری به وی آموزش اضافه داده می‌شود و چالش انتخاب سازها نیز از بین سازهای بیشتری می‌شود."}</p>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"تمرکز این مجموعه مراحل، روی مهارت‌های شنیداری و دیداری کودک می‌باشد."}</p>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '20px', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <table style={{marginLeft: '20px', marginRight: '20px'}}>
                          <tr>
                            <th>مرحله</th>
                            <th>سازها</th>
                            <th>اهداف</th>
                          </tr>
                          <tr>
                            <td>۱</td>
                            <td>بلز، طبلک</td>
                            <td>حافظه بینایی</td>
                          </tr>
                          <tr>
                            <td>۲</td>
                            <td>بلز، طبلک</td>
                            <td>حافظه شنیداری</td>
                          </tr>
                          <tr>
                            <td>۳</td>
                            <td>بلز، طبلک</td>
                            <td>پردازش شنیداری</td>
                          </tr>
                          <tr>
                            <td>۴</td>
                            <td>بلز، طبلک، سنج</td>
                            <td>حافظه بینایی</td>
                          </tr>
                          <tr>
                            <td>۵</td>
                            <td>بلز، طبلک، سنج</td>
                            <td>حافظه شنیداری</td>
                          </tr>
                          <tr>
                            <td>۶</td>
                            <td>بلز، طبلک، سنج</td>
                            <td>پردازش شنیداری</td>
                          </tr>
                          <tr>
                            <td>۷</td>
                            <td>بلز، طبلک، سنج، کیبورد</td>
                            <td>حافظه بینایی</td>
                          </tr>
                          <tr>
                            <td>۸</td>
                            <td>بلز، طبلک، سنج، پیانو</td>
                            <td>حافظه شنیداری</td>
                          </tr>
                          <tr>
                            <td>۹</td>
                            <td>بلز، طبلک، سنج، پیانو</td>
                            <td>پردازش شنیداری</td>
                          </tr>
                          <tr>
                            <td>۱۰</td>
                            <td>بلز، طبلک، سنج، پیانو، چوبک</td>
                            <td>حافظه بینایی</td>
                          </tr>
                          <tr>
                            <td>۱۱</td>
                            <td>بلز، طبلک، سنج، پیانو، چوبک</td>
                            <td>حافظه شنیداری</td>
                          </tr>
                          <tr>
                            <td>۱۲</td>
                            <td>بلز، طبلک، سنج، پیانو، چوبک</td>
                            <td>پردازش شنیداری</td>
                          </tr>
                        </table>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۳. نمودار یادگیری سازها</div>
                    </div>
                    <div style={{marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"در نمودار زیر، پیشرفت کودک در مراحل را بر اساس تاریخ انجام بازی مشاهده می‌نمایید. محور عمودی، مرحله‌ای که کودک در آن قرار دارد و محور افقی، زمان انجام آن مرحله می‌باشد. بدیهی‌است که هر چه شیب نمودار بر حسب زمان بیشتر باشد، کودک در طول مراحل پیشرفت و در نتیجه، یادگیری بیشتری داشته است."}</p>
                    </div>
                    <div className='w-90 d-flex flex-column' style={{marginTop: '0', marginBottom: '0', marginLeft: '20px', marginRight: '20px'}}>
                        <div className='d-flex mb-2'>
                            <div className='chart-data panel-card'>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                    width={100}
                                    height={100}
                                    data={world1Data}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        left: -20,
                                        bottom: 10,
                                    }}
                                    >
                                      <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                        </linearGradient>
                                      </defs>
                                      <XAxis dataKey="date" />
                                      <CartesianGrid strokeDasharray="3 3" />
                                      {/* <XAxis xAxisId="0" dataKey="level" /> */}
                                      {/* <XAxis xAxisId="1" dataKey="date" allowDuplicatedCategory={false} /> */}
                                      {/* <YAxis label={{ value: 'پیشرفت کودک: ریتم‌ها', angle: -90, position: 'insideMiddle' }} /> */}
                                      <YAxis dataKey="level" allowDecimals={false} />
                                      <Tooltip />
                                      {/* <Legend /> */}
                                      {/* <Line type="monotone" dataKey="level" stroke="#5bcdbe" activeDot={{ r: 8 }} /> */}
                                      {/* <Line type="monotone" dataKey="corrects" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                      <Line type="monotone" dataKey="wrongs" stroke="#f9a597" /> */}
                                      <Area type="monotone" dataKey="level" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    <div style={{marginBottom: '0'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۴. مراحل آموزش ریتم‌ها</div>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"در مجموعه مراحل ریتم‌ها، ابتدا یک کلید برای کودک در نقطه‌ی مشخص از ساز و به تعداد مشخص زده می‌شود. سپس از کودک خواسته می‌شود که همان کلید را در همان نقطه از ساز و به تعدادی که زده شده، مشابه با آواتار بزند. در صورتی که کودک کلید و تعداد آموزش داده شده را فرا گرفته باشد و کلید دیگری و به تعداد بیشتری به وی آموزش اضافه داده می‌شود و چالش تعداد و مکان نت‌ها بیشتر می‌شود."}</p>
                        <p style={{marginLeft: '20px', marginRight: '20px'}}>{"تمرکز این مجموعه مراحل، روی مهارت‌های هماهنگی چشم و دست و توجه و تمرکز کودک می‌باشد."}</p>
                    </div>
                    <div style={{flexDirection: 'column', marginTop: '0', marginBottom: '20px', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                        <table style={{marginLeft: '20px', marginRight: '20px'}}>
                          <tr>
                            <th>مرحله</th>
                            <th>ریتم‌ها</th>
                            <th>اهداف</th>
                          </tr>
                          <tr>
                            <td>۱</td>
                            <td>ریتم یکی‌ای، انتخاب ترتیبی</td>
                            <td>هماهنگی چشم و دست</td>
                          </tr>
                          <tr>
                            <td>۲</td>
                            <td>ریتم یکی‌ای، انتخاب تصادفی</td>
                            <td>حافظه یکی‌ای</td>
                          </tr>
                          <tr>
                            <td>۳</td>
                            <td>ریتم یکی‌ای، آهنگ کودکانه</td>
                            <td>تعمیم دو مهارت قبلی</td>
                          </tr>
                          <tr>
                            <td>۴</td>
                            <td>ریتم دوتایی، انتخاب ترتیبی</td>
                            <td>هماهنگی چشم و دست</td>
                          </tr>
                          <tr>
                            <td>۵</td>
                            <td>ریتم دوتایی، انتخاب تصادفی</td>
                            <td>حافظه دوتایی</td>
                          </tr>
                          <tr>
                            <td>۶</td>
                            <td>ریتم دوتایی، آهنگ کودکانه</td>
                            <td>تعمیم دو مهارت قبلی</td>
                          </tr>
                          <tr>
                            <td>۷</td>
                            <td>ریتم سه‌تایی، انتخاب ترتیبی</td>
                            <td>هماهنگی چشم و دست</td>
                          </tr>
                          <tr>
                            <td>۸</td>
                            <td>ریتم سه‌تایی، انتخاب تصادفی</td>
                            <td>حافظه سه‌تایی</td>
                          </tr>
                          <tr>
                            <td>۹</td>
                            <td>ریتم سه‌تایی، آهنگ کودکانه</td>
                            <td>تعمیم دو مهارت قبلی</td>
                          </tr>
                          <tr>
                            <td>۱۰</td>
                            <td>ریتم چهارتایی، انتخاب ترتیبی</td>
                            <td>هماهنگی چشم و دست</td>
                          </tr>
                          <tr>
                            <td>۱۱</td>
                            <td>ریتم چهارتایی، انتخاب تصادفی</td>
                            <td>حافظه چهارتایی</td>
                          </tr>
                          <tr>
                            <td>۱۲</td>
                            <td>ریتم چهارتایی، آهنگ کودکانه</td>
                            <td>تعمیم دو مهارت قبلی</td>
                          </tr>
                        </table>
                    </div>
                    <div style={{marginBottom: '0', marginTop: '200px'}} className='result-header mode-text-13' >
                        <div className='result-modal-title d-flex'>بخش ۵. نمودار یادگیری ریتم‌ها</div>
                    </div>
                    <div className='w-90 d-flex flex-column' style={{marginTop: '0', marginBottom: '0', marginLeft: '20px', marginRight: '20px'}}>
                        <div style={{marginTop: '0', margin: '0 2.5%', marginBottom: '0', justifyContent: 'flex-start', textAlign: 'justify'}} className='result-spec-table mode-text-9'>
                            <p>{"در نمودار زیر، پیشرفت کودک در مراحل را بر اساس تاریخ انجام بازی مشاهده می‌نمایید. محور عمودی، مرحله‌ای که کودک در آن قرار دارد و محور افقی، زمان انجام آن مرحله می‌باشد. بدیهی‌است که هر چه شیب نمودار بر حسب زمان بیشتر باشد، کودک در طول مراحل پیشرفت و در نتیجه، یادگیری بیشتری داشته است."}</p>
                        </div>
                        <div className='d-flex mb-2'>
                            <div className='chart-data panel-card'>
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                    width={100}
                                    height={60}
                                    data={world2Data}
                                    margin={{
                                        top: 20,
                                        right: 20,
                                        left: -20,
                                        bottom: 10,
                                    }}
                                    >
                                      <defs>
                                        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                          <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                        </linearGradient>
                                      </defs>
                                      <XAxis dataKey="date" />
                                      <CartesianGrid strokeDasharray="3 3" />
                                      {/* <XAxis xAxisId="0" dataKey="level" />
                                      <XAxis xAxisId="1" dataKey="date" allowDuplicatedCategory={false} />
                                      <YAxis label={{ value: 'پیشرفت کودک: نت‌ها', angle: -90, position: 'insideMiddle' }} /> */}
                                      <YAxis dataKey={"level"} allowDecimals={false}/>
                                      <Tooltip />
                                      {/* <Legend /> */}
                                      {/* <Line type="monotone" dataKey="level" stroke="#5bcdbe" activeDot={{ r: 8 }} />
                                      <Line type="monotone" dataKey="corrects" stroke="#82ca9d" activeDot={{ r: 8 }} />
                                      <Line type="monotone" dataKey="wrongs" stroke="#f9a597" /> */}
                                      <Area type="monotone" dataKey="level" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                        {/* <div className='d-flex mb-2'>
                            <div className='d-flex w-50 mt-2 mr-3 mb-2 mode-text-7'>
                                به زودی ...
                            </div>
                            <div className='d-flex w-50 mt-2 mr-3 mb-2 mode-text-7'>
                                به زودی ...
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className='result-spec-table'>
                    <ReactToPrint
                    trigger={() => <button style={{color: '#8D1D57'}}>چاپ نتایج</button>}
                    content={() => componentRef.current}
                    documentTitle= 'نتایج موزیکوبو۱'
                    />
                    <button onClick={() => closeModal(false)}>
                        متوجه شدم
                    </button>
                </div>
            </div>
        </div>
    )
}

export default GameplayModal
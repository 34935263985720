import React from 'react'
import './style.css'
import axios from 'axios'

import { FaBars } from 'react-icons/fa';
import Sidebar from '../sidebar';
import Evaluations from './evaluations'
import List from './list';
import Dashboard from './dashboard';
import Gars2 from './gars2';
import Gars3 from './gars3';
import Scobo from './scobo';
import Wechsler from './wechsler';
import Binet from './binet';
import Videocall from './videocall';
import Session from './session';
import Results from './results';
import Users from './users';
import Store from './store';
import Sessions from './sessions';
import Games from './games';
import Musicobo from './musicobo';
import Offline from './sessions/offline';
import Asq3 from './asq3';

class Panel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLogin: false,
            requestedUser: null,
            sendMessage: "Hello,I'mInPanelPage",
        }
    }

    componentDidMount = async () => {
        this.handleRedirect();
        const result = await axios.get('https://request.sweetobot.com/user/request', {headers: {'x-access-token': localStorage.getItem('token')}})
        if (result.data.message == "Yo, You are authenticated!") {
            console.log(result.data);
            console.log(result.data.user.id);
            console.log(result.data.user.firstname);
            this.setState({isLogin: true});
            this.setState({requestedUser: result.data.user})
        } else {
            this.setState({isLogin: false});
            window.location.href = 'https://www.mehrobo.com/fa/login'
        }
    }

    async handleRedirect () {
        const response = await axios.post('https://request.sweetobot.com/pages/request', {sendMe: this.state.sendMessage})
    }

    render() {
        
        if (this.state.isLogin == false) {
            return(
                <div className="panel-container">
                    پنل در حال بارگزاری می‌باشد ...
                </div>
            )
        }
        
        return(
            <div className="panel-container">
                <div className={this.props.sidebar ? 'panel-right-responsive' : 'panel-right'}>
                    <Sidebar 
                        activeLink={this.props.activeLink}
                        sidebarHandler={this.props.sidebarHandler}
                        firstName={this.state.requestedUser.firstname}
                        lastName={this.state.requestedUser.lastname}
                        accessType={this.state.requestedUser.access}
                    />
                </div>
                <div className='panel-left'>
                    <FaBars className='fa-bars' onClick={this.props.sidebarHandler}></FaBars>
                    {this.props.activeLink == 1 && <Dashboard selectedUser={this.state.requestedUser}/>}
                    {this.props.activeLink == 2 && this.props.subLink == 0 && <Evaluations />}
                    {this.props.activeLink == 2 && this.props.subLink == 1 && <List />}
                    {this.props.activeLink == 2 && this.props.subLink == 2 && <Results />}
                    {this.props.activeLink == 2 && this.props.subLink == 3 && <Scobo />}
                    {this.props.activeLink == 2 && this.props.subLink == 4 && <Gars2 />}
                    {this.props.activeLink == 2 && this.props.subLink == 5 && <Gars3 />}
                    {this.props.activeLink == 2 && this.props.subLink == 6 && <Asq3 />}
                    {this.props.activeLink == 2 && this.props.subLink == 7 && <Wechsler />}
                    {this.props.activeLink == 2 && this.props.subLink == 8 && <Binet />}
                    {this.props.activeLink == 3 && this.props.subLink == 0 && <Games />}
                    {this.props.activeLink == 3 && this.props.subLink == 1 && <Musicobo />}
                    {this.props.activeLink == 4 && this.props.subLink == 0 && <Sessions />}
                    {this.props.activeLink == 4 && this.props.subLink == 1 && <Offline />}
                    {this.props.activeLink == 4 && this.props.subLink == 2 && <Videocall />}
                    {this.props.activeLink == 4 && this.props.subLink == 3 && <Session />}
                    {this.props.activeLink == 5 && this.props.subLink == 0 && <Users />}
                    {this.props.activeLink == 6 && this.props.subLink == 0 && <Store />}
                </div>
            </div>
        )
    }
}

export default Panel
import React from 'react'
import '../../screening-test/index.css'
import arrowImage from '../../../../assets/arrow.png'
import axios from 'axios'
import resultImageG01 from '../../../../assets/FlatChartG01.svg'
import resultImageG02 from '../../../../assets/FlatChartG02.svg'
import resultImageO01 from '../../../../assets/FlatChartO01.svg'
import resultImageO02 from '../../../../assets/FlatChartO02.svg'
import resultImageR01 from '../../../../assets/FlatChartR01.svg'
import resultImageR02 from '../../../../assets/FlatChartR02.svg'

const faQuestions = require('../../../../utils/asq3TestQuestionsFa.js')
const engQuestions = require('../../../../utils/scoboTestQuestionsEng.js')
const faAnswers = require('../../../../utils/scoboTestAnswersFa')
const engAnswers = require('../../../../utils/scoboTestAnswersEng')
const faTestResults = require('../../../../utils/scoboTestResultsFa.js')
const engTestResults = require('../../../../utils/scoboTestResultsEng.js')

class Asq3 extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      asq3Result: null,
      autismBackground: '',
      autismDiagnosis: '',
      childAge: 0,
      execute: false,
      gender: '',
      questionsCounter:  1,
      questionsToShow: [],
      questionsSelectionByAge: [0],
      questionNumber: 1,
      questionsTotalNumber: 30,
      questions: {},
      results: {},
      resultsSubscalesText: ["مهارت‌های ارتباطی ", "مهارت‌های درشت ", "مهارت‌های خرد ","مهارت‌های حل مسئله ","مهارت‌های اجتماعی "],
      screeningAnswer: [],
      screeningbooking: false,
      finishedAnswering: false,
      resultNumber: 0,
      resultSubscales: [0,0,0,0,0],
      countryCode: 'IR', // this.props.languageInput,
      testAnswersOptions: [],
      testResultsText: [],
      isLoaded: false,
      pictureSelector: '',
    }
  }

  componentDidMount = async() => {

    this.setState({testAnswersOptions: [...faAnswers]})
    this.setState({testResultsText: [...faTestResults]})

    const response = await axios.post('https://request.sweetobot.com/evaluation/request', {testName: 'ASQ3'}, {headers: {'x-access-token': localStorage.getItem('token')}})
    if (response.data.status == 0) {
      window.location.href = '/fa/panel/evaluations/list?test=ASQ3' // It could be changes to show some message to user
    }
    this.setState({userId: response.data.audienceId})
    var receivedAge = response.data.age
    console.log("User age is:", response.data.age);

    if (response.data.age < 3) receivedAge = 0
    else if (response.data.age < 5) receivedAge = 1
    else if (response.data.age < 7) receivedAge = 2
    else if (response.data.age < 9) receivedAge = 3
    else if (response.data.age < 10) receivedAge = 4
    else if (response.data.age < 11) receivedAge = 5
    else if (response.data.age < 13) receivedAge = 6
    else receivedAge = 7
    this.setState({childAge: receivedAge})
    this.setState({questionsToShow: faQuestions[receivedAge]})
    this.setState({questionsTotalNumber: faQuestions[receivedAge].length})
    this.setState({isLoaded: true})
  }

  screeningAnswerHandler = (value) => {

    this.state.questions[this.state.questionsCounter] = value
    console.log(this.state.questions);
    
    if (this.state.questionsCounter < this.state.questionsTotalNumber) {
      this.setState({questionsCounter: this.state.questionsCounter+1})
    } else {
      this.setState({finishedAnswering: true})
      this.sendAnsweredQuestions()
    }
  }

  previousQuestionNumberHandler = () => {
    if (this.state.questionsCounter > 1){
      this.setState({questionNumber: 1})
      this.setState({questionsCounter: this.state.questionsCounter-1})
    }
  }

  nextQuestionNumberHandler = () => {
    if (this.state.questionNumber == 1){
      this.setState({questionsCounter: this.state.questionsCounter+1})
      this.setState({questionNumber: 1})
    }
  }

  sendAnsweredQuestions = async() => {
    const results = await axios.post('https://request.sweetobot.com/evaluation/evaluate', {
      audienceId: this.state.userId,
      age: this.state.childAge, // 0, 1, 2, 3
      sex: this.state.gender,
      hasAutism: this.state.autismBackground,
      diagAutism: this.state.autismDiagnosis,
      questions: Object.keys(this.state.questions), 
      answers: Object.values(this.state.questions),
      test: 'ASQ3'
    }, {
      headers: {'x-access-token': localStorage.getItem('token')}
    })
    console.log('server answer is: ' , results.data);
    this.setState({asq3Result: results.data.result})
  }

  render() {

    if (this.state.isLoaded == false) {
      return (
        <div className='result-page'>{"سوالات در حال بارگزاری می‌باشند ..."}</div>
      )
    }
    
    if (this.state.asq3Result != null){
      if (this.state.asq3Result.Result == 1){
        this.setState({pictureSelector: resultImageG02})
      } 
      if (this.state.asq3Result.Result == 2){
        this.setState({pictureSelector: resultImageO02})
      } 
      if (this.state.asq3Result.Result == 3){
        this.setState({pictureSelector: resultImageR02})
      }

      return (
        <div className='result-page'>
          <div className='result-page-container'>
            <div className='result-page-right-wrapper'>
              {this.state.asq3Result.Result==1 && <img src={this.state.pictureSelector} className='result-answer'></img>}
              {this.state.asq3Result.Result==2 && <img src={resultImageO02} className='result-answer'></img>}
              {this.state.asq3Result.Result==3 && <img src={resultImageR02} className='result-answer'></img>}
              {this.state.asq3Result.Result==4 && <img src={resultImageR02} className='result-answer'></img>}
            </div>
            <div className='result-page-left-wrapper'>
              {this.state.asq3Result.Subscales.map((subCount, i) => (
              <div className='d-flex w-100'>
                  <div className='d-flex align-items-center font-11' style={{width: '20%', height: '30px', borderStyle: 'solid', borderWidth: '1px', marginBottom: '5px'}}>
                      <p>{this.state.resultsSubscalesText[i]}</p>
                  </div>
                  <div style={{display: 'flex', position: 'relative', width: '80%', height: '30px', borderStyle: 'solid', borderWidth: '1px', marginBottom: '5px'}}>
                      {subCount > this.state.asq3Result.Percentiles[1][i] &&
                      <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: '#8dc63f'}}></div>
                      }
                      {subCount > this.state.asq3Result.Percentiles[0][i] && subCount <= this.state.asq3Result.Percentiles[1][i] &&
                      <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: '#e86e34'}}></div>
                      }
                      {subCount <= this.state.asq3Result.Percentiles[0][i] &&
                      <div style={{position: 'absolute', top:'0px', left: '0%', width: subCount*1.67+'%', height: '30px', background: 'red'}}></div>
                      }
                      <div style={{position: 'absolute', top:'7px', left: '2%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '9.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '17.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '25%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '32.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '40.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '48%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '55.7%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '63.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '71%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '78.6%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '86.3%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                      <div style={{position: 'absolute', top:'7px', left: '94%', width: '12px', height: '12px', borderStyle: 'solid', borderWidth: '2px', borderRadius: '20px', borderColor: 'black'}}></div>
                  </div>
              </div>
              ))}
              <div className='d-flex'>
                <a href={`/fa/panel/evaluations/list/results?id=${this.state.userId}`}> {"رفتن به صفحه نتایج"} </a>
                <a href='/fa/panel'> {"بازگشت به داشبورد"} </a>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (this.state.finishedAnswering == true){
      return (
        <div className='screening-test' style={{backgroundImage: 'none'}}>
          {this.state.testResultsText[0]}
        </div>
      )
    }

    return (
      <div className={this.state.countryCode=='IR' ? 'screening-test' : 'screening-test-eng'} style={{backgroundImage: 'none'}}>
        <button onClick={() => this.nextQuestionNumberHandler()} className='pushButton-next-question'> 
          <img src={arrowImage}></img>
        </button>
        <div className='test-container'>
          <div className='question-number'>{this.state.questionsCounter}/{this.state.questionsTotalNumber}</div>
          <div className='container-screening-question'>
            <div className='screening-test-question'>
              <p>{this.state.questionsToShow[this.state.questionsCounter-1]}</p>
            </div>
          </div>
          <div className='container-screening-answer'>
          <button onClick={() => this.screeningAnswerHandler(3)} className='screening-answer-option mode-bg-11'>{"بله"}</button>
            <button onClick={() => this.screeningAnswerHandler(2)} className='screening-answer-option mode-bg-11'>{"گاهی اوقات"}</button>
            <button onClick={() => this.screeningAnswerHandler(1)} className='screening-answer-option mode-bg-11'>{"نه هنوز"}</button>
          </div>
        </div>
          <button onClick={() => this.previousQuestionNumberHandler()} className='pushButton-previous-question'> 
            <img src={arrowImage}></img>
          </button>
      </div>
    )
  }
}

export default Asq3